/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { CardBody, Col, Form, Card, Label, Input, Button, Row } from "reactstrap";
import '../../Admin/Support_Tickets/Support_Tickets.css'
import Publisher_Support_Ticket_Chat_List from "./Publisher_Support_Ticket_Chat_List";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";

//react-scroll
import { Element, animateScroll, scroller } from 'react-scroll';

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import { addChat as onAddChat } from "store/Support_Ticket/Publisher_Ticket/Publisher_Support/actions";
import $ from "jquery";
// import { getChats } from "helpers/fakebackend_helper";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { preventDefault } from "@fullcalendar/core";

var file


const Publisher_Support_Ticket_Chat = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [userMsg, setUsermsg] = useState("")
    const toscroll = useRef(null)
    const dispatch = useDispatch()
    const [chats, setChats] = useState([])
    const [userFilename, setUserFilename] = useState('')
    const [inputKey, setInputKey] = useState(Math.random().toString(36))

    const [chatLoader, setChatLoader] = useState(false)


    const { ticket, notificationDatas } = props

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const handleUserMsg = e => {
        e.preventDefault()
        setUsermsg(e.target.value)

    }
    const onFileChange = e => {
        if (e.target.files[0] != undefined) {
            setUserFilename(e.target.files[0].name)
            file = e.target.files[0]
            if (file.size > 10485760) {
                toastr.error("File size must be less than 10 MB");
                console.log("large file")
                setUserFilename("")
                setInputKey(Math.random().toString(36))
                file = ''
            }
        } else {
            setUserFilename("")
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            animateScroll.scrollToBottom({
                containerId: 'chat-container',
                duration: 0,
            });
        }, 500);
    };

    useEffect(() => {
        scrollToBottom();
    }, [chats]);




    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    useEffect(() => {
        console.log(`--------------------------------------------------------useEffect-${props.ticket}`)
        if (props.ticket) {
            getChats(props.ticket)
        }
    }, [props.ticket])

    useEffect(() => {
        const intervalCall = setInterval(() => {
            getChats(props.ticket)
        }, 10000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };
    }, [props.ticket]);

    const getChats = (ticket) => {
        console.log(`---------------------------------------------------------${ticket}`)
        let bodyChat = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                Partner: userInfo.name,
                name: userInfo.name
            
        }
        axios.post("/v2.0/partnerOP/SupportTickets/read/partner/" + userInfo.userID + '/' + ticket, bodyChat,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    let todayLine = {
                        "dateNaming": 'Today'
                    }
                    let yesterdayLine = {
                        "dateNaming": 'Yesterday'
                    }
                    let dateLine = {
                        "dateNaming": ''
                    }
                    let todayLinePresent = 'yes'
                    let yesterdayLinePresent = 'yes'
                    let nthLine = {
                        "NthLine": 'yes'
                    }
                    res.data.data[0].chat.map((item, index) => {
                        // res.data.data[0].chat.splice(res.data.data[0].chat.length, 0, nthLine)
                        console.log(moment(item.messageTimeStamp).calendar())
                        if (moment(item.messageTimeStamp).calendar().includes('Today')) {

                            if (todayLinePresent === 'yes') {
                                res.data.data[0].chat.splice(index, 0, todayLine);
                                todayLinePresent = 'no'
                            }
                        }
                        if (moment(item.messageTimeStamp).calendar().includes('Yesterday')) {
                            if (yesterdayLinePresent === 'yes') {
                                res.data.data[0].chat.splice(index, 0, yesterdayLine);
                                yesterdayLinePresent = 'no'
                            }
                        }
                        // if(moment(item.messageTimeStamp).calendar().includes('Today')){
                        //     todayLinePresent = false
                        //     res.data.data[0].chat.splice(index, 0, todayLine);
                        // }
                    })
                    console.log(res.data.data[0].chat)
                    setChats(res.data.data[0].chat)
                }
            })
            .catch((err) => {
                console.log("e", err)
                setInputKey(Math.random().toString(36))
                file = ''
            });

    }

    // useEffect(() => {
    //     if (!toscroll || !toscroll.current) {
    //         return;
    //     }
    //     window.setTimeout(() => {
    //         const scrollY = window.scrollY;
    //         requestAnimationFrame(() => {
    //             toscroll.current.scroll({ top: toscroll.current.scrollHeight, behavior: 'instant' });
    //             window.scrollTo(0, scrollY + toscroll.current.scrollHeight);
    //         });
    //         toscroll.current.addEventListener('DOMNodeInserted', event => {
    //             const { currentTarget: target } = event;
    //             target.scroll({ top: target.scrollHeight, behavior: 'instant' });
    //         });
    //     }, 400);
    // }, [toscroll]);

    function _handleSubmit(e) {
        e.preventDefault();
        setChatLoader(true)

        let fileData = new FormData();
        fileData.append('file', file);
        fileData.append("supportTicketID", props.notificationDatas.supportTicketID);
        fileData.append("message", userMsg)
        fileData.append('userID', userInfo.userID)
        fileData.append('userRole', userInfo.userRole)
        fileData.append('companyID', userInfo.companyID)
        fileData.append('name', userInfo.name)
        // https://support-ticket.bidsxchange.com/v1.0/SupportTickets/create
        $.ajax({
            url: "https://support-ticket.bidsxchange.com/v1.0/SupportTickets/create/chat",
            type: 'POST',
            data: fileData,
            processData: false,
            contentType: false,
            mode: 'cors',
            cache: false,
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            enctype: "multipart/form-data",
            success: function (data) {
                // Success..
                setChatLoader(false)

                e.target.reset()
                setUsermsg('')
                getChats(props.ticket)
                setUserFilename('')
                console.log('success....', data);
                setInputKey(Math.random().toString(36))
                file = ''
            },
            // Fail..
            error: function (xhr, status, err) {
                console.log(xhr, status);
                console.log(err);
                setInputKey(Math.random().toString(36))
                file = ''
            }
        });
    }

    const handleClear = () => {
        setUserFilename('')
        setInputKey(Math.random().toString(36))
    }

    const handleEnterKey = (e) => {
        console.log(e.key)
        const buttonSend = document.getElementById("sendBtn");
        if (e.key === "Enter") {
            if (e.shiftKey) {
                console.log("next-line")
            } else {
                e.preventDefault()
                buttonSend.click();
            }
        }
    }

    const handleAttachmentEnter = (e) => {
        const buttonSend = document.getElementById("sendBtn");
        if(e.key === "Enter"){
            e.preventDefault()
            buttonSend.click()
        }
    }

    return (
        <Col md="12">
            <Col sm="12">
                <Card className="p-1 mb-0" >
                    <CardBody className="d-flex justify-content-end ">
                        <div className="d-flex justify-content-end fixed">
                            <button

                                className="m-1 active-state "
                                style={{ borderColor: "#20C9AC", }}

                            >
                                {`Status: ${props.notificationDatas.supportTicketStatus}`}
                            </button>
                            <Button
                                color="secondary"
                                className="m-1 d-none"
                                type="button"
                            >
                                Close Ticket
                            </Button>
                        </div>
                    </CardBody>
                </Card>
                <Card className="mb-0 mt-0">
                    <CardBody className="chatbox-outer mt-0">
                        <div className="chatbox d-flex flex-column " >
                            <Element id="chat-container" style={{ overflowY: 'scroll' }} offset={10} >
                                <ul className="ul-chat-list" >
                                    {
                                        chats.length !== 0 &&
                                        chats.map((each, index) => <Publisher_Support_Ticket_Chat_List chat={each} key={index} chats={chats} />)
                                    }
                                </ul>
                            </Element>
                        </div>
                        <div className="p-3 chat-input-section">
                            <Form onSubmit={_handleSubmit} method="post">
                                <Row>
                                    <Col>
                                        <div className="position-relative">
                                            <textarea
                                                type="text"
                                                rows={1}
                                                value={userMsg}
                                                // onKeyPress={onKeyPress}
                                                onChange={handleUserMsg}
                                                className="form-control chat-input"
                                                style={{ overflow: "hidden", resize: "none" }}
                                                onKeyDown={handleEnterKey}
                                                placeholder="Enter Message..."
                                            />
                                            <div className="chat-input-links">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item d-flex justify-content-center align-items-center">
                                                        <div className="support-ticket-file btn btn-sm  d-flex justify-content-center align-items-center">
                                                            <i className='bx bx-paperclip  bx-sm rotate-90'></i>
                                                            {userFilename ? `${userFilename}` : "Attach File"}
                                                            <input className="support-ticket-input" type="file" key={inputKey || ''} onKeyDown={handleAttachmentEnter} onChange={onFileChange} name="file" accept=".png,.jpeg,.jpg,video/*" />
                                                        </div>
                                                        {userFilename && <i className='bx bx-x ml-3' style={{ cursor: "pointer", fontSize: "1.2rem" }} onClick={() => handleClear()}></i>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col-auto">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn btn-primary btn-rounded chat-send w-md "
                                            disabled={props.notificationDatas.supportTicketStatus === "closed"}
                                            id="sendBtn"
                                        >
                                            <i className={chatLoader ? "bx bx-loader-circle bx-spin font-size-16 align-middle me-2 ml-2 mr-2" : ''} ></i>

                                            <span className="d-none d-sm-inline-block me-2">
                                                Send
                                            </span>
                                            <i className="mdi mdi-send" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>


                    </CardBody>
                </Card>
            </Col>
        </Col>
    )
}
Publisher_Support_Ticket_Chat.propTypes = {}

export default Publisher_Support_Ticket_Chat