import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";
import axios from "axios";

//import Charts
import StackedColumnChart from "pages/Dashboard/StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "store/actions";

import modalimage1 from "assets/images/product/img-7.png";
import modalimage2 from "assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "pages/Dashboard/WelcomeComp";

import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DateRangePickers from 'helpers/DateRangePicker'

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import Publisher_StackChart from "./Publisher_StackChart";
import DateRangePickerDashboard from "helpers/DateRangePickerDashboard";
import LoadingDashboardCard from "pages/LoaderPages/LoadingDashboardCard";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 3 : 2

var fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
var dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
var dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')

var xAxis = []
var stdbXAxis = []
var highImpactXAxis = []
var videoXAxis = []

const Publisher_Dashboard = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [stdbPeriodData, setStdbPeriodData] = useState([]);
  const [highImpactPeriodData, setHighImpactPeriodData] = useState([]);
  const [videoPeriodData, setVideoPeriodData] = useState([]);

  const [periodType, setPeriodType] = useState("day");
  const [stdbPeriodType, setStdbPeriodType] = useState("day");
  const [highImpactPeriodType, setHighImpactPeriodType] = useState("day");
  const [videoPeriodType, setVideoPeriodType] = useState("day");

  const [reportData, setReportData] = useState([
    { title: "Total Code Served", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Paid Impressions", iconClass: "bx-bar-chart", description: "0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Revenue", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$0", yesterdayRate: '0%', isIncreased: 'zero' },
    { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: "0%", yesterdayRate: '0%', isIncreased: 'zero' },
  ])
  const [inventoryType, setInventoryType] = useState('')
  const [adUnitData, setAdUnitData] = useState([]);
  const [highImpactAdUnitData, setHighImpactAdUnitData] = useState([]);
  const [videoAdUnitData, setVideoAdUnitData] = useState([]);
  const [selectedAdUnit, setSelectedAdUnit] = useState([])
  const [selectedHighImpactAdUnit, setSelectedHighImpactAdUnit] = useState([])
  const [selectedVideoAdUnit, setSelectedVideoAdUnit] = useState([])
  const [userInventoryState, setUserInventoryState] = useState('both')

  const [switchLoader, setSwitchLoader] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSwitch = () => {
    setSwitchLoader(!switchLoader)
  }

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    fromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    toDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    dateFromDate = moment().subtract(estCurrentDate > 2 ? 8 : 7, 'days')
    dateToDate = moment().subtract(estCurrentDate > 2 ? 2 : 1, 'days')
    getDashboardData('')
  }, []);

  const changeInventory = (e) => {
    setInventoryType(e.target.value)
    getDashboardData(e.target.value)
  }

  const getDashboardData = (inventory) => {
    setLoading(true)
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
        inventoryType: inventory
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is dashBoardData ==>", res)
        setLoading(false)
        if (res.data) {
          if (res.data && res.data) {
            if (res.data.PreviousDayTotalCodeServed) {
              let prevCompTotalCodeServed = res.data.totalCodeServed === 0 ? 0 : (100 * ((res.data.totalCodeServed - res.data.PreviousDayTotalCodeServed) / res.data.PreviousDayTotalCodeServed))
              let prevCompEstimatedImpressions = res.data.estimatedImpressions === 0 ? 0 : (100 * ((res.data.estimatedImpressions - res.data.PreviousDayEstimatedImpressions) / res.data.PreviousDayEstimatedImpressions))
              let prevCompEstimatedRevenue = res.data.estimatedRevenue === 0 ? 0 : (100 * ((res.data.estimatedRevenue - res.data.PreviousDayEstimatedRevenue) / res.data.PreviousDayEstimatedRevenue))
              let prevCompEstimatedeCPM = res.data.estimatedeCPM === 0 ? 0 : (100 * ((res.data.estimatedeCPM - res.data.PreviousDayEstimatedeCPM) / res.data.PreviousDayEstimatedeCPM))
              let prevCompEstimatedFillRate = res.data.estimatedFillRate === 0 ? 0 : (100 * ((res.data.estimatedFillRate - res.data.PreviousDayEstimatedFillRate) / res.data.PreviousDayEstimatedFillRate))

              console.log(prevCompTotalCodeServed, prevCompEstimatedImpressions, prevCompEstimatedRevenue, prevCompEstimatedeCPM, prevCompEstimatedFillRate)
              setReportData([
                { title: "Total Code Served", iconClass: "bx-bar-chart", description: (res.data.totalCodeServed).toLocaleString(), yesterdayRate: (prevCompTotalCodeServed.toFixed(2) < 0 ? prevCompTotalCodeServed.toFixed(2) * -1 : prevCompTotalCodeServed.toFixed(2)) + "%", isIncreased: prevCompTotalCodeServed > 0 ? 'yes' : prevCompTotalCodeServed < 0 ? 'no' : 'zero' },
                { title: "Paid Impressions", iconClass: "bx-bar-chart", description: (res.data.estimatedImpressions).toLocaleString(), yesterdayRate: (prevCompEstimatedImpressions.toFixed(2) < 0 ? prevCompEstimatedImpressions.toFixed(2) * -1 : prevCompEstimatedImpressions.toFixed(2)) + "%", isIncreased: prevCompEstimatedImpressions > 0 ? 'yes' : prevCompEstimatedImpressions < 0 ? 'no' : 'zero' },
                { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + (res.data.estimatedRevenue).toLocaleString(), yesterdayRate: (prevCompEstimatedRevenue.toFixed(2) < 0 ? prevCompEstimatedRevenue.toFixed(2) * -1 : prevCompEstimatedRevenue.toFixed(2)) + "%", isIncreased: prevCompEstimatedRevenue > 0 ? 'yes' : prevCompEstimatedRevenue < 0 ? 'no' : 'zero' },
                { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$" + (res.data.estimatedeCPM).toLocaleString(), yesterdayRate: (prevCompEstimatedeCPM.toFixed(2) < 0 ? prevCompEstimatedeCPM.toFixed(2) * -1 : prevCompEstimatedeCPM.toFixed(2)) + "%", isIncreased: prevCompEstimatedeCPM > 0 ? 'yes' : prevCompEstimatedeCPM < 0 ? 'no' : 'zero' },
                { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: res.data.estimatedFillRate + "%", yesterdayRate: (prevCompEstimatedFillRate.toFixed(2) < 0 ? prevCompEstimatedFillRate.toFixed(2) * -1 : prevCompEstimatedFillRate.toFixed(2)) + "%", isIncreased: prevCompEstimatedFillRate > 0 ? 'yes' : prevCompEstimatedFillRate < 0 ? 'no' : 'zero' },
              ])
            }
            else {
              setReportData([
                { title: "Total Code Served", iconClass: "bx-bar-chart", description: (res.data.totalCodeServed).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Paid Impressions", iconClass: "bx-bar-chart", description: (res.data.estimatedImpressions).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + (res.data.estimatedRevenue).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$" + (res.data.estimatedeCPM).toLocaleString(), yesterdayRate: '0%', isIncreased: 'zero' },
                { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: res.data.estimatedFillRate + "%", yesterdayRate: '0%', isIncreased: 'zero' },
              ])
            }
            if (res.data.overview && res.data.overview.length > 0) {
              xAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.overview.map((item, index) => {
                xAxis.push(item['x-axis'])
              })
              res.data.overview.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.overview.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.overview.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })

              setChartData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setChartData([])
              xAxis = []
            }
            if (res.data.stdb.length > 0) {
              stdbXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.stdb.map((item, index) => {
                stdbXAxis.push(item['x-axis'])
              })
              res.data.stdb.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.stdb.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.stdb.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })

              setStdbPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setStdbPeriodData([])
              stdbXAxis = []
            }
            if (res.data.highImpact && res.data.highImpact.length > 0) {
              highImpactXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.highImpact.map((item, index) => {
                highImpactXAxis.push(item['x-axis'])
              })
              res.data.highImpact.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.highImpact.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.highImpact.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })

              setHighImpactPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setHighImpactPeriodData([])
              highImpactXAxis = []
            }
            if (res.data.video && res.data.video.length > 0) {
              videoXAxis = []
              let estimatedImpressions = []
              let estimatedRevenue = []
              let estimatedeCPM = []
              res.data.video.map((item, index) => {
                videoXAxis.push(item['x-axis'])
              })
              res.data.video.map((item, index) => {
                estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
              })
              res.data.video.map((item, index) => {
                estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
              })
              res.data.video.map((item, index) => {
                estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
              })

              setVideoPeriodData([
                { name: 'Total Code Served', type: "column", data: estimatedImpressions },
                { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
                { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
              ])
            } else {
              setVideoPeriodData([])
              videoXAxis = []
            }
          } else {
            setReportData([
              { title: "Total Code Served", iconClass: "bx-bar-chart", description: '0%', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Paid Impressions", iconClass: "bx-bar-chart", description: '0%', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Revenue", iconClass: "bx-dollar-circle", description: "$" + '0', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Estimated eCPM", iconClass: "bx-dollar-circle", description: "$" + '0', yesterdayRate: '0%', isIncreased: 'zero' },
              { title: "Estimated Fill Rate", iconClass: "bx-show-alt", description: '0' + "%", yesterdayRate: '0%', isIncreased: 'zero' },
            ])
            setChartData([])
            xAxis = []
            setStdbPeriodData([])
            stdbXAxis = []
            setHighImpactPeriodData([])
            highImpactXAxis = []
            setVideoPeriodData([])
            videoXAxis = []
          }

        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("e", err)
      });
  }

  const dataModal = (data, label) => (e) => {
    let dataList = []
    data.map((item, index) => {
      dataList.push(item[label])
    })
    console.log(dataList)
    return dataList

  }


  const changeDatePick = (event) => {
    console.log(event)
    console.log("start: ", event.startDate._d);
    console.log("end: ", event.endDate._d);
    dateFromDate = event.startDate
    dateToDate = event.endDate
    fromDate = event.startDate._d.toISOString();
    toDate = event.endDate._d.toISOString();
    setSelectedAdUnit([])
    setSelectedHighImpactAdUnit([])
    setSelectedVideoAdUnit([])
    setPeriodType("day")
    setStdbPeriodType("day");
    setHighImpactPeriodType("day");
    setVideoPeriodType("day");
    getDashboardData(inventoryType)
    // setFromDate(event.startDate._d.toISOString());
    // setToDate(event.endDate._d.toISOString());
  }

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  console.log(userInfo)



  useEffect(() => {
    getAdUnitsData()
  }, []);

  // const onChangeAdUnit = (e) => {
  //   setSelectedAdUnit(e.target.value)
  //   getStdbData(stdbPeriodType, e.target.value)
  // };

  // const onChangeHighImpactAdUnit = (e) => {
  //   setSelectedHighImpactAdUnit(e.target.value)
  //   getHighImpactData(highImpactPeriodType, e.target.value)
  // };

  // const onChangeVideoAdUnit = (e) => {
  //   setSelectedVideoAdUnit(e.target.value)
  //   getVideoOverViewData(videoPeriodType, e.target.value)
  // };

  function onChangeAdUnit(selectAdUnit) {
    console.log(selectAdUnit)
    setSelectedAdUnit(selectAdUnit);
    getStdbData(stdbPeriodType, selectAdUnit)
  }

  function onChangeHighImpactAdUnit(selectAdUnit) {
    console.log(selectAdUnit)
    setSelectedHighImpactAdUnit(selectAdUnit);
    getHighImpactData(highImpactPeriodType, selectAdUnit)
  }

  function onChangeVideoAdUnit(selectAdUnit) {
    console.log(selectAdUnit)
    setSelectedVideoAdUnit(selectAdUnit);
    getVideoOverViewData(videoPeriodType, selectAdUnit)
  }

  const getAdUnitsData = () => {
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name: userInfo.name,
        partnerName: userInfo.name,
        inventoryType: ''
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards/getAdunit", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is adUnitdData ==>", res)
        if (res.data) {
          setAdUnitData(objectModal(res.data.stdbAdUnitName))
          setHighImpactAdUnitData(objectModal(res.data.highImpactAdUnitName))
          setVideoAdUnitData(objectModal(res.data.videoAdUnitName))
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }


  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    getOverViewData(pType)
  };


  const getOverViewData = (pType) => {
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        frequency: pType,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards/overView", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is dashBoardData ==>", res)
        if (res.data) {
          if (res.data.overview.length > 0) {
            xAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.overview.map((item, index) => {
              xAxis.push(item['x-axis'])
            })
            res.data.overview.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.overview.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.overview.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setChartData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setChartData([])
            xAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const stdbOnChangeChartPeriod = pType => {
    setStdbPeriodType(pType);
    getStdbData(pType, selectedAdUnit)
  };


  const getStdbData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards/stdb", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is dashBoardData ==>", res)
        if (res.data) {
          if (res.data.stdb.length > 0) {
            stdbXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.stdb.map((item, index) => {
              stdbXAxis.push(item['x-axis'])
            })
            res.data.stdb.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.stdb.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.stdb.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setStdbPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setStdbPeriodData([])
            stdbXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const highImpactOnChangeChartPeriod = pType => {
    setHighImpactPeriodType(pType);
    getHighImpactData(pType, selectedHighImpactAdUnit)
  };


  const getHighImpactData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards/highImpact", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is dashBoardData ==>", res)
        if (res.data) {
          if (res.data.highImpact.length > 0) {
            highImpactXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.highImpact.map((item, index) => {
              highImpactXAxis.push(item['x-axis'])
            })
            res.data.highImpact.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.highImpact.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.highImpact.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setHighImpactPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          } else {
            setHighImpactPeriodData([])
            highImpactXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const videoOnChangeChartPeriod = pType => {
    setVideoPeriodType(pType);
    getVideoOverViewData(pType, selectedVideoAdUnit)
  };


  const getVideoOverViewData = (pType, adUnit) => {
    let listData = []
    adUnit.length > 0 ? adUnit.map((item, index) => {
      listData.push(item.value)
    }) : []
    const body = {
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        partnerName: userInfo.name,
        frequency: pType,
        "adunit": listData,
        "startDate": moment(fromDate).format("YYYY-MM-DD"),
        "endDate": moment(toDate).format("YYYY-MM-DD"),
      
    }
    axios.post("/v2.0/partnerRe/Query/Revenue/DashBoards/video", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is dashBoardData ==>", res)
        if (res.data) {
          if (res.data.video.length > 0) {
            videoXAxis = []
            let estimatedImpressions = []
            let estimatedRevenue = []
            let estimatedeCPM = []
            res.data.video.map((item, index) => {
              videoXAxis.push(item['x-axis'])
            })
            res.data.video.map((item, index) => {
              estimatedImpressions.push(item['estimatedImpressions'] < 0 ? 0 : item['estimatedImpressions'])
            })
            res.data.video.map((item, index) => {
              estimatedRevenue.push(item['estimatedRevenue'] < 0 ? 0 : item['estimatedRevenue'])
            })
            res.data.video.map((item, index) => {
              estimatedeCPM.push(item['estimatedeCPM'] < 0 ? 0 : item['estimatedeCPM'])
            })
            setVideoPeriodData([
              { name: 'Total Code Served', type: "column", data: estimatedImpressions },
              { name: 'Estimated Revenue', type: "column", data: estimatedRevenue },
              { name: 'Estimated eCPM', type: "line", data: estimatedeCPM },
            ])
          }
          else {
            setVideoPeriodData([])
            videoXAxis = []
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  useEffect(() => {
    console.log(loading)
    if (loading) {
      setSwitchLoader(true)
    }
  }, [loading]);


  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(onGetChartsData("year"));
  // }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Marketplace Dashboard | Bidsxchange</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col md="5">
              <Breadcrumbs
                title={props.t("Marketplace Dashboard")}
                breadcrumbItem={props.t("Marketplace Dashboard")}
              />
            </Col>
            <Col md="7">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end mb-4">
                    {(userInfo.inventoryType === 'both' || userInfo.inventoryType === 'display') &&
                      <Input type="select" className="form-select" style={{ width: '18pc' }} value={inventoryType} onChange={changeInventory}>
                        <option value=''> Select Inventory Type</option>
                        <option value='stdb' >Standard Banner</option>
                        <option value='highImpact'> High Impact</option>
                        {userInfo.inventoryType === 'both' &&
                          <option value='video'> Video</option>
                        }
                      </Input>
                    }
                    &nbsp;&nbsp;
                    <DateRangePickerDashboard datePick={changeDatePick} startDate={dateFromDate} endDate={dateToDate} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {/* <Col xl="4">
                <WelcomeComp />
              </Col> */}
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {/* <Col md='4'>
                  <WelcomeComp />
                </Col> */}
                {switchLoader &&
                  <LoadingDashboardCard loading={loading} targetLoading={handleSwitch} />
                }
                {!switchLoader && reportData.map((report, key) => (
                  <Col md="" key={"_col_" + key}>
                    <Card className="mini-stats-wid dashboard-cards text-center">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 style={{ marginBottom: '3%' }}>{report.description}</h4>

                            <span className={report.isIncreased === 'yes' ? 'badge badge-soft-success' : report.isIncreased === 'no' ? 'badge badge-soft-danger' : 'badge badge-soft-warning'} style={{ fontSize: '12px', fontWeight: '500', marginBottom: '0.7em' }}>
                              {(report.isIncreased === 'yes' ? <i className="mdi mdi-arrow-up " /> : report.isIncreased === 'no' ? <i className="mdi mdi-arrow-down " /> : '')} {report.yesterdayRate}</span>                               <br></br>
                            &nbsp;&nbsp;From Previous Period
                          </div>
                          {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="12">

              <Card>
                <CardBody>
                  {/* <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3">
                
                    </div>
                </div> */}
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Overview of Total code served | Revenue | Estimated eCPM</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item mb-4">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "day" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("day");
                            }}
                            id="one_month"
                          >
                            Day
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "week" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("week");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "month" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("month");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                  <Publisher_StackChart chartData={chartData} xAxisData={xAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                </CardBody>
              </Card>
              {(userInfo.inventoryType === 'both' || userInfo.inventoryType === 'video') &&
                <Card>
                  <CardBody>
                    {/*<div className="d-sm-flex flex-wrap">*/}
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Video</h4>
                      <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                        <MultiSelect
                          options={videoAdUnitData}
                          value={selectedVideoAdUnit}
                          onChange={onChangeVideoAdUnit}
                          overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                        />
                      </span>
                      <div className="ms-auto">
                        <ul className="nav nav-pills">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: videoPeriodType === "day" },
                                "nav-link"
                              )}
                              onClick={() => {
                                videoOnChangeChartPeriod("day");
                              }}
                              id="one_month"
                            >
                              Day
                            </Link>
                          </li>
                          <li className="nav-item">

                            <Link
                              to="#"
                              className={classNames(
                                { active: videoPeriodType === "week" },
                                "nav-link"
                              )}
                              onClick={() => {
                                videoOnChangeChartPeriod("week");
                              }}
                              id="one_month"
                            >
                              Week
                            </Link>{" "}
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: videoPeriodType === "month" },
                                "nav-link"
                              )}
                              onClick={() => {
                                videoOnChangeChartPeriod("month");
                              }}
                              id="one_month"
                            >
                              Month
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="clearfix mt-2"></div>
                    <Publisher_StackChart chartData={videoPeriodData} xAxisData={videoXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                  </CardBody>
                </Card>
              }
              {(userInfo.inventoryType === 'both' || userInfo.inventoryType === 'display') &&
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">High Impact</h4>
                      <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                        <MultiSelect
                          options={highImpactAdUnitData}
                          value={selectedHighImpactAdUnit}
                          onChange={onChangeHighImpactAdUnit}
                          overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                        />
                      </span>
                      <div className="ms-auto">
                        <ul className="nav nav-pills ">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: highImpactPeriodType === "day" },
                                "nav-link"
                              )}
                              onClick={() => {
                                highImpactOnChangeChartPeriod("day");
                              }}
                              id="one_month"
                            >
                              Day
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: highImpactPeriodType === "week" },
                                "nav-link"
                              )}
                              onClick={() => {
                                highImpactOnChangeChartPeriod("week");
                              }}
                              id="one_month"
                            >
                              Week
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: highImpactPeriodType === "month" },
                                "nav-link"
                              )}
                              onClick={() => {
                                highImpactOnChangeChartPeriod("month");
                              }}
                              id="one_month"
                            >
                              Month
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="clearfix mt-2"></div>
                    <Publisher_StackChart chartData={highImpactPeriodData} xAxisData={highImpactXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                  </CardBody>
                </Card>
              }
              {(userInfo.inventoryType === 'both' || userInfo.inventoryType === 'display') &&
                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Standard Banner</h4>
                      <span style={{ position: 'absolute', width: '35%', left: '30%' }}>
                        <MultiSelect
                          options={adUnitData}
                          value={selectedAdUnit}
                          onChange={onChangeAdUnit}
                          overrideStrings={{ "selectSomeItems": "Please Select AdUnits" }}
                        />
                      </span>
                      <div className="ms-auto">
                        <ul className="nav nav-pills ">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: stdbPeriodType === "day" },
                                "nav-link"
                              )}
                              onClick={() => {
                                stdbOnChangeChartPeriod("day");
                              }}
                              id="one_month"
                            >
                              Day
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: stdbPeriodType === "week" },
                                "nav-link"
                              )}
                              onClick={() => {
                                stdbOnChangeChartPeriod("week");
                              }}
                              id="one_month"
                            >
                              Week
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={classNames(
                                { active: stdbPeriodType === "month" },
                                "nav-link"
                              )}
                              onClick={() => {
                                stdbOnChangeChartPeriod("month");
                              }}
                              id="one_month"
                            >
                              Month
                            </Link>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="clearfix mt-2"></div>
                    <Publisher_StackChart chartData={stdbPeriodData} xAxisData={stdbXAxis} dataColors='["--bs-danger","--bs-primary", "--bs-success"]' title={''} />
                  </CardBody>
                </Card>
              }
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};


Publisher_Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
}

export default withTranslation()(Publisher_Dashboard)