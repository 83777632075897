import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_HEADERBIDS, ADD_HEADERBID, UPDATE_HEADERBID,UPDATE_ADUNITBID, READ_HEADERBID, STATUS_HEADERBID, ARCHIVE_HEADERBID } from "./actionTypes"

import {
  loadActivitiesHeaderBids,
  noDataHeaderBids,
  getHeaderBidsSuccess,
  getHeaderBidsFail,
  addHeaderBidSuccess,
  addHeaderBidFail,
  updateHeaderBidSuccess,
  updateHeaderBidFail,
  updateAdUnitBidSuccess,
  updateAdUnitBidFail,
  readHeaderBidSuccess,
  statusHeaderBidSuccess,
  archiveHeaderBidSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getHeaderBid,  createHeaderBid, updateHeaderBid,updateAdUnitBid, readHeaderBid, statusChangeHeaderBid, archiveHeaderBid } from "../../../helpers/Backend_helper"

function* fetchHeaderBids({ payload: adv }) {
  try {
    console.log(adv)
    yield put(loadActivitiesHeaderBids(true))
    var getIOGList
    if (adv) {
      getIOGList = yield call(getHeaderBid, adv)
      console.log(getIOGList)
      getIOGList.data.map((item, index) => {
        item.id = index + 1
      })
      if (getIOGList.data.length === 0) {
        yield put(noDataHeaderBids(true))
      }
      yield put(getHeaderBidsSuccess(getIOGList.data))
    } else {
      getIOGList = []
      yield put(getHeaderBidsSuccess(getIOGList))
    }
  } catch (error) {
    yield put(getHeaderBidsFail(error))
  }
}

function* onReadHeaderBid({ payload: adv }) {
  try {
    const response = yield call(readHeaderBid, adv)
    yield put(readHeaderBidSuccess(response))
  } catch (error) {
    yield put(readHeaderBidSuccess(error))
  }
}

function* onAddHeaderBid({ payload: adv }) {
  try {
    const response = yield call(createHeaderBid, adv)
    if (response.success) {
      yield put(addHeaderBidSuccess(response))
    } else {
      yield put(addHeaderBidFail(response))
    }
  } catch (error) {
    yield put(addHeaderBidFail(error))
  }
}

function* onUpdateHeaderBid({ payload: adv }) {
  try {
    const response = yield call(updateHeaderBid, adv)
    if (response.success) {
      yield put(updateHeaderBidSuccess(response))
    } else {
      yield put(updateHeaderBidFail(response))
    }
  } catch (error) {
    yield put(updateHeaderBidFail(error))
  }
}

function* onUpdateAdUnitBid({ payload: adv }) {
  try {
    const response = yield call(updateAdUnitBid, adv)
    if (response.success) {
      yield put(updateAdUnitBidSuccess(response))
    } else {
      yield put(updateAdUnitBidFail(response))
    }
  } catch (error) {
    yield put(updateAdUnitBidFail(error))
  }
}

function* onStatusHeaderBid({ payload: adv }) {
  try {
    const response = yield call(statusChangeHeaderBid, adv)
    yield put(statusHeaderBidSuccess(response))
  } catch (error) {
    yield put(statusHeaderBidSuccess(error))
  }
}

function* onArchiveHeaderBid({ payload: adv }) {
  try {
    const response = yield call(archiveHeaderBid, adv)
    yield put(archiveHeaderBidSuccess(response))
  } catch (error) {
    yield put(archiveHeaderBidSuccess(error))
  }
}

function* headerBidSaga() {
  yield takeEvery(GET_HEADERBIDS, fetchHeaderBids)
  yield takeEvery(ADD_HEADERBID, onAddHeaderBid)
  yield takeEvery(UPDATE_HEADERBID, onUpdateHeaderBid)
  yield takeEvery(UPDATE_ADUNITBID, onUpdateAdUnitBid)
  yield takeEvery(READ_HEADERBID, onReadHeaderBid)
  yield takeEvery(STATUS_HEADERBID, onStatusHeaderBid)
  yield takeEvery(ARCHIVE_HEADERBID, onArchiveHeaderBid)
}

export default headerBidSaga;
