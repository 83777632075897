import React, { useEffect, useState, useRef } from "react";
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import PropTypes from 'prop-types'
import {
    Col, Container, Row, Input, Label, Form, Button,
    Card,
    CardBody,
    CardTitle,
    CardImg,
    CardText,Spinner
} from "reactstrap"
import { withRouter, Link } from "react-router-dom";

import OTTApps from "assets/images/bidsxchange/Adformat_Images/ott-1.png"
import videoStories from "assets/images/bidsxchange/Adformat_Images/stories-1.png"
import contextualVideo from "assets/images/bidsxchange/Adformat_Images/context-video-1.png"
import adPushDown from "assets/images/bidsxchange/Adformat_Images/ad-push-down-1.png"
import pagePushDown from "assets/images/bidsxchange/Adformat_Images/pagepush-down.png"
import interstitial from "assets/images/bidsxchange/Adformat_Images/inter.png"
import parallax from "assets/images/bidsxchange/Adformat_Images/parallax.png"
import sliderAds from "assets/images/bidsxchange/Adformat_Images/slider.png"
import towerAds from "assets/images/bidsxchange/Adformat_Images/tower.png"
import stayOn from "assets/images/bidsxchange/Adformat_Images/Stayon-1.png"
import inContent from "assets/images/bidsxchange/Adformat_Images/incontent.png"
import audio from "assets/images/bidsxchange/Adformat_Images/audio-flash-1.png"
import stdNativeUnits from "assets/images/bidsxchange/Adformat_Images/native-1.png"
import underImage from "assets/images/bidsxchange/Adformat_Images/underimage.png"
import inImage from "assets/images/bidsxchange/Adformat_Images/in-image-2.png"

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import axios from "axios";
import Agency_Cards_Loading from "./Agency_Cards_Loading";

var explore = [
    {
        "adFromat": "OTT apps",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Want to deliver exceptional experiences for viewers while earning high eCPMs? Our OTT solution makes it easy for publishers to start monetizing their on-demand and live OTT inventory.',
        imgSrc: OTTApps,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Video Stories",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Video stories are the blend of audio, video, and images to offer a dynamic experience. Video stories turn your current content into engaging formats. These stories deliver high incremental revenue.',
        imgSrc: videoStories,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Contextual Video",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Contextual video ensures that the audience finds the ad to be associated with what is being viewed, and thus, would be involved in understanding the product. Contextual video ads have more impact.',
        imgSrc: contextualVideo,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Ad Push Down",
        "isSelected": false,
        "isFirstTime": false,
        description: 'A unit that displays a teaser image initially. The teaser image is displayed till the user hovers the mouse or clicks the expansion button. This ad format increases the earning potential of website',
        imgSrc: adPushDown,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Page Push Down",
        "isSelected": false,
        "isFirstTime": false,
        description: 'When a user opens a page that features your ad, the page is shifted down for at least seven seconds creating space for the ad. Higher ROI and CTR are some of the proven benefits of this ad unit.',
        imgSrc: pagePushDown,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Interstitial",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Interstitial ads are fast-loading full-page static, rich media, or video ad units that are displayed in the middle of the screen. The ad is perfect for additional monetization as it can be customized.',
        imgSrc: interstitial,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Parallax",
        "isSelected": false,
        "isFirstTime": false,
        description: 'This is an attractive display format specifically designed for the mobile web. It offers a layered, animation effect to grab the attention. Users must scroll down the page to view this ad unit.',
        imgSrc: parallax,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Slider Ads",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Slider ads fade in or slide in at the bottom side of the web page. These ads give you added inventory without hampering the current ad space. It moves along with the page as the user scrolls down.',
        imgSrc: sliderAds,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Tower Ads",
        "isSelected": false,
        "isFirstTime": false,
        description: 'These ads are tall and thin in shape that makes them look like a tower. These ads run at the center column of a page (on the left or right side) and do not move when the user scrolls the page.',
        imgSrc: towerAds,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Stay On",
        "isSelected": false,
        "isFirstTime": false,
        description: 'A Stay On ad appears at the bottom of a page horizontally. This ad can be closed with a click of a button. The ad uses JavaScript/HTML code to slide the ad content into a web page. The ad does not move…',
        imgSrc: stayOn,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "In-Content",
        "isSelected": false,
        "isFirstTime": false,
        description: 'In-content ads are placed within the content of a web page where users are engaged the most. As In-content ads are integrated into a page’s content they are the best option for high engagement.',
        imgSrc: inContent,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Audio (Beta)",
        "isSelected": false,
        "isFirstTime": false,
        description: 'We help summarize your story for people who do not like reading long content. They understand the story by listening to the audio. It helps to establish a close connection with the listeners.',
        imgSrc: audio,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Standard Native Units",
        "isSelected": false,
        "isFirstTime": false,
        description: `These ad units generally appear naturally within the app’s content.  This ad unit is more likely to capture your audience’s attention as it fits in with the content on the page.`,
        imgSrc: stdNativeUnits,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "Under Image",
        "isSelected": false,
        "isFirstTime": false,
        description: 'Under image ads appear just below an image. As a format, it grabs the attention of the reader and offers higher payouts.',
        imgSrc: underImage,
        isOldOne: false,
        status:'NONE'
    },
    {
        "adFromat": "In-Image",
        "isSelected": false,
        "isFirstTime": false,
        description: 'In-image ads are placed at the base of editorial images on web pages in the form of an overlay. These ads can also be rich media or video ad units that appear when the users click or hover the mouse…',
        imgSrc: inImage,
        isOldOne: false,
        status:'NONE'
    },
]

const Agency_ExploreAdFormats = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [statusSearch, setStatusSearch] = useState('')

    const [exploreData, setExploreData] = useState([
        {
            "adFromat": "OTT apps",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Want to deliver exceptional experiences for viewers while earning high eCPMs? Our OTT solution makes it easy for publishers to start monetizing their on-demand and live OTT inventory.',
            imgSrc: OTTApps,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Video Stories",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Video stories are the blend of audio, video, and images to offer a dynamic experience. Video stories turn your current content into engaging formats. These stories deliver high incremental revenue.',
            imgSrc: videoStories,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Contextual Video",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Contextual video ensures that the audience finds the ad to be associated with what is being viewed, and thus, would be involved in understanding the product. Contextual video ads have more impact.',
            imgSrc: contextualVideo,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Ad Push Down",
            "isSelected": false,
            "isFirstTime": false,
            description: 'A unit that displays a teaser image initially. The teaser image is displayed till the user hovers the mouse or clicks the expansion button. This ad format increases the earning potential of website',
            imgSrc: adPushDown,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Page Push Down",
            "isSelected": false,
            "isFirstTime": false,
            description: 'When a user opens a page that features your ad, the page is shifted down for at least seven seconds creating space for the ad. Higher ROI and CTR are some of the proven benefits of this ad unit.',
            imgSrc: pagePushDown,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Interstitial",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Interstitial ads are fast-loading full-page static, rich media, or video ad units that are displayed in the middle of the screen. The ad is perfect for additional monetization as it can be customized.',
            imgSrc: interstitial,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Parallax",
            "isSelected": false,
            "isFirstTime": false,
            description: 'This is an attractive display format specifically designed for the mobile web. It offers a layered, animation effect to grab the attention. Users must scroll down the page to view this ad unit.',
            imgSrc: parallax,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Slider Ads",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Slider ads fade in or slide in at the bottom side of the web page. These ads give you added inventory without hampering the current ad space. It moves along with the page as the user scrolls down.',
            imgSrc: sliderAds,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Tower Ads",
            "isSelected": false,
            "isFirstTime": false,
            description: 'These ads are tall and thin in shape that makes them look like a tower. These ads run at the center column of a page (on the left or right side) and do not move when the user scrolls the page.',
            imgSrc: towerAds,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Stay On",
            "isSelected": false,
            "isFirstTime": false,
            description: 'A Stay On ad appears at the bottom of a page horizontally. This ad can be closed with a click of a button. The ad uses JavaScript/HTML code to slide the ad content into a web page. The ad does not move…',
            imgSrc: stayOn,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "In-Content",
            "isSelected": false,
            "isFirstTime": false,
            description: 'In-content ads are placed within the content of a web page where users are engaged the most. As In-content ads are integrated into a page’s content they are the best option for high engagement.',
            imgSrc: inContent,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Audio (Beta)",
            "isSelected": false,
            "isFirstTime": false,
            description: 'We help summarize your story for people who do not like reading long content. They understand the story by listening to the audio. It helps to establish a close connection with the listeners.',
            imgSrc: audio,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Standard Native Units",
            "isSelected": false,
            "isFirstTime": false,
            description: `These ad units generally appear naturally within the app’s content.  This ad unit is more likely to capture your audience’s attention as it fits in with the content on the page.`,
            imgSrc: stdNativeUnits,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "Under Image",
            "isSelected": false,
            "isFirstTime": false,
            description: 'Under image ads appear just below an image. As a format, it grabs the attention of the reader and offers higher payouts.',
            imgSrc: underImage,
            isOldOne: false,
            status:'NONE'
        },
        {
            "adFromat": "In-Image",
            "isSelected": false,
            "isFirstTime": false,
            description: 'In-image ads are placed at the base of editorial images on web pages in the form of an overlay. These ads can also be rich media or video ad units that appear when the users click or hover the mouse…',
            imgSrc: inImage,
            isOldOne: false,
            status:'NONE'
        },
    ])
    const [selectedAdFormats, setSelectedAdFormats] = useState([])
    const [loading, setLoading] = useState(true)
    const [filteredAdFormats, setFilteredAdFormats] = useState([])

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const statusChange = (e) => {
        setStatusSearch(e.target.value)
        console.log(filteredAdFormats)
        if(filteredAdFormats.length > 0){
            if (e.target.value === 'Audio') {
                setExploreData([filteredAdFormats[11]])
            } else if (e.target.value === 'Display') {
                setExploreData([filteredAdFormats[3], filteredAdFormats[4], filteredAdFormats[5], filteredAdFormats[6], filteredAdFormats[7], filteredAdFormats[8], filteredAdFormats[9], filteredAdFormats[10], filteredAdFormats[13], filteredAdFormats[14]])
            }
            else if (e.target.value === 'Native') {
                setExploreData([filteredAdFormats[12]])
            }
            else if (e.target.value === 'Video') {
                setExploreData([filteredAdFormats[0], filteredAdFormats[1], filteredAdFormats[2]])
            } else {
                setExploreData(filteredAdFormats)
            }
        }
        console.log(e.target.value)
    }


    useEffect(() => {
        readExploreAdformat()
    }, []);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            "companyID": userInfo.companyID,
            partnerName: userInfo.name,
            userEmail: userInfo.username
    }

    const readExploreAdformat = (e) => {
        // let exploreDatas = [...exploreData]
        // console.log(exploreDatas)
        setLoading(true)
        axios.post("/v2.0/partnerOp/exploreAdFormat/read/forPartner", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                setLoading(false)
                if (res.data.data) {
                    if (res.data.data.data && res.data.data.data.length > 0) {
               
                        res.data.data.data.map((item, i) => {
                            item.isSelected = item.isSelected
                            item.isFirstTime = item.isFirstTime
                            item.isOldOne = item.isSelected
                            item.imgSrc = explore[i].imgSrc
                            item.description = explore[i].description
                            item.status = item.status
                        })
                        // console.log(exploreDatas)
                        setSelectedAdFormats([])
                        // setExploreData([...res.data.data.data])
                        setFilteredAdFormats([...res.data.data.data])
                        if(res.data.data.data.length > 0){
                            if (statusSearch === 'Audio') {
                                setExploreData([res.data.data.data[11]])
                            } else if (statusSearch === 'Display') {
                                setExploreData([res.data.data.data[3], res.data.data.data[4], res.data.data.data[5], res.data.data.data[6], res.data.data.data[7], res.data.data.data[8], res.data.data.data[9], res.data.data.data[10], res.data.data.data[13], res.data.data.data[14]])
                            }
                            else if (statusSearch === 'Native') {
                                setExploreData([res.data.data.data[12]])
                            }
                            else if (statusSearch === 'Video') {
                                setExploreData([res.data.data.data[0], res.data.data.data[1], res.data.data.data[2]])
                            } else {
                                setExploreData(res.data.data.data)
                            }
                        }
                    }else{
                        setFilteredAdFormats([...exploreData])
                    }
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }

    const changeSelection = (name, index) => (e) => {
        // let adFormatsLists = [...selectedAdFormats]
        // if (e.target.checked) {
        //     adFormatsLists.push(name)
        //     setSelectedAdFormats(adFormatsLists)
        // } else {
        //     let itemIndex = adFormatsLists.findIndex(x => x === name)
        //     adFormatsLists.splice(itemIndex, 1)
        //     setSelectedAdFormats(adFormatsLists)
        // }
        if(filteredAdFormats.length > 0){
            let ads = [...filteredAdFormats]
            let itemIndex = ads.findIndex(x => x.adFromat === name) 
            ads[itemIndex].isSelected = true
            ads[itemIndex].isFirstTime = true
  
        let exploreAdFormatsLists = [...exploreData]
        console.log(exploreAdFormatsLists)
        exploreAdFormatsLists[index].isSelected = true
        exploreAdFormatsLists[index].isFirstTime = true
        setExploreData(exploreAdFormatsLists)
        requestAdFormats(ads)
        }
    }

    const requestAdFormats = (exploreAdFormatsLists) => {
        setLoading(true)
    
        let finalData = [...exploreAdFormatsLists]
        finalData.map((item, i) => {
            delete item.description
            delete item.imgSrc
            delete item.isOldOne
        })
        console.log(finalData)
        let exploreBody = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                "companyID": userInfo.companyID,
                partnerName: userInfo.name,
                userEmail: userInfo.username,
                "data": finalData
        }
        console.log(exploreBody)
        axios.post("/v2.0/partnerOp/exploreAdFormat/update/forPartner", exploreBody,
            {
                headers: headers
            })
            .then((res) => {
                setLoading(false)
                console.log("result is ==>", res)
                if (res.data) {
                    toastr.success('Request for AdFormats has been raised')
                    console.log(exploreData)
                    readExploreAdformat()

                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("e", err)
            });
    }


    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
                <div className="page-content">
                    <MetaTags>
                        <title>Explore Adformats | Bidsxchange</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Revenue Opportunities" breadcrumbItem="Explore Adformats" />
                        <Row>
                            <div className="col-12">

                                <Card>
                                    <CardBody>
                                        <div className="row mt-4">
                                            <div className="col-8">
                                                <h2 className="title">Streamlyn Ad Gallery	</h2>
                                                <div className="divider mt-3 mb-3 "></div>
                                                <p className="subtitle"> Our technology allows Publishers to produce creative online advertising for any devices within minutes.</p>
                                            </div>
                                            <div className="col-4">
                                                <div className="text-center">
                                                    <Link
                                                        type="button" to={{ pathname: "https://demo.bidsxchange.com/v2/" }} target='_blank'
                                                        className="btn btn-outline-primary "
                                                        style={{ position: 'absolute', bottom: '3pc' }}
                                                        rel='noopener noreferrer'
                                                    // eslint-disable-next-line react/prop-types
                                                    >
                                                        View Demo
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <Card>
                                            <CardBody aria-hidden="true">
                                                <Row >
                                                    <Col sm="4">
                                                        <Label className="form-label">Filter Ads</Label>
                                                        <div className=" text-sm-end">
                                                            <Input type="select" className="form-select" value={statusSearch} onChange={statusChange}>
                                                                <option value=''> All</option>
                                                                <option value='Audio' > Audio(Beta)</option>
                                                                <option value='Display'> Display</option>
                                                                <option value='Native'> Native</option>
                                                                <option value='Video'> Video</option>
                                                            </Input>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </CardBody>
                                        </Card>
                                        {loading &&
                                            // <Spinner className="ms-2 loader" color="primary" />
                                            <Agency_Cards_Loading />
                                        }
                                        {!loading &&
                                         <div className="row mt-4">
                                         {
                                             exploreData.map((subItem, i) => {
                                                 return (

                                                     <Col mg={4} xl={4} xxl={3} key={i}>
                                                         <Card style={{ height: '30pc' }}>
                                                             <CardImg top className="img-fluid" src={subItem.imgSrc} alt={subItem.adFromat} />
                                                             <CardBody>
                                                                 <CardTitle className="mt-0">
                                                                     <div className="row">
                                                                         <div className="col-12">
                                                                             {subItem.adFromat}
                                                                         </div>
                                                                         {/* <div className="col-3">
                                                                             <div className="form-check  mb-2">
                                                                                 <input
                                                                                     className="form-check-input"
                                                                                     type="checkbox"
                                                                                     checked={subItem.isSelected}
                                                                                     name={subItem.adFromat}
                                                                                     disabled={subItem.isOldOne}
                                                                                     onChange={changeSelection(subItem.adFromat, i)}
                                                                                 />
                                                                             </div>
                                                                         </div> */}
                                                                  
                                                                     </div></CardTitle>
                                                                 <CardText>
                                                                     {subItem.description}
                                                                     <div className="hstack gap-2 justify-content-center" style={{position:'absolute',bottom:'5px', left:'0',right:'0'}}>
                                                                         {subItem.status === 'PENDING' &&
                                                                             <button type="button" className="btn btn-secondary" disabled >Request Raised</button>
                                                                         }
                                                                         {subItem.status === 'NONE' &&
                                                                             <button type="button" className="btn btn-primary" onClick={changeSelection(subItem.adFromat, i)}>Request AdFormat</button>
                                                                         }
                                                                             {subItem.status === 'REJECTED' &&
                                                                             <button type="button" className="btn btn-danger" disabled>Rejected</button>
                                                                         }
                                                                             {subItem.status === 'APPROVED' &&
                                                                             <button type="button" className="btn btn-success" disabled>Approved</button>
                                                                         }
                                                                     </div>
                                                                 </CardText>

                                                             </CardBody>
                                                         </Card>
                                                     </Col>
                                                 )
                                             })
                                         }
                                     </div>
                                        }

                                        {selectedAdFormats.length > 0 &&

                                            <div className="text-center">
                                                <Link
                                                    type="button" to={'#'}
                                                    className="btn btn-primary "
                                                    style={{ position: 'fixed', bottom: '2%', width: '20%', left: '50%', fontSize: '16px' }}
                                                    rel='noopener noreferrer'
                                                    onClick={requestAdFormats}
                                                // eslint-disable-next-line react/prop-types
                                                >
                                                    Request Adformats
                                                </Link>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>
            {/* </div> */}
        </React.Fragment >
    )
}

Agency_ExploreAdFormats.propTypes = {}

export default Agency_ExploreAdFormats