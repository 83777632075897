/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Row, Col, Input, Label, Form, FormFeedback, DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown, Spinner, Collapse
} from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import Toaster from "pages/Toaster/Toaster";

// actions
import {
    addNewHighImpactSetting as onADDHighImpactSetting,
    updateHighImpactSetting as onUpdateHighImpactSetting,
    readHighImpactSetting as onReadHighImpactSetting,
    addHighImpactSettingSuccess,
    addHighImpactSettingFail,
    updateHighImpactSettingSuccess,
    updateHighImpactSettingFail,
} from "store/Supply/HighImpactSettings/actions";

import {
    getAdUnit as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

var templateName = ''
var creativeBody = {}
var creativeBodyInfo = []

const NewHighImpact = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [publisher, setPublisher] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [partnerData, setPartnerData] = useState([])
    const [selectedPartner, setSelectedPartner] = useState(null);
    const nextRef = useRef();
    const [nextTab, setNextTab] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [adUnitGroupType, setAdUnitGroupType] = useState('');
    const [loader, setLoader] = useState(false);
    console.log(props)

    const [templateSelect, setTemplateSelect] = useState('');
    const [templatesData, setTemplatesData] = useState([]);
    const [creativeData, setCreativeData] = useState([])
    const [adUnitData, setAdUnitData] = useState([])
    const [selectedAdUnit, setSelectedAdUnit] = useState(null);

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name:userInfo.name,
        
    }

    const [finalData, setFinalData] = useState({
        highImpactSettingName: '',
        // description: '',
        highImpactSettingTemplateName: '',
        adUnitID: '',
        highImpactSettingData: {}
    })

    useEffect(() => {
        dispatch(onGetAdUnits(null));
    }, [])

    const { adUnits } = useSelector(state => ({
        adUnits: state.adUnits.adUnitList
    }));

    console.log(adUnits)

    useEffect(() => {
        if (adUnits !== null) {
            dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: 'highImpact', adUnitSize: '' }));
        }
    }, []);

    useEffect(() => {
        if (adUnits && adUnits.length > 0) {
            setAdUnitData(objectAdUnitModal(adUnits))
            console.log(adUnits)
        }
    }, [adUnits]);

    const objectAdUnitModal = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.adUnitID
            obj.label = item.adUnitName
            dataList.push(obj)
        })
        return dataList
    }

    function handleSelectAdUnit(selectAdUnit) {
        setSelectedAdUnit(selectAdUnit);
        if (finalData.highImpactSettingName === '') {
            setFinalData(finalData => ({ ...finalData, adUnitID: selectAdUnit.value, highImpactSettingName: selectAdUnit.label + "_HIS" }));
        }
        setFinalData(finalData => ({ ...finalData, adUnitID: selectAdUnit.value }));
    }

    useEffect(() => {
        console.log(props.selectedHighImpact)
        creativeBodyInfo = []
        if (props.selectedHighImpact !== '') {
            creativeBodyInfo.push(props.selectedHighImpact.highImpactSettingData)
            setTemplateSelect({value:props.selectedHighImpact.highImpactSettingTemplateName,label:props.selectedHighImpact.highImpactSettingTemplateName})
            setFinalData(finalData => ({ ...finalData, ...props.selectedHighImpact}));
            handleData(props.selectedHighImpact.highImpactSettingTemplateName)
            setIsEdit(true)
        }
        console.log(creativeBodyInfo)
      }, [])

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.highImpactSettings.errorMsg,
        successMsg: state.highImpactSettings.successMsg,
      }));
    
      console.log(errorMsg, successMsg)

      useEffect(() => {
        if (successMsg !== null) {
          if (successMsg.success) {
            dispatch(addHighImpactSettingSuccess(""));
            dispatch(addHighImpactSettingFail(""));
            dispatch(updateHighImpactSettingSuccess(""));
            dispatch(updateHighImpactSettingFail(""));
            setLoader(false)
              if (props.selectedHighImpact !== '') {
                props.closeCanvas('Update')
              } else {
                props.closeCanvas('Create')
              }
          }
        }
      }, [successMsg]);

      useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
          setLoader(false)
        }
      }, [errorMsg]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const contentChange = index => e => {
        console.log(e.target)
        let newArr = [...creativeData];
        newArr[index].value = e.target.value;
        setCreativeData(newArr);
        console.log(creativeData)
        creativeData.map((item, index) => {
            const { label, value } = item;
            console.log(value)
            let numMul = isNaN(value)
            if (item.variableType === "NUMERIC" && numMul) {
                if (value) {
                    console.log(value)
                    creativeBody = { ...creativeBody, [label]: parseInt(value) }
                }
            } else if (item.variableType === "JSONArray") {
                let urls = value.split(/[\s,]+/);
                creativeBody = { ...creativeBody, [label]: urls }
            }
            else {
                if (value === 'YES') {
                    creativeBody = { ...creativeBody, [label]: true }
                } else if (value === 'NO') {
                    creativeBody = { ...creativeBody, [label]: false }
                } else {
                    creativeBody = { ...creativeBody, [label]: value }
                }
            }
            console.log(creativeBody)
            setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
        })
        console.log(creativeBody)
        console.log(finalData)
    }

    useEffect(() => {
      
        body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name:userInfo.name,

            CreativeTemplateType: "highImpact",
            CreativeTemplateFor: "HighImapct"
        }
        axios.post("/v2.0/OP/CreativeTemplates/list", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    console.log(res.data.response)
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setTemplatesData(objectModals(res.data.response))
                    console.log(templatesData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }, [])

    const objectModals = (response) => {
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item.creativeTemplateName
            obj.label = item.creativeTemplateName
            dataList.push(obj)
        })
        return dataList
    }

    const templateChange = (event) => {
        console.log(event)
        if (event.value) {
            setTemplateSelect(event);
            handleData(event.value)
        }
    };

    const handleData = async (template) => {
        const bodyData = {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name:userInfo.name,
                creativeTemplateName: template
            }
        
        axios.post("/v2.0/OP/CreativeTemplates/getVariable", bodyData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": `Bearer ${userInfo.token}`
                }
            })
            .then((res) => {
                console.log("result is etttgdfg ==>", res)
                if (res.data) {
                    templateName = res.data.creativeTemplateName
                    console.log(templateName)
                    setFinalData(finalData => ({ ...finalData, highImpactSettingTemplateName: templateName }));
                    creativeBody = { ...creativeBody, url: res.data.url }
                    setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))

                    res.data.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        if (props.selectedHighImpact !== '') {
                          console.log(creativeBodyInfo)
                          if(creativeBodyInfo[0][label] === true){
                            item.value = 'YES'
                            item.checked = true
                          }else if(creativeBodyInfo[0][label] === false){
                            item.value = 'NO'
                            item.checked = false
                          }else{
                            item.value = creativeBodyInfo[0][label]
                          }
                          if (item.variableType === "CHECKBOX") {
                            if(creativeBodyInfo[0][label] !== ''){
                              item.checked = true
                            }else if(creativeBodyInfo[0][label] === ''){
                              item.checked = false
                            }
                          }
                          console.log(label,creativeBodyInfo[0][label])
                        } else {
                          item.value = item.defaultValue ? item.defaultValue : ''
                          if (item.variableType === "CHECKBOX") {
                            item.checked = false;
                          }
                        }
                      })

                    res.data.creativeTemplateVariables.map((item, index) => {
                        let label = item.label
                        item.value = item.defaultValue ? item.defaultValue : ''
                        if (item.variableType === "CHECKBOX") {
                            item.checked = false;
                        }
                    })
                    setCreativeData(res.data.creativeTemplateVariables);
                    res.data.creativeTemplateVariables.map((item, index) => {
                        if (item.variableType === "CHECKBOX") {
                            const { label, checked } = item;
                            creativeBody = { ...creativeBody, [label]: checked }
                        } else {
                            const { label, value } = item;
                            creativeBody = { ...creativeBody, [label]: value }
                        }
                        console.log(item)
                        console.log(creativeBody)
                        setFinalData(finalData => ({ ...finalData, highImpactSettingData: { ...finalData.highImpactSettingData, ...creativeBody } }))
                        console.log(finalData)
                    })
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }


    function allowAlphaNumericSpace(e) {
        var code = ('charCode' in e) ? e.charCode : e.keyCode;
        if (!(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault(); }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log(finalData)
        if (finalData.highImpactSettingName && finalData.adUnitID && finalData.highImpactSettingTemplateName) {
            setLoader(true)
            if (props.selectedHighImpact !== '') {
                dispatch(onUpdateHighImpactSetting({ ...finalData, highImpactSettingID: props.selectedHighImpact.highImpactSettingID }));
            } else {
                dispatch(onADDHighImpactSetting({ ...finalData }));
            }
        }
    }


    const createUI = () => {
        return creativeData.map((item, i) =>
            <div key={i}>
                {item.variableType === "STRING" &&
                    <div className="mt-4" >
                        <Label className="form-label">{item.description}</Label>
                        {item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' onKeyPress={allowAlphaNumericSpace} readOnly={props.selectedArchived}
                            />
                        }
                        {!item.defaultValue &&
                            <Input
                                type="text" name={item.label} value={item.value} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived}
                            />
                        }

                    </div>
                }
                {item.variableType === "NUMERIC" &&
                    <div className="mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="number" name={item.label} value={item.value ? parseInt(item.value) : ''} onChange={contentChange(i)} className='form-control' readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "LONG TEXT" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea" maxLength="1225"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "JSONArray" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input type="textarea" id="textarea" maxLength="1225"
                            rows="3" placeholder={item.label} className="form-control" style={{ width: '100%' }}
                            name={item.label} value={item.value} onChange={contentChange(i)} readOnly={props.selectedArchived}
                        />
                    </div>
                }
                {item.variableType === "LIST" &&
                    <div className="form-group mt-4">
                        <Label className="form-label">{item.description}</Label>
                        <Input
                            type="select"
                            className="form-select"
                            onChange={contentChange(i)}
                            name={item.label} value={item.value} disabled={props.selectedArchived}
                        >
                            <option value=''> Select {item.label}</option>
                            {item.choices.map((item) => (
                                <option key={item} value={item}>{item}</option>
                            ))}
                        </Input>
                    </div>
                }
                {item.variableType === "CHECKBOX" &&
                    <div className="form-check mt-4 mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            disabled={props.selectedArchived}
                        />
                        <label
                            className="form-check-label"
                        >
                            {item.description}
                        </label>
                    </div>
                }
            </div>
        )
    }

    return (
        <React.Fragment>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

                <Form onSubmit={handleSubmit}>
                    <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
                        <Col xs={12}>
                            <div className="mb-3">
                                <Label>Ad Unit</Label>
                                <Select
                                    value={selectedAdUnit}
                                    onChange={s => {
                                        handleSelectAdUnit(s)
                                    }}
                                    options={adUnitData}
                                    classNamePrefix="select2-selection"
                                    className={ (submitted && !finalData.adUnitID ? ' is-invalid' : '') }
                                />
                                {submitted && !finalData.adUnitID ? (
                                    <FormFeedback type="invalid">{'Please Select Ad Unit'}</FormFeedback>
                                ) : null}

                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Name</Label>
                                <Input
                                    name="highImpactSettingName"
                                    type="text"
                                    value={finalData.highImpactSettingName}
                                    onChange={handleChange}
                                    className={ (submitted && !finalData.highImpactSettingName ? ' is-invalid' : '')}
                                />
                                {submitted && !finalData.highImpactSettingName ? (
                                    <FormFeedback type="invalid">{'High Impact Settings Name is required'}</FormFeedback>
                                ) : null}
                            </div>
                            {/* <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    name="description"
                                    type="text"
                                    value={finalData.description}
                                    onChange={handleChange}
                                />
                            </div> */}
                            <div className="mb-3">
                                <Label className="form-label">Creative Templates</Label>
                                <Select
                                    value={templateSelect}
                                    onChange={s => {
                                        templateChange(s)
                                    }}
                                    options={templatesData}
                                    classNamePrefix="select2-selection"
                                    className={ (submitted && !finalData.highImpactSettingTemplateName ? ' is-invalid' : '')}
                                />
                                {submitted && !finalData.highImpactSettingTemplateName ? (
                                    <FormFeedback type="invalid">{'Please Select Creative Template'}</FormFeedback>
                                ) : null}
                            </div>
                            {templateSelect &&
                                <div className="mb-3">
                                    <h5 className="mb-4">{templateName} Settings</h5>
                                    <hr></hr>
                                    <div className="mb-3">
                                        <div className="accordion" id="accordion">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                </h2>
                                                <Collapse isOpen={true} className="accordion-collapse">
                                                    <div className="accordion-body">
                                                        {createUI()}
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
                        <Col>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    // eslint-disable-next-line react/prop-types
                                    onClick={props.closeCanvas}
                                >
                                    Back
                                </button>
                                &nbsp;&nbsp;
                                {isEdit && !props.selectedArchived &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Update
                                    </button>
                                }
                                {!isEdit &&
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-user"
                                    >
                                        Save
                                    </button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {errorMsg !== null && errorMsg !== '' &&
                    <Toaster status="error" msg={errorMsg} />
                    }
            </div>
        </React.Fragment>
    );
};

NewHighImpact.propTypes = {}

export default NewHighImpact