import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SUPPORT_TICKET_ADMINS,ADD_CHAT_ADMIN , STATUS_CHAT_ADMIN} from "./actionTypes"

import {
  loadSupportTicketAdmins,
  noSupportTicketsAdmin,
  getSupportTicketAdminsSuccess,
  getSupportTicketAdminsFail,
  addChatAdminSuccess,
  addChatAdminFail,
  statusSupportTicketAdminSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getSupportTicketforAdmin,postChat,changeStatusSupportTicket } from "helpers/Backend_helper"

function* fetchSupportTicketAdmins({ payload: spt }) {
  try {
    yield put(loadSupportTicketAdmins(true))
    var getSupportTicketList
      if(spt){
        getSupportTicketList = yield call(getSupportTicketforAdmin,spt)
        getSupportTicketList.data.map((item, index) => {
          item.id = index + 1
      })
      if(getSupportTicketList.data.length === 0){
        yield put(noSupportTicketsAdmin(true))
      }
        yield put(getSupportTicketAdminsSuccess(getSupportTicketList.data))
      }
      else{
        getSupportTicketList = []
        yield put(getSupportTicketAdminsSuccess(getSupportTicketList))
      }

  } catch (error) {
    console.log(error)
    yield put(getSupportTicketAdminsFail(error))
  }
}

function* onAddSupportTicketAdmin({ payload: spt }) {
  try {
    const response = yield call(postChat, spt)
    if(response.success){
      yield put(addChatAdminSuccess(response))
    }else{
      yield put(addChatAdminFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(addChatAdminFail(error))
  }
}

function* onStatusSupportTicketAdmin({ payload: spt }) {
  try {
    const response = yield call(changeStatusSupportTicket, spt)
    yield put(statusSupportTicketAdminSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusSupportTicketAdminSuccess(error))
  }
}



function* supportTicketAdminsSaga() {
  yield takeEvery(GET_SUPPORT_TICKET_ADMINS, fetchSupportTicketAdmins)
  yield takeEvery(ADD_CHAT_ADMIN, onAddSupportTicketAdmin)
  yield takeEvery(STATUS_CHAT_ADMIN, onStatusSupportTicketAdmin)
}

export default supportTicketAdminsSaga;
