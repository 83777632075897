// import React, { Component } from 'react';
// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import PropTypes from 'prop-types'
// import MetaTags from 'react-meta-tags';
// import Header from "components/VerticalLayout/Header";
// import Sidebar from "components/VerticalLayout/Sidebar";
// import Breadcrumb from 'components/Common/Breadcrumb';
// import { Col, Container, Row, Input, Label, Form, Button, Table } from "reactstrap";


// const MyAdtxt = () => {
//     const [post, setPost] = useState(null);

//     const baseURL = "https://streamlyn.com/myads.txt";
  
//         useEffect(() => {
//             axios.get(baseURL).then((response) => {
//               setPost(response);
//             });
//           }, []);
    

//      function updatePost(){
//         axios.post(baseURL, post)
//           .then((response) => {
//            console.log(response)
//           })
//           .catch((error) => {
//            console.log(err)
            
//           })
//      }

// console.log()
//     return (
//         <React.Fragment>


//                 <div className="page-content">
//                     <MetaTags>
//                         <title>MyAdtxt | Bidsxchange</title>
//                     </MetaTags>
//                     <Container fluid={true}>
//                         <Breadcrumb title="Tools" breadcrumbItem="MyAdtxt" />
//                         <div className='card card-body'>
//                             <Input
//                                 type="textarea"
//                                 id="textarea"
//                                 rows="20"
//                                 name="myAdt" value={post} onChange={(e) => setPost(e.target.value)}

//                             />
//                             <div className='d-flex flex-row justify-content-center m-4'>
//                                 <button type="button" className="btn btn-primary m-2" onClick={updatePost}> Save</button>
//                                 <button type="button" className="btn btn-danger m-2">Reload</button>

//                             </div>
//                         </div>
//                     </Container>

//                 </div>
//         </React.Fragment>

//     );
// }
// MyAdtxt.propTypes = {};

// export default MyAdtxt;