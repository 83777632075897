/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap"
import axios from "axios";

var itemInfo = {}

const Breadcrumb = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  useEffect(() => {
    if (props.iOGroupID || props.iOID || props.lineItemID || props.creativeID || props.videoCreativeID) {
      var filterBody = {
          "advertiserID": 0,
          "iOGroupID": 0,
          "iOID": 0,
          "lineItemID": 0,
          "creativeID": 0,
          "videoCreativeID": 0,
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name: userInfo.name,
      }
      if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
        filterBody = {...filterBody,partnerName:userInfo.name,partnerID:userInfo.userID}
    }
      if (props.iOGroupID) {
        filterBody.iOGroupID = JSON.parse(props.iOGroupID)
      } else if (props.iOID) {
        filterBody.iOID = JSON.parse(props.iOID)
      }
      else if (props.lineItemID) {
        filterBody.lineItemID = JSON.parse(props.lineItemID)
      }
      else if (props.creativeID) {
        filterBody.creativeID = JSON.parse(props.creativeID)
      }
      else if (props.videoCreativeID) {
        filterBody.videoCreativeID = JSON.parse(props.videoCreativeID)
      } else {
        filterBody.advertiserID = 0
      }
      axios.post(userInfo.userRole === 'Partner' ? "/v2.0/partnerOp/demand/getParent" :userInfo.userRole === 'Agency' ? "/v2.0/partnerOp/demand/getParent" : "/v2.0/OP/demand/getParent", filterBody,
        {
          headers: headers
        })
        .then((res) => {
          console.log("cdsresult is ==>", res)
          if (res.data) {
            itemInfo = res.data
          }
        })
        .catch((err) => {
          console.log("e", err)
          // setInValid(true)
        });
    }
  }, [])

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box  align-items-center ">
          <h4 className="mb-2 font-size-18">{props.breadcrumbItem}</h4>
          <ol className="breadcrumb m-0 collapsed" >
            <div style={{ display: 'contents', fontSize: '12px' }}>
              <li>
                <Link to="/Dashboard" className="color-dark-blue signUp"> <i
                  className="mdi mdi-home "
                ></i> Home</Link>
              </li>
              <li style={{ display: 'none' }}></li>
            </div>
            {!props.breadList && !props.advertiserName && !props.buyerName && !props.adUnitName && !props.iOGroupID && !props.iOID && !props.lineItemID &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <span>{props.breadcrumbItem}</span>
                </li>
              </div>
            }

            {props.breadList &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <span>{props.breadList}</span>
                </li>
              </div>
            }
            {props.advertiserName &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/Advertiser">All Advertisers</Link>
                </li>
                <li>
                  <span> {props.advertiserName}</span>
                </li>
              </div>
            }
            {props.buyerName &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/HB_Buyer">All HB Buyers</Link>
                </li>
                <li>
                  <span>HB Buyer : {props.buyerName}</span>
                </li>
              </div>
            }
            {props.adUnitName &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/HB_AdUnits">All HB Adunits</Link>
                </li>
                <li>
                  <span>HB AdUnit : {props.adUnitName}</span>
                </li>
              </div>
            }
            {props.iOGroupID &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/Advertiser">All Advertisers</Link>
                </li>
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem >Advertiser  : &nbsp;&nbsp; <Link to={`/Insertion_Order_Group?advertiserId=${itemInfo.advertiserID}&advertiserName=${itemInfo.advertiserName}`} className="color-dark-blue signUp">
                        {itemInfo.advertiserName}
                      </Link></DropdownItem>
                      <DropdownItem >    <Link to="/Insertion_Order_Group">All IOG</Link></DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span> {itemInfo.iOGroupName}</span>
                </li>
              </div>
            }
            {props.iOID &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/Advertiser">All Advertisers</Link>
                </li>
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem >Advertiser  : &nbsp;&nbsp; <Link to={`/Insertion_Order_Group?advertiserId=${itemInfo.advertiserID}&advertiserName=${itemInfo.advertiserName}`} className="color-dark-blue signUp">
                        {itemInfo.advertiserName}
                      </Link></DropdownItem>
                      <DropdownItem >    <Link to="/Insertion_Order_Group">All IOG</Link></DropdownItem>
                      <DropdownItem >IOG  : &nbsp;&nbsp;      <Link to={`/Insertion_Order?iOGroupID=${itemInfo.iOGroupID}`} className="color-dark-blue signUp">
                        {itemInfo.iOGroupName}
                      </Link></DropdownItem>
                      <DropdownItem >           <Link to="/Insertion_Order">All IOS</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span> {itemInfo.iOName}</span>
                </li>
              </div>
            }
            {(props.lineItemID) &&
              <div style={{ display: 'contents', fontSize: '12px' }}>
                <li>
                  <Link to="/Advertiser">All Advertisers</Link>
                </li>
                <li style={{ display: "flex" }}>
                  <UncontrolledDropdown
                    className="dropdown  active" style={{ fontSize: '1.5rem', marginTop: '-5px' }}
                  >
                    <DropdownToggle
                      tag="span" className="dropdown-toggle"
                    >
                      <i className="bx bx-dots-horizontal-rounded" />
                    </DropdownToggle>
                    <DropdownMenu data-popper-placement="left-start">
                      <DropdownItem >Advertiser  : &nbsp;&nbsp;<Link to={`/Insertion_Order_Group?advertiserId=${itemInfo.advertiserID}&advertiserName=${itemInfo.advertiserName}`} className="color-dark-blue signUp">
                        {itemInfo.advertiserName}
                      </Link></DropdownItem>
                      <DropdownItem >    <Link to="/Insertion_Order_Group">All IOG</Link></DropdownItem>
                      <DropdownItem > IOG  : &nbsp;&nbsp;<Link to={`/Insertion_Order?iOGroupID=${itemInfo.iOGroupID}`} className="color-dark-blue signUp">
                        {itemInfo.iOGroupName}
                      </Link></DropdownItem>
                      <DropdownItem >           <Link to="/Insertion_Order">All IOS</Link></DropdownItem>
                      <DropdownItem >IOS  : &nbsp;&nbsp; <Link to={`/Line_Items?iOID=${itemInfo.iOID}`} className="color-dark-blue signUp">
                        {itemInfo.iOName}
                      </Link></DropdownItem>
                      <DropdownItem >           <Link to="/Line_Items">All Line Item</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li>
                  <span>{itemInfo.lineItemName}</span>
                </li>
                <li>
                  <Link to={`/Creative`} className="color-dark-blue signUp">
                    All Creatives
                  </Link>
                </li>
              </div>
            }
            {/* <li>
                <Link to="#">{props.title}</Link>
              </li>
              <li active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </li> */}

          </ol>
        </div>
        {/* <div className="page-title-box d-sm-flex  justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li>
                <Link to="#">{props.title}</Link>
              </li>
              <li active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </li>
            </ol>
          </div>
        </div> */}
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
