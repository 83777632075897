import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_IOS,ADD_IOS , UPDATE_IOS,READ_IOS,STATUS_IOS,ARCHIVE_IOS} from "./actionTypes"

import {
  loadActivitiesIOs,
  noDataIOs,
  getIOsSuccess,
  getIOsFail,
  addIOsSuccess,
  addIOsFail,
  updateIOsSuccess,
  updateIOsFail,
  readIOsSuccess,
  statusIOsSuccess,
  archiveIOsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getIOs,getIOsByIOG,getIOsType,createIOs,updateIOs,readIOs,statusChangeIOs,archiveIOs } from "../../../helpers/Backend_helper"

function* fetchIOs({ payload: adv }) {
  try {
    yield put(loadActivitiesIOs(true))
    var getIOsList
    if(adv && adv.type){
      if(adv && adv.iOGroupID){
        getIOsList = yield call(getIOsByIOG,adv)
        getIOsList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getIOsList.response.length === 0){
        yield put(noDataIOs(true))
      }
        yield put(getIOsSuccess(getIOsList.response))
      } if(adv && adv.iOGroupID === ''){
        delete adv.iOGroupID
        getIOsList = yield call(getIOsType,adv)
        getIOsList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getIOsList.response.length === 0){
        yield put(noDataIOs(true))
      }
        yield put(getIOsSuccess(getIOsList.response))
    }
    }else{
      if(adv && adv.iOGroupID){
        getIOsList = yield call(getIOsByIOG,adv)
        getIOsList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getIOsList.response.length === 0){
        yield put(noDataIOs(true))
      }
        yield put(getIOsSuccess(getIOsList.response))
      }else if(adv && adv.iOGroupID === ''){
        delete adv.iOGroupID
        getIOsList = yield call(getIOs,adv)
        getIOsList.response.map((item, index) => {
          item.id = index + 1
      })
      if(getIOsList.response.length === 0){
        yield put(noDataIOs(true))
      }
        yield put(getIOsSuccess(getIOsList.response))
      }else {
        getIOsList = []
        yield put(getIOsSuccess(getIOsList))
      }
    }
  } catch (error) {
    yield put(getIOsFail(error))
  }
}

function* onReadIOs({ payload: adv }) {
  try {
    const response = yield call(readIOs, adv)
    yield put(readIOsSuccess(response))
  } catch (error) {
    yield put(readIOsSuccess(error))
  }
}

function* onAddIOs({ payload: adv }) {
  try {
    const response = yield call(createIOs, adv)
    if(response.success){
      yield put(addIOsSuccess(response))
    }else{
      yield put(addIOsFail(response))
    }
  } catch (error) {
    yield put(addIOsFail(error))
  }
}

function* onUpdateIOs({ payload: adv }) {
  try {
    const response = yield call(updateIOs, adv)
    if(response.success){
      yield put(updateIOsSuccess(response))
    }else{
      yield put(updateIOsFail(response))
    }
  } catch (error) {
    yield put(updateIOsFail(error))
  }
}

function* onStatusIOs({ payload: adv }) {
  try {
    const response = yield call(statusChangeIOs, adv)
    yield put(statusIOsSuccess(response))
  } catch (error) {
    yield put(statusIOsSuccess(error))
  }
}

function* onArchiveIOs({ payload: adv }) {
  try {
    const response = yield call(archiveIOs, adv)
    yield put(archiveIOsSuccess(response))
  } catch (error) {
    yield put(archiveIOsSuccess(error))
  }
}

function* iOsSaga() {
  yield takeEvery(GET_IOS, fetchIOs)
  yield takeEvery(ADD_IOS, onAddIOs)
  yield takeEvery(UPDATE_IOS, onUpdateIOs)
  yield takeEvery(READ_IOS, onReadIOs)
  yield takeEvery(STATUS_IOS, onStatusIOs)
  yield takeEvery(ARCHIVE_IOS, onArchiveIOs)
}

export default iOsSaga;
