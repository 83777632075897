import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_IOGROUPS, ADD_IOGROUP, UPDATE_IOGROUP, READ_IOGROUP, STATUS_IOGROUP, ARCHIVE_IOGROUP } from "./actionTypes"

import {
  loadActivitiesIOGroups,
  noDataIOGroups,
  getIOGroupsSuccess,
  getIOGroupsFail,
  addIOGroupSuccess,
  addIOGroupFail,
  updateIOGroupSuccess,
  updateIOGroupFail,
  readIOGroupSuccess,
  statusIOGroupSuccess,
  archiveIOGroupSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getIOGroup, getIOGroupAdvertiser, getIOGroupType, createIOGroup, updateIOGroup, readIOGroup, statusChangeIOGroup, archiveIOGroup } from "../../../helpers/Backend_helper"

function* fetchIOGroups({ payload: adv }) {
  try {
    console.log(adv)
    yield put(loadActivitiesIOGroups(true))
    var getIOGList
    if (adv && adv.type) {
      if (adv && adv.advertiserID) {
        getIOGList = yield call(getIOGroupAdvertiser, adv)
        getIOGList.response.map((item, index) => {
          item.id = index + 1
        })
        if (getIOGList.response.length === 0) {
          yield put(noDataIOGroups(true))
        }
        yield put(getIOGroupsSuccess(getIOGList.response))
      }
      if (adv && adv.advertiserID === '') {
        delete adv.advertiserID
        getIOGList = yield call(getIOGroupType, adv)
        getIOGList.response.map((item, index) => {
          item.id = index + 1
        })
        if (getIOGList.response.length === 0) {
          yield put(noDataIOGroups(true))
        }
        yield put(getIOGroupsSuccess(getIOGList.response))
      }
    } else {
      if (adv && adv.advertiserID) {
        getIOGList = yield call(getIOGroupAdvertiser, adv)
        getIOGList.response.map((item, index) => {
          item.id = index + 1
        })
        if (getIOGList.response.length === 0) {
          yield put(noDataIOGroups(true))
        }
        yield put(getIOGroupsSuccess(getIOGList.response))
      } else if (adv && adv.advertiserID === '') {
        delete adv.advertiserID
        getIOGList = yield call(getIOGroup, adv)
        getIOGList.response.map((item, index) => {
          item.id = index + 1
        })
        if (getIOGList.response.length === 0) {
          yield put(noDataIOGroups(true))
        }
        yield put(getIOGroupsSuccess(getIOGList.response))
      } else {
        getIOGList = []
        yield put(getIOGroupsSuccess(getIOGList))
      }
    }

  } catch (error) {
    yield put(getIOGroupsFail(error))
  }
}

function* onReadIOGroup({ payload: adv }) {
  try {
    const response = yield call(readIOGroup, adv)
    yield put(readIOGroupSuccess(response))
  } catch (error) {
    yield put(readIOGroupSuccess(error))
  }
}

function* onAddIOGroup({ payload: adv }) {
  try {
    const response = yield call(createIOGroup, adv)
    if (response.success) {
      yield put(addIOGroupSuccess(response))
    } else {
      yield put(addIOGroupFail(response))
    }
  } catch (error) {
    yield put(addIOGroupFail(error))
  }
}

function* onUpdateIOGroup({ payload: adv }) {
  try {
    const response = yield call(updateIOGroup, adv)
    if (response.success) {
      yield put(updateIOGroupSuccess(response))
    } else {
      yield put(updateIOGroupFail(response))
    }
  } catch (error) {
    yield put(updateIOGroupFail(error))
  }
}

function* onStatusIOGroup({ payload: adv }) {
  try {
    const response = yield call(statusChangeIOGroup, adv)
    yield put(statusIOGroupSuccess(response))
  } catch (error) {
    yield put(statusIOGroupSuccess(error))
  }
}

function* onArchiveIOGroup({ payload: adv }) {
  try {
    const response = yield call(archiveIOGroup, adv)
    yield put(archiveIOGroupSuccess(response))
  } catch (error) {
    yield put(archiveIOGroupSuccess(error))
  }
}

function* iOGroupSaga() {
  yield takeEvery(GET_IOGROUPS, fetchIOGroups)
  yield takeEvery(ADD_IOGROUP, onAddIOGroup)
  yield takeEvery(UPDATE_IOGROUP, onUpdateIOGroup)
  yield takeEvery(READ_IOGROUP, onReadIOGroup)
  yield takeEvery(STATUS_IOGROUP, onStatusIOGroup)
  yield takeEvery(ARCHIVE_IOGROUP, onArchiveIOGroup)
}

export default iOGroupSaga;
