/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,Spinner } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewPartner as onADDPartner,
  updatePartner as onUpdatePartner,
  addPartnerSuccess,
  addPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
} from "store/PartnerManagement/actions";
import PasswordStrengthIndicator from "pages/Authentication/PasswordStrengthIndicator";
import Toaster from "pages/Toaster/Toaster";

// var fORM10F = ''
// var NPE = ''
// var TRC = ''

var country = ''
var countriesList = {}

const Create_PM = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  // const [partner, setPartner] = useState({
  //   password: ''
  // });
  const [isEdit, setIsEdit] = useState(false);
  const [regionList, setRegionList] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [form10F, setForm10F] = useState('')
  const [NPE, setNPE] = useState('')
  const [TRC, setTRC] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passwordValidity, setPasswordValidity] = useState({
    passwordLength: false,
    containNumbers: false,
    isUpperCase: false,
    isLowerCase: false,
    requireSymbols: false
  });
  const [loader, setLoader] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const body = {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
  }

  toastr.options = {
    positionClass: "toast-top-right",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 1000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    axios.post("/v2.0/OP/regions/list", body,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is ==>", res)
        if (res.data) {
          countriesList = res.data
          setRegionList([{
            label: 'Africa',
            options: res.data.Africa
          }, {
            label: 'America',
            options: res.data.Americas
          }, {
            label: 'Asia',
            options: res.data.Asia
          }, {
            label: 'Europe',
            options: res.data.Europe
          }, {
            label: 'GDPR',
            options: res.data.GDPR
          }, {
            label: 'Oceania',
            options: res.data.Oceania
          }])
          // countriesList = [...res.data.Africa, ...res.data.Americas, ...res.data.Asia, ...res.data.Europe, ...res.data.GDPR, ...res.data.Oceania]
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
    if (props.selectedPartner !== '') {
      setIsEdit(true)
      setPartner({ ...props.selectedPartner, password: '', userRole: 'Partner' })
      if (props.selectedPartner.partnerFORM10F) {
        console.log(props.selectedPartner.partnerFORM10F.name)
        let partnerFORM10F = JSON.parse(props.selectedPartner.partnerFORM10F)
        setForm10F(partnerFORM10F.name)
      }
      if (props.selectedPartner.partnerNoPermanentEstablishment.name) {
        let partnerNoPermanentEstablishment = JSON.parse(props.selectedPartner.partnerNoPermanentEstablishment)
        setNPE(partnerNoPermanentEstablishment.name)
      }
      if (props.selectedPartner.partnerTaxResidencyCertificate.name) {
        let partnerTaxResidencyCertificate = JSON.parse(props.selectedPartner.partnerTaxResidencyCertificate)
        setTRC(partnerTaxResidencyCertificate.name)
      }
    }
  }, []);

  function handleSelectRegion(selectCountry) {
    console.log(selectCountry)
    setSelectedCountry(selectCountry);
    setPartner(partner => ({ ...partner, partnerCountry: selectCountry.label }));
  }

  const [partner, setPartner] = useState({
    userName:'',
    password: '',
    userRole:'Partner',
    userEmail:'',
    userlog:{
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    },
    companyID: userInfo.companyID,
    partnerLegalBusinessName: '',
    partnerRegion: '',
    partnerType: '',
    customerSuccessManager:'',
    partnerInvertoryType:'display',
    adOpsManager:'',
    partnerManager:'',
    partnerContactDesignation: '',
    partnerCountry: '',
    partnerPaymentTerms: '',
    partnerBeneficiaryName: '',
    partnerFinanceContactName:'',
    partnerFinanceContactPhone: '',
    partnerFinanceContactEmail: '',
    partnerPaymentMode: '',
    partnerBeneficiaryAccountNo: '',
    partnerBankSwiftCode: '',
    partnerBankName: '',
    partnerBankAddress: '',
    partnerOtherInfo: '',
    partnerPANTAXID: '',
    partnerCIN: '',
    partnerGSTNo: '',
    partnerFORM10F: {name:''},
    partnerNoPermanentEstablishment: {name:''},
    partnerTaxResidencyCertificate: {name:''},

}); 
  

  // const handleChange = (e) => {
  //   setSelectedRegion(e.target.value)
  //   partner.partnerRegion = e.target.value
  //   setRegionList(countriesList[e.target.value])
  // }

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.partners.errorMsg,
    successMsg: state.partners.successMsg,
  }));

  console.log(errorMsg, successMsg)



  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addPartnerSuccess(""));
        dispatch(addPartnerFail(null));
        dispatch(updatePartnerSuccess(""));
        dispatch(updatePartnerFail(null));
        setLoader(false)
        if (props.selectedPartner !== '') {
          props.closeCanvas('Update')
        } else {
          props.closeCanvas('Create')
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {

    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
    }
  }, [errorMsg]);

  const form10FFileChange = async (file) => {
    console.log(file.target.files[0].size)
    const fileName = file.target.files[0].name
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    setForm10F(fileName)
    if (file.target.files[0].size < 7e6) {

      setPartner({
        ...partner,
        partnerFORM10F: {
          // ...file, 
          name: fileName,
          base64: tempBase64
        }
      })
    } else {
      setForm10F('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }

  };

  const estFileChange = async (file) => {
    console.log(file.target.files[0])
    const fileName = file.target.files[0].name
    setNPE(fileName)
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    if (file.target.files[0].size < 7e6) {
      setPartner({
        ...partner,
        partnerNoPermanentEstablishment: {
          name: fileName,
          base64: tempBase64
        }
      })

    } else {
      setNPE('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }
  };

  const buttonStatus = (props.selectedPartner !== '') ? true : (passwordValidity.passwordLength && passwordValidity.containNumbers && passwordValidity.isUpperCase && passwordValidity.isLowerCase && passwordValidity.requireSymbols ? false : true);

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    let targetValue = e.target.value;
    console.log(targetValue)
    setPartner(partners => ({ ...partners, password: value }));
    if (e.target.name === 'password') {
      console.log(e.target.name)
      var matches = targetValue.match(/\d+/g);
      var upperCaseMatch = targetValue.match(/[A-Z]/);
      var lowerCaseMatch = targetValue.match(/[a-z]/);
      var symbolMatch = new RegExp(/[^A-Z a-z 0-9]/);
      console.log(targetValue.length, matches, upperCaseMatch, lowerCaseMatch)
      setPasswordValidity({
        passwordLength: targetValue.length > 7 ? true : false,
        containNumbers: matches != null ? true : false,
        isUpperCase: upperCaseMatch != null ? true : false,
        isLowerCase: lowerCaseMatch != null ? true : false,
        requireSymbols: symbolMatch.test(targetValue) ? true : false
      })
    }
  }

  const taxFileChange = async (file) => {
    console.log(file.target.files[0])
    const fileName = file.target.files[0].name
    setTRC(fileName)
    const tempBase64 = await encodeFileBase64(file.target.files[0])
    if (file.target.files[0].size < 7e6) {
      setPartner({
        ...partner,
        partnerTaxResidencyCertificate: {
          name: fileName,
          base64: tempBase64
        }
      })
    } else {
      setTRC('')
      toastr.warning(' Please upload a file smaller than 7 MB')
      return;
    }
  };

  const encodeFileBase64 = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          const base64 = reader.result.replace("data:application/pdf;base64,", "");
          if (base64) {
            resolve(base64);
          }
          resolve('');
        }
        reader.onerror = (error) => {
          console.log("error: ", error)
          resolve('');
        };
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newKey = name;
    if(newKey === 'partnerRegion'){
          setSelectedRegion(e.target.value)
    setRegionList(countriesList[e.target.value])
    }
   
    setPartner(partner => ({ ...partner, [newKey]: value }));
}

const handleSubmit = async (e) => {
  e.preventDefault();
  setSubmitted(true);
  console.log(partner)
  if(partner.partnerCountry !== 'India' && !(!!partner.partnerFORM10F.name||!!partner.partnerNoPermanentEstablishment.name||!!partner.partnerTaxResidencyCertificate.name)){
      return
  }
  if (partner.userName && partner.userEmail && partner.partnerLegalBusinessName && partner.partnerPaymentTerms && partner.partnerRegion && partner.partnerType && partner.partnerPaymentMode ) {
      console.log("this If condition==============");
      setLoader(true)
      if (props.selectedPartner !== '') {
        dispatch(onUpdatePartner({ ...partner,    userlog:{
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
        } }));
      } else {
        dispatch(onADDPartner({ ...partner }));
      }
  }
}

  return (
    <React.Fragment>
            {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
            <div className="col-12" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
            <Form onSubmit={handleSubmit}  >
              <Row style={{ padding: '3%', height: '85vh', overflow: 'auto',width:'100%' }}>
                <Col  xl={8} className="mb-4">
                  <h6 className="text-muted">Access</h6>
                  <hr></hr>
                  <div className="mb-3">
                    <Label className="form-label">Parnter Username <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="userName"
                      onChange={handleChange}
                      value={partner.userName }
                      className={'form-control' + (submitted && !partner.userName? ' is-invalid' : '')}
                    />
                    {submitted && !partner.userName && (
                      <FormFeedback type="invalid">{'Partner Name is required'}</FormFeedback>
                    )}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Email <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="userEmail"
                      type="email"
                      onChange={handleChange}
                      value={partner.userEmail }
                      className={'form-control' + (submitted && !partner.userEmail? ' is-invalid' : '')}
                    />
                    {submitted && !partner.userEmail && (
                      <FormFeedback type="invalid">{'Partner Email is required'}</FormFeedback>
                    )}
                  </div>
                  {!isEdit && 
                      <div className="mb-3">
                      <Label className="form-label">Partner Password <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                      <Input
                        name="password"
                        type="password"
                        // onChange={handleChange}
                        value={partner.password }
                        onFocus={() => setPasswordFocused(true)}
                        onChange={handleChangePassword}
                        className={'form-control' + (submitted && !partner.password ? ' is-invalid' : '')}
                      />
                      {passwordFocused && buttonStatus && <PasswordStrengthIndicator validity={passwordValidity} />}
                    </div>
                  }
                  <h6 className="text-muted">Partner Details</h6>
                  <hr></hr>
                  <div className="mb-3">
                    <Label className="form-label">Partner Legal Business Name <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="partnerLegalBusinessName"
                      onChange={handleChange}
                      
                      value={partner.partnerLegalBusinessName }
                      className={'form-control' + (submitted && !partner.partnerLegalBusinessName ? ' is-invalid' : '')}
                    />
          {submitted && !partner.partnerLegalBusinessName &&
                                          <div className="invalid-feedback">Partner Business Name is required</div>
                                      }
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Beneficiary Name </Label>
                    <Input
                      name="partnerBeneficiaryName"
                      onChange={handleChange}
                      
                      value={partner.partnerBeneficiaryName }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Payment Terms <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="partnerPaymentTerms"
                      type="select"
                      className="form-select"
                      onChange={handleChange}
                      
                      value={partner.partnerPaymentTerms }
                    >
                      <option value=''>Select</option>
                      <option>NET+30</option>
                      <option>NET+60</option>
                      <option>NET+90</option>
                    </Input>
                    {submitted && !partner.partnerPaymentTerms &&
                                          <div className="invalid-feedback">Partner Payment Terms is required</div>
                                      }
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Contact Designation</Label>
                    <Input
                      name="partnerContactDesignation"
                      onChange={handleChange}
                      
                      value={partner.partnerContactDesignation }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Region <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="partnerRegion"
                      type="select"
                      className="form-select"
                      onChange={handleChange}
                      
                      value={partner.partnerRegion }
                    >
                      <option value=''>Select</option>
                      <option value='Africa'>Africa</option>
                      <option value='Americas'>America</option>
                      <option value='Asia'>Asia</option>
                      <option value='Europe'>Europe</option>
                      <option value='GDPR'>GDPR</option>
                      <option value='Oceania'>Oceania</option>
                    </Input>
                    {submitted && !partner.partnerRegion &&
                                          <div className="invalid-feedback">Partner Region is required</div>
                                      }
                  </div>
                  <div className="mb-3">
                    <Label>Partner Country</Label>
                    <Select
                      value={selectedCountry}
                      onChange={s => {
                        handleSelectRegion(s)
                      }}
                      options={regionList}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Type <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="partnerType"
                      type="select"
                      onChange={handleChange}
                      value={partner.partnerType }
                      className={'form-control form-select' + (submitted && !partner.partnerType ? ' is-invalid' : '')}
                    >
                      <option value=''>Select</option>
                      <option value='network'>Network</option>
                      <option value='publisher'>Publisher</option>
                      <option value='platformPartner'>Platform Partner</option>
                    </Input>
                    {submitted && !partner.partnerType &&
                                          <div className="invalid-feedback">Partner Type is required</div>
                                      }
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Manager </Label>
                    <Input
                      name="partnerManager"
                      onChange={handleChange}
                      value={partner.partnerManager }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Customer Success Manager </Label>
                    <Input
                      name="customerSuccessManager"
                      onChange={handleChange}
                      value={partner.customerSuccessManager }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Ad Ops Manager </Label>
                    <Input
                      name="adOpsManager"
                      onChange={handleChange}
                      value={partner.adOpsManager }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Finance Contact Name</Label>
                    <Input
                      name="partnerFinanceContactName"
                      type="text"
                      onChange={handleChange}
                      value={partner.partnerFinanceContactName }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Finance Contact Email</Label>
                    <Input
                      name="partnerFinanceContactEmail"
                      type="email"
                      onChange={handleChange}
                      value={partner.partnerFinanceContactEmail }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Finance Contact Number </Label>
                    <Input
                      name="partnerFinanceContactPhone"
                      type="number"
                      value={partner.partnerFinanceContactPhone }
                      onChange={handleChange}
                      onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }}
                    />
                  </div>
              
                  <div className="mb-3">
                    <Label className="form-label">Partner Payment Mode <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                    <Input
                      name="partnerPaymentMode"
                      type="select"
                      onChange={handleChange}
                      value={partner.partnerPaymentMode }
                      className={'form-control form-select' + (submitted && !partner.partnerPaymentMode ? ' is-invalid' : '')}
                    >
                      <option value=''>Select</option>
                      <option value='Wire'>Wire</option>
                      <option value='Paypal'>Paypal</option>
                    </Input>
                    {submitted && !partner.partnerPaymentMode &&
                                          <div className="invalid-feedback">Partner Payment Mode is required</div>
                                      }
                  </div>
                  {/* <div className="mb-3">
                    <Label className="form-label">Partner Beneficiary Account No </Label>
                    <Input
                      name="partnerBeneficiaryAccountNo"
                      type="number"
                      onChange={handleChange}
                      
                      value={partner.partnerBeneficiaryAccountNo }
                      invalid={
                        validation.touched.partnerBeneficiaryAccountNo && validation.errors.partnerBeneficiaryAccountNo ? true : false
                      }
                    />
                    {validation.touched.partnerBeneficiaryAccountNo && validation.errors.partnerBeneficiaryAccountNo ? (
                      <FormFeedback type="invalid">{validation.errors.partnerBeneficiaryAccountNo}</FormFeedback>
                    ) : null}
                  </div> */}
                  <div className="mb-3">
                    <Label className="form-label">Partner Bank Swift Code </Label>
                    <Input
                      name="partnerBankSwiftCode"
                      type="text"
                      onChange={handleChange}
                      value={partner.partnerBankSwiftCode }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Bank Name </Label>
                    <Input
                        type="text"
                      name="partnerBankName"
                      onChange={handleChange}
                      value={partner.partnerBankName }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Bank Address </Label>
                    <Input
                        type="text"
                      name="partnerBankAddress"
                      onChange={handleChange}
                      value={partner.partnerBankAddress }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Other Information</Label>
                    <Input
                        type="text"
                      name="partnerOtherInfo"
                      onChange={handleChange}
                      value={partner.partnerOtherInfo }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner PAN/TAX ID</Label>
                    <Input
                    type="text"
                      name="partnerPANTAXID"
                      onChange={handleChange}
                      value={partner.partnerPANTAXID }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner GSTNo</Label>
                    <Input
                      name="partnerGSTNo"
                      type="text"
                      onChange={handleChange}
                      value={partner.partnerGSTNo }
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Partner Company Identification Number(CIN)</Label>
                    <Input
                      name="partnerCIN"
                      type="number"
                      onChange={handleChange}
                      value={partner.partnerCIN }
                    />
                  </div>
                  {selectedCountry.label !== 'India' &&
                    <div className="mb-3">
                        <div className='mb-3'>
                                      <h6 >For Non Indian Companies</h6>
                                  </div>
                      <div className="mb-3">
                        <Label className="form-label"> Browse Form 10F <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={form10F}  className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !form10F? 'form-control is-invalid' : '')} readOnly  />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={form10FFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !form10F &&
                                          <div className="invalid-feedback">Partner FORM10F is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Browse No Permenant Establishment <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={NPE} className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !NPE? 'form-control is-invalid' : '')} readOnly />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={estFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !NPE &&
                                          <div className="invalid-feedback">Partner NoPermanentEstablishment is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Browse Tax Residency Certificate <span style={{color:'red',fontSize:'15px'}}>*</span></Label>
                        <div>
                          <Input id="uploadFile" value={TRC} className={'f-input ' + (submitted && partner.partnerCountry!== 'India' && !TRC? 'form-control is-invalid' : '')} readOnly  />
                          <div className="fileUpload  btn--browse">
                            <span>Browse</span>
                            <Input type="file" accept="application/pdf" className="upload" onChange={taxFileChange}
                            />
                          </div>
                          {submitted  && partner.partnerCountry!== 'India' && !TRC &&
                                          <div className="invalid-feedback">Partner TaxResidencyCertificate is required</div>
                                      }
                          <span className="notes">Note: Allowed file types: pdf. Maximum allowed file size: 7 MB.</span>
                        </div>
                      </div>
                    </div>
                  }

                </Col>
              </Row>
                  <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '1rem 1rem', margin: 0, display: "flex", backgroundColor: "white" }}>
                    <Col>
                      <div className="text-start">


                        {isEdit &&
                          <button
                            type="submit"
                            className="btn btn-primary save-user"
                          >
                            Update
                          </button>
                        }
                        {!isEdit &&
                          <button
                            type="submit"
                            className="btn btn-primary save-user"
                            disabled={buttonStatus}
                          >
                            Save
                          </button>
                        }

                        &nbsp;&nbsp;
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          // eslint-disable-next-line react/prop-types
                          onClick={props.closeCanvas}
                        >
                          Back
                        </button>

                      </div>
                    </Col>
                  </Row>
                  </Form>
              {errorMsg !== null && errorMsg !== '' &&
                    <Toaster status="error" msg={errorMsg}/>
                }
            </div>

    </React.Fragment>
  );
};

export default withRouter(Create_PM);

Create_PM.propTypes = {
  history: PropTypes.object,
};