/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import axios from "axios";
import Select from "react-select";
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form, FormFeedback, Modal, ModalBody
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

import {
    getPublisherRevenueDisplayReports as onGetRevenueDisplayReports,
} from "store/Proxy/ProxyAdUnits/actions";

import {
    getScheduleReports as onGetScheduleReports,
    addNewScheduleReport as onADDScheduleReport,
    updateScheduleReport as onUpdateScheduleReport,
    readScheduleReport as onReadScheduleReport,
    addScheduleReportSuccess,
    addScheduleReportFail,
    updateScheduleReportSuccess,
    updateScheduleReportFail,
} from "store/PublisherConsole/ScheduleReport/actions";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')
var loader = false

const NewPublisherSchedule_Report = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [totalCodeServed, setTotalCodeServed] = useState(0)
    const [totalImpressions, setTotalImpressions] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [AvgRPM, setAvgRPM] = useState(0)
    const [AvgFillRate, setAvgFillRate] = useState(0)

    const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    // const [loader, setLoader] = useState(false)
    const [selectAllMTC, setSelectAllMTC] = useState(false)

    const [Domain, setDomian] = useState([]);
    const [AdUnitName, setAdUnitName] = useState([]);
    const [AdUnitSize, setAdUnitSize] = useState([]);
    const [InventoryType, setInventoryType] = useState([]);
    const [DealType, setDealType] = useState([]);
    const [Device, setDevice] = useState([]);

    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedAdUnitName, setSelectedAdUnitName] = useState(null);
    const [selectedAdUnitSize, setSelectedAdUnitSize] = useState(null);
    const [selectedInventoryType, setSelectedInventoryType] = useState(null);
    const [selectedDealType, setSelectedDealType] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState(null);


    const [selectAllDM, setSelectAllDM] = useState({
        Domain: false,
        AdUnitName: false,
        AdUnitSize: false,
        InventoryType: false,
        DealType: false,
        Device: false,
    });

    const [filters, setFilters] = useState({
        Domain: '',
        AdUnitName: '',
        AdUnitSize: '',
        InventoryType: '',
        DealType: '',
        Device: '',
    });

    const [filterData, setFilterData] = useState({
        Domain: [],
        AdUnitName: [],
        AdUnitSize: [],
        InventoryType: [],
        DealType: [],
        Device: [],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        TotalCodeServed: false,
        PaidImpressions: false,
        Revenue: false,
        RPM: false,
        FillRate: false,
    });

    const [finalData, setFinalData] = useState({
        dateRange: 'yesterday',
        range: 'Cumulative',
        frequency: 'daily',
        scheduleName: '',
        emails: [],

    });

    const [emailData, setEmailData] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [scheduleReport, setScheduleReport] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const { loading } = useSelector(state => ({
        loading: state.proxyAdUnit.loading,
    }));

    console.log(loading)

    const handleSelectDomian = domian => {
        setSelectedDomain(domian)
        setFilters(filters => ({ ...filters, Domain: domian.value }));
        changeData('Domain', domian.value)
    }

    const handleSelectAdUnitName = adUnitName => {
        setSelectedAdUnitName(adUnitName)
        setFilters(filters => ({ ...filters, AdUnitName: adUnitName.value }));
        changeData('AdUnitName', adUnitName.value)
    }

    const handleSelectAdUnitSize = adUnitSize => {
        setSelectedAdUnitSize(adUnitSize)
        setFilters(filters => ({ ...filters, AdUnitSize: adUnitSize.value }));
        changeData('AdUnitSize', adUnitSize.value)
    }

    const handleSelectInventoryType = inventoryType => {
        setSelectedInventoryType(inventoryType)
        setFilters(filters => ({ ...filters, InventoryType: inventoryType.value }));
        changeData('InventoryType', inventoryType.value)
    }

    const handleSelectDealType = dealType => {
        setSelectedDealType(dealType)
        setFilters(filters => ({ ...filters, DealType: dealType.value }));
        changeData('DealType', dealType.value)
    }

    const handleSelectDevice = device => {
        setSelectedDevice(device)
        setFilters(filters => ({ ...filters, Device: device.value }));
        changeData('Device', device.value)
    }

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        if (name === 'Domain') {
            setFilters(filters => ({
                ...filters, AdUnitName: '',
                AdUnitSize: '',
                InventoryType: '',
                DealType: '',
                Device: '',
            }));
        } else if (name === 'AdUnitName') {
            setFilters(filters => ({
                ...filters,
                AdUnitSize: '',
                InventoryType: '',
                DealType: '',
                Device: '',
            }));
        } else if (name === 'Device') {
            setFilters(filters => ({
                ...filters,
                AdUnitSize: '',
                DealType: '',
                InventoryType: '',
            }));
        }
        else if (name === 'AdUnitSize') {
            setFilters(filters => ({
                ...filters,
                InventoryType: '',
                DealType: '',
            }));
        } else if (name === 'DealType') {
            setFilters(filters => ({
                ...filters,
                InventoryType: '',
            }));
        }
        changeData(e)
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            TotalCodeServed: e.target.checked,
            PaidImpressions: e.target.checked,
            Revenue: e.target.checked,
            RPM: e.target.checked,
            FillRate: e.target.checked,
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            Partner: userInfo.name
        
    }

    useEffect(() => {
        fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
        toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')


        axios.post("/v2.0/partnerRe/Query/Revenue/DisplayGetFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });

    }, []);

    const changeData = (name, value) => {
        resetSelectedData(name)
        body = {
            ...body,
            "Domain": name === 'Domain' ? value : '',
            AdUnitName: name === 'AdUnitName' ? value : '',
            AdUnitSize: name === 'AdUnitSize' ? value : '',
            InventoryType: name === 'InventoryType' ? value : '',
            DealType: name === 'DealType' ? value : '',
            Device: name === 'Device' ? value : '',
        }
        axios.post("/v2.0/partnerRe/Query/Revenue/DisplayGetSpecificFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const resetSelectedData = (name) => {
        if (name === 'Domain') {
            setSelectedAdUnitName(null)
            setSelectedAdUnitSize(null)
            setSelectedInventoryType(null)
            setSelectedDealType(null)
            setSelectedDevice(null)
        }
        else if (name === 'AdUnitName') {
            setSelectedAdUnitSize(null)
            setSelectedInventoryType(null)
            setSelectedDealType(null)
            setSelectedDevice(null)
        } 
        else if (name === 'AdUnitSize') {
            setSelectedInventoryType(null)
            setSelectedDealType(null)
            setSelectedDevice(null)
        }
        else if (name === 'InventoryType') {
            setSelectedDealType(null)
            setSelectedDevice(null)
        } 
        else if (name === 'DealType') {
            setSelectedDevice(null)
        }
        else {
        }
    }

    const objectModalData = (response) => {
        if (response.Domain) {
            setDomian(objectModal(response.Domain))
        }
        if (response.AdUnitName) {
            setAdUnitName(objectModal(response.AdUnitName))
        }
        if (response.AdUnitSize) {
            setAdUnitSize(objectModal(response.AdUnitSize))
        }
        if (response.InventoryType) {
            setInventoryType(objectModal(response.InventoryType))
        }
        if (response.DealType) {
            setDealType(objectModal(response.DealType))
        }
        if (response.Device) {
            setDevice(objectModal(response.Device))
        }
    }

    const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            Domain: e.target.checked,
            AdUnitName: e.target.checked,
            AdUnitSize: e.target.checked,
            InventoryType: e.target.checked,
            DealType: e.target.checked,
            Device: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        setSelectAll(false)
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.proxyAdUnit.errorMsg,
        successMsg: state.proxyAdUnit.successMsg,
    }));

    console.log(errorMsg, successMsg)

    const { revenueDisplayReports } = useSelector(state => ({
        revenueDisplayReports: state.proxyAdUnit.publisherRevenueDisplayRpt,
    }));

    console.log(revenueDisplayReports)

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const revenueDisplayReportsColumns = [
        {
            text: "No",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            footer: ''
        },
    ];

    const createColoumn = () => {

    }

    const { errorMsgSchedule, successMsgSchedule } = useSelector(state => ({
        errorMsgSchedule: state.scheduleReports.errorMsg,
        successMsgSchedule: state.scheduleReports.successMsg,
    }));

    useEffect(() => {
        if (successMsgSchedule !== null) {
            if (successMsgSchedule.success) {
                dispatch(addScheduleReportSuccess(""));
                dispatch(addScheduleReportFail(""));
                dispatch(updateScheduleReportSuccess(""));
                dispatch(updateScheduleReportFail(""));
                toastr.success('ScheduleReport Scheduled Successfully!...')
                dispatch(onGetScheduleReports({ filter: 'Active' }));
            }
        }
    }, [successMsgSchedule]);

    useEffect(() => {
        console.log(errorMsgSchedule)
        if (errorMsgSchedule !== null && errorMsgSchedule !== '') {
        }
    }, [errorMsgSchedule]);

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        totalSize: revenueDisplayReports.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
    };

    const defaultSorted = [
        {
            dataField: "requests", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const queryReportforData = (e) => {
        if (scheduleReport) {
            handleSubmit()
            scheduleReportData()
        } else {
            let DimArr = Object.values(selectAllDM)
            let MtcArr = Object.values(selectedMTC)
            if (!MtcArr.includes(true)) {
                toastr.warning('Please select atleast one Metrices')
            }
            else {
                handleSubmit()
            }

        }
    }

    const confirmScheduleReport = (e) => {
        setScheduleReport(true);
        setShowAlert(false)
    }

    const scheduleReportData = async (e) => {
        setSubmitted(true);
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Metrices')
        }
        else {
            if (finalData.scheduleName && finalData.emails.length > 0) {
                let query = {
                    "settings": {
                        "dimensions": selectAllDM,
                        "filters": filters,
                        "metrices": selectedMTC,
                        "range": range,
                    },
                    "scheduleName": finalData.scheduleName,
                    "partnerName": userInfo.name,
                    "dateRange": finalData.dateRange,
                    "emails": finalData.emails,
                    "frequency": finalData.frequency,
                }

                console.log(query)
                dispatch(onADDScheduleReport({ ...query }));
            }
        }
    }

    const handleSubmit = async (e) => {
        setShowAlert(false)
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!MtcArr.includes(true)) {
            toastr.warning('Please select atleast one  Metrices')
        }
        else {
            // setLoader(true)
            loader = true
            let query = {
                "dimensions": selectAllDM,
                "startDate": moment(fromDate).format("YYYY-MM-DD"),
                "endDate": moment(toDate).format("YYYY-MM-DD"),
                "filters": filters,
                "metrices": selectedMTC,
                "range": range,
                Partner: userInfo.name
            }
            if (adminDetails) {
                query = {
                    ...query, userlog: {
                        userID: adminDetails.userID,
                        userRole: adminDetails.userRole,
                        companyID: adminDetails.companyID,
                        name: adminDetails.name
                    }
                }
            }
            console.log(query)
            dispatch(onGetRevenueDisplayReports({ ...query }));
            setReportOpen(true)
        }
    }

    if (revenueDisplayReports.length > 0) {
        let keys = [];
        for (var k in revenueDisplayReports[0]) keys.push(k);
        if (keys.includes("hour")) {
            revenueDisplayReports.map((item, index) => {
                item.hour = parseInt(item.hour)
            })
        }
        console.log(keys)
        if (keys.includes("Date")) {
            revenueDisplayReportsColumns.push({
                dataField: "Date",
                text: "Date",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("Month")) {
            revenueDisplayReportsColumns.push({
                dataField: "Month",
                text: "Month",
                sort: true,
                footer: 'Total'
            })
        }
        if (keys.includes("hour")) {
            revenueDisplayReportsColumns.push({
                dataField: "hour",
                text: "Hour",
                sort: true,
                footer: ''
            })
        }
        if (keys.includes("Domain")) {
            revenueDisplayReportsColumns.push({
                dataField: "Domain",
                text: "Site",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("AdUnitName")) {
            revenueDisplayReportsColumns.push({
                dataField: "AdUnitName",
                text: "Ad Unit",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("AdUnitSize")) {
            revenueDisplayReportsColumns.push({
                dataField: "AdUnitSize",
                text: "Size",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("InventoryType")) {
            revenueDisplayReportsColumns.push({
                dataField: "InventoryType",
                text: "Inventory Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("DealType")) {
            revenueDisplayReportsColumns.push({
                dataField: "DealType",
                text: "Deal Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("Device")) {
            revenueDisplayReportsColumns.push({
                dataField: "Device",
                text: "Device Type",
                sort: true,
                footer: '',
                style: { wordBreak: 'break-word' }
            })
        }
        if (keys.includes("TotalCodeServed")) {
            revenueDisplayReportsColumns.push({
                dataField: "TotalCodeServed",
                text: "Total Code Served",
                sort: true,
                style: { wordBreak: 'break-word' },
                footer: `${totalCodeServed}`
            })
        }
        if (keys.includes("PaidImpressions")) {
            revenueDisplayReportsColumns.push({
                dataField: "PaidImpressions",
                text: "Paid Impressions",
                sort: true,
                footer: `${totalImpressions}`
            })
        }
        if (keys.includes("Revenue")) {
            revenueDisplayReportsColumns.push({
                dataField: "Revenue",
                text: "Revenue",
                sort: true,
                footer: `${totalRevenue}`,
                formatter: (cellContent, revenue) => (
                    <>
                        <span>{((parseFloat(revenue.Revenue)).toFixed(2))}</span>
                    </>
                ),
            })
        }
        if (keys.includes("RPM")) {
            revenueDisplayReportsColumns.push({
                dataField: "RPM",
                text: "RPM",
                sort: true,
                footer: `${AvgRPM}`
            })
        }
        if (keys.includes("FillRate")) {
            revenueDisplayReportsColumns.push({
                dataField: "FillRate",
                text: "Fill Rate",
                sort: true,
                footer: `${AvgFillRate}` + '%'
            })
        }

        loader = false
        console.log(revenueDisplayReportsColumns)
    } else {
        loader = false
    }

    useEffect(() => {
        console.log(revenueDisplayReports)

        if (revenueDisplayReports !== null && revenueDisplayReports.length > 0) {
            // setLoader(false)
            loader = false
            let totalCodeServed = 0
            let paidImpressions = 0
            let revenue = 0
            let avgRPM = 0
            let avgFillRate = 0
            revenueDisplayReports.map((item, index) => {
                if (item.TotalCodeServed) {
                    totalCodeServed = totalCodeServed + parseInt(item.TotalCodeServed)
                }
                if (item.PaidImpressions) {
                    paidImpressions = paidImpressions + (parseInt(item.PaidImpressions) || 0)
                }
                if (item.Revenue) {
                    revenue = revenue + (parseFloat(item.Revenue))
                }
                if (item.RPM) {
                    avgRPM = avgRPM + (parseFloat(item.RPM))
                }
                if (item.FillRate) {
                    avgFillRate = avgFillRate + (parseFloat(item.FillRate))
                }
            })
            if (avgRPM > 0) {
                let avg = (revenue > 0 && paidImpressions > 0) ? (revenue / paidImpressions) * 1000 : 0
                setAvgRPM(avg.toFixed(2))
            }
            if (avgFillRate > 0) {
                let avgFLR = (paidImpressions > 0 && totalCodeServed > 0) ? (paidImpressions / totalCodeServed) * 100 : 0
                setAvgFillRate(avgFLR.toFixed(2))
            }
            setTotalCodeServed(totalCodeServed)
            setTotalImpressions(paidImpressions)
            setTotalRevenue(revenue.toFixed(2))
        } else {
            loader = false
        }

    }, [revenueDisplayReports]);

    var CSVProp
    const CSVClick = () => {
        CSVProp.onExport();
    }

    const MyExportCSV = (csvprops) => {
        CSVProp = csvprops
        return (<></>);
    };

    const copyTable = () => {
        console.log(CSVProp, JSON.stringify(CSVProp))
        navigator.clipboard.writeText(CSVProp)
        // const elTable = document.querySelector('table');

        // let range, sel;

        // if (document.createRange && window.getSelection) {

        //   range = document.createRange();
        //   sel = window.getSelection();
        //   sel.removeAllRanges();

        //   try {
        //     range.selectNodeContents(elTable);
        //     sel.addRange(range);
        //   } catch (e) {
        //     range.selectNode(elTable);
        //     sel.addRange(range);
        //   }

        //   document.execCommand('copy');
        // }

        // sel.removeAllRanges();

        console.log('Element Copied! Paste it in a file')
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Proxy AdUnit Report";
        const headers = revenueDisplayReportsColumns;

        // const data = this.state.people.map(elt=> [elt.name, elt.profession]);

        let content = {
            startY: 50,
            head: headers,
            body: revenueDisplayReports
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const handleData = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setEmailData(value)
        let urls = value.split(/[\s,]+/);
        setFinalData(finalData => ({ ...finalData, [name]: urls }));
    }

    const [state, setState] = useState({
        start: moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'),
        end: moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days'),
    });

    const { start, end } = state;

    const handleEvent = (startDate, endDate, label) => {
        console.log(startDate, endDate)
        setState({ start: startDate, end: endDate, });
        fromDate = startDate._d.toISOString();
        toDate = endDate._d.toISOString();
    };

    const labelDate = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');


    return (
        <div>

            {!reportOpen &&
                <Container fluid>
                    <Row>
                        <div className="col-12">
                                    <Form onSubmit={handleSubmit}>
                                    <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                    <div className="mb-3">
                                                    <h5>SELECT DATE</h5> <br />
                                                    <DateRangePicker
                                                        initialSettings={{
                                                            opens: 'left',
                                                            startDate: start.toDate(),
                                                            endDate: end.toDate(),
                                                            ranges: {
                                                                // Today: [moment(), moment()],
                                                                Yesterday: [
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                ],
                                                                'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month'),
                                                                    moment().subtract(1, 'month').endOf('month'),
                                                                ],
                                                            },
                                                        }}
                                                        onCallback={handleEvent}
                                                    >
                                                        <button className='datePicker' type="button">
                                                            <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                        </button>
                                                    </DateRangePicker>
                                                </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                        <h5> SELECT RANGE</h5><br />
                                                        <Input
                                                            name="range"
                                                            type="select"
                                                            className="form-select"
                                                            onChange={changeRange}
                                                            value={range}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option value='Month'>Month</option>
                                                            <option value='Day'>Day</option>
                                                            <option value='Cumulative'>Cumulative</option>
                                                        </Input>
                                                    </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                    <div className="mb-3">
                                                    <h5> SELECT Time Zone</h5><br />
                                                    <Input
                                                        name="timeZone"
                                                        type="select"
                                                        className="form-select"
                                                        defaultValue={'EST'}
                                                    >
                                                        <option value='' disabled>Select</option>
                                                        <option value='EST'>EST</option>
                                                    </Input>
                                                </div>
                                                    </Col>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5> SCHEDULE REPORT NAME:</h5><br />
                                                            <Input
                                                                name="scheduleName"
                                                                type="text"
                                                                className={(submitted && !finalData.scheduleName ? ' is-invalid' : '')}
                                                                onChange={handleData}
                                                                value={finalData.scheduleName}
                                                            >
                                                            </Input>
                                                            {submitted && !finalData.scheduleName ? (
                                                                <FormFeedback type="invalid">{'Schedule Report Name is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> EMAIL:</h5><br />
                                                            <Input
                                                                name="emails"
                                                                type="text"
                                                                className={(submitted && finalData.emails.length === 0 ? ' is-invalid' : '')}
                                                                value={emailData}
                                                                onChange={handleChangeEmail}
                                                                placeholder="Enter multiple email addresses seperated by commas"
                                                            >
                                                            </Input>
                                                            {submitted && finalData.emails.length === 0 ? (
                                                                <FormFeedback type="invalid">{'Email is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                        <Row style={{ padding: '1% 3%', }}>

                                            <Col xs={4} >
                                                {/* <div className="mb-3">
                                                    <h5>SELECT DATE</h5> <br />
                                                    <DateRangePicker
                                                        initialSettings={{
                                                            opens: 'left',
                                                            startDate: start.toDate(),
                                                            endDate: end.toDate(),
                                                            ranges: {
                                                                // Today: [moment(), moment()],
                                                                Yesterday: [
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                    moment().subtract(estCurrentDate > 1 ? 2 : 1, 'days'),
                                                                ],
                                                                'Last 7 Days': [moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'Last 30 Days': [moment().subtract(estCurrentDate > 1 ? 31 : 30, 'days'), moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')],
                                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                'Last Month': [
                                                                    moment().subtract(1, 'month').startOf('month'),
                                                                    moment().subtract(1, 'month').endOf('month'),
                                                                ],
                                                            },
                                                        }}
                                                        onCallback={handleEvent}
                                                    >
                                                        <button className='datePicker' type="button">
                                                            <i className="bx bx-calendar font-size-18 " id="edittooltip" style={{ margin: '3% 3% 2%' }} ></i>{labelDate}
                                                        </button>
                                                    </DateRangePicker>
                                                </div>
                                                <br /> */}
                                                <h5>FILTERS</h5>
                                                <div className="mb-3">
                                                    <Label className="form-label">Sites</Label>
                                                    <Select
                                                        value={selectedDomain}
                                                        onChange={s => {
                                                            handleSelectDomian(s)
                                                        }}
                                                        options={Domain}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>


                                                <div className="mb-3">
                                                    <Label className="form-label">Ad Unit</Label>
                                                    <Select
                                                        value={selectedAdUnitName}
                                                        onChange={s => {
                                                            handleSelectAdUnitName(s)
                                                        }}
                                                        options={AdUnitName}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                       
                                                <div className="mb-3">
                                                    <Label>AdUnit Size</Label>
                                                    <Select
                                                        value={selectedAdUnitSize}
                                                        onChange={s => {
                                                            handleSelectAdUnitSize(s)
                                                        }}
                                                        options={AdUnitSize}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Inventory Type </Label>
                                                    <Select
                                                        value={selectedInventoryType}
                                                        onChange={s => {
                                                            handleSelectInventoryType(s)
                                                        }}
                                                        options={InventoryType}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Deal Type</Label>
                                                    <Select
                                                        value={selectedDealType}
                                                        onChange={s => {
                                                            handleSelectDealType(s)
                                                        }}
                                                        options={DealType}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Device Type</Label>
                                                    <Select
                                                        value={selectedDevice}
                                                        onChange={s => {
                                                            handleSelectDevice(s)
                                                        }}
                                                        options={Device}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

                                            </Col>
                                            <Col xs={4} style={{ padding: '0% 3%', }}>

                                                {/* <div className="mb-3">
                                                    <h5> SELECT RANGE</h5><br />
                                                    <Input
                                                        name="range"
                                                        type="select"
                                                        className="form-select"
                                                        onChange={changeRange}
                                                        value={range}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='Month'>Month</option>
                                                        <option value='Day'>Day</option>
                                                        <option value='Cumulative'>Cumulative</option>
                                                    </Input>
                                                </div>
                                                <br /> */}
                                                <h5 className="mb-3 ">DIMENSIONS</h5>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAll}
                                                        onChange={changeDMSelect}
                                                        id="reportselectall"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportselectall"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.Domain}
                                                        name="Domain"
                                                        onChange={changeSelection}
                                                        id="reportsites"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportsites"
                                                    >
                                                        Sites
                                                    </label>
                                                </div>


                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.AdUnitName}
                                                        name="AdUnitName"
                                                        onChange={changeSelection}
                                                        id="reportadunit"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportadunit"
                                                    >
                                                        Ad Unit
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.Device}
                                                        name="Device"
                                                        onChange={changeSelection}
                                                        id="reportDevicetype"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportDevicetype"
                                                    >
                                                        Device Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.AdUnitSize}
                                                        name="AdUnitSize"
                                                        onChange={changeSelection}
                                                        id="reportadunitsize"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportadunitsize"
                                                    >
                                                        AdUnit Size
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.DealType}
                                                        name="DealType"
                                                        onChange={changeSelection}
                                                        id="reportdealtype"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportdealtype"
                                                    >
                                                        Deal Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.InventoryType}
                                                        name="InventoryType"
                                                        onChange={changeSelection}
                                                        id="reportinventorytype"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportinventorytype"
                                                    >
                                                        Inventory Type
                                                    </label>
                                                </div>


                                            </Col>
                                            <Col xs={4} style={{ padding: '0 3%', }}>
                                                {/* <div className="mb-3">
                                                    <h5> SELECT Time Zone</h5><br />
                                                    <Input
                                                        name="timeZone"
                                                        type="select"
                                                        className="form-select"
                                                        defaultValue={'EST'}
                                                    >
                                                        <option value='' disabled>Select</option>
                                                        <option value='EST'>EST</option>
                                                    </Input>
                                                </div>
                                                <br /> */}
                                                <h5 className="mb-3">METRICES</h5>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllMTC}
                                                        onChange={changeMTCSelect}
                                                        id="metricesselectall"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="metricesselectall"
                                                    >
                                                        Select All
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.TotalCodeServed}
                                                        name="TotalCodeServed"
                                                        onChange={changeSelectionMTC}
                                                        id="totalcodeserved"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="totalcodeserved"
                                                    >
                                                        Total Code Served
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.PaidImpressions}
                                                        name="PaidImpressions"
                                                        onChange={changeSelectionMTC}
                                                        id="paidImpressions"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="paidImpressions"
                                                    >
                                                        Paid Impressions
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.Revenue}
                                                        name="Revenue"
                                                        onChange={changeSelectionMTC}
                                                        id="reportrevenue"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportrevenue"
                                                    >
                                                        Revenue
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.RPM}
                                                        name="RPM"
                                                        onChange={changeSelectionMTC}
                                                        id="revenueRpm"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="revenueRpm"
                                                    >
                                                        RPM
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.FillRate}
                                                        name="FillRate"
                                                        onChange={changeSelectionMTC}
                                                        id="reportFillRate"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="reportFillRate"
                                                    >
                                                        Fill Rate
                                                    </label>
                                                </div>


                                            </Col>
                                        </Row>


                                        {/* <div className="form-check " style={{ padding: '1% 4%', }}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={scheduleReport}
                                                name="AdUnitName"
                                                onChange={(e) => setScheduleReport(e.target.checked)}
                                            />
                                            <h5
                                                className="form-check-label"
                                            >
                                                Schedule Report
                                            </h5>
                                        </div> */}
                                        {/* {scheduleReport &&
                                            <div>
                                                <Row style={{ padding: '1% 3%', }}>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5>SELECT DATE:</h5> <br />
                                                            <Input
                                                                name="dateRange"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.dateRange}
                                                            >
                                                                <option value='' disabled>Select Date Range</option>
                                                                <option value='yesterday'>Yesterday</option>
                                                                <option value='last7Days'>Last 7 Days</option>
                                                                <option value='last30Days'>Last 30 Days</option>
                                                                <option value='thisMonth'>Current Month</option>
                                                                <option value='lastMonth'>Last Month</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> GROUP BY:</h5><br />
                                                            <Input
                                                                name="range"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.range}
                                                            >
                                                                <option value='' disabled>Select</option>
                                                                <option value='Month'>Month</option>
                                                                <option value='Day'>Day</option>
                                                                <option value='Cumulative'>Cumulative</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> FREQUENCY:</h5><br />
                                                            <Input
                                                                name="frequency"
                                                                type="select"
                                                                className="form-select"
                                                                onChange={handleData}
                                                                value={finalData.frequency}
                                                            >
                                                                <option value='daily' >Daily</option>
                                                                <option value='weekly'>Weekly</option>
                                                                <option value='monthly'>Monthly</option>
                                                            </Input>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} >
                                                        <div className="mb-3">
                                                            <h5> SCHEDULE REPORT NAME:</h5><br />
                                                            <Input
                                                                name="scheduleName"
                                                                type="text"
                                                                className={(submitted && !finalData.scheduleName ? ' is-invalid' : '')}
                                                                onChange={handleData}
                                                                value={finalData.scheduleName}
                                                            >
                                                            </Input>
                                                            {submitted && !finalData.scheduleName ? (
                                                                <FormFeedback type="invalid">{'Schedule Report Name is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} style={{ padding: '0% 3%', }}>
                                                        <div className="mb-3">
                                                            <h5> EMAIL:</h5><br />
                                                            <Input
                                                                name="emails"
                                                                type="text"
                                                                className={(submitted && finalData.emails.length === 0 ? ' is-invalid' : '')}
                                                                value={emailData}
                                                                onChange={handleChangeEmail}
                                                                placeholder="Enter multiple email addresses seperated by commas"
                                                            >
                                                            </Input>
                                                            {submitted && finalData.emails.length === 0 ? (
                                                                <FormFeedback type="invalid">{'Email is required'}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        } */}
                                        <Row>
                                            <Col>
                                                <div className=" text-sm-end">
                                                    {revenueDisplayReports.length > 0 && !reportOpen &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary save-user"
                                                            onClick={() => setReportOpen(true)}
                                                        >
                                                            Go Back
                                                        </button>
                                                    }
                                                    &nbsp;&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary save-user"
                                                        onClick={queryReportforData}
                                                        data-toggle="modal"
                                                    >
                                                        Query Report
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                        </div>
                    </Row>
                </Container>
            }


            {reportOpen &&

                <Container fluid={true} style={{ pointerEvents: (loading) ? 'none' : 'unset' }}>
                    <div className="row mb-2 d-sm-flex  justify-content-between">
                        <div className=" text-sm-end">
                            <div className="btn-group">
                                <Dropdown
                                    isOpen={btnprimary1}
                                    toggle={() => setBtnprimary1(!btnprimary1)}
                                >
                                    <DropdownToggle tag="button" className="btn btn-primary">
                                        Export
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/* <DropdownItem onClick={copyTable}>Copy</DropdownItem> */}
                                        <DropdownItem onClick={CSVClick}>CSV</DropdownItem>
                                        {/* <DropdownItem onClick={exportPDF}>PDF</DropdownItem> */}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                color="primary"
                                className="font-16 btn-block btn btn-primary"
                                onClick={(e) => setReportOpen(false)}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Modify Report
                            </Button>
                        </div>
                    </div>
                    {loading &&
                        <Spinner className="ms-2 loader" color="primary" />
                    }

                    {!loading &&
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody aria-hidden="true">
                                        {loader &&
                                            <>
                                                <Spinner className="ms-2 loader" color="primary" />
                                                <br />
                                                <h5 className="text-center mt-5" style={{ padding: '15%', fontSize: '25px' }}></h5>
                                                <br />
                                            </>
                                        }
                                        {revenueDisplayReports.length === 0 && !loader &&
                                            <h5 className="text-center" style={{ padding: '15%', fontSize: '25px' }}>No Data</h5>
                                        }
                                        {revenueDisplayReports.length > 0 && !loader &&
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField={keyField}
                                                data={revenueDisplayReports}
                                                columns={revenueDisplayReportsColumns}
                                            >
                                                {({ paginationProps, paginationTableProps }) => {
                                                    return (
                                                        <ToolkitProvider
                                                            keyField={keyField}
                                                            data={revenueDisplayReports}
                                                            columns={revenueDisplayReportsColumns}
                                                            // bootstrap4
                                                            search
                                                            exportCSV
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>
                                                                    <Row className="mb-2">
                                                                        <Col sm="8">
                                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                                <div className="position-relative">
                                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                                    <i className="bx bx-search-alt search-icon" />
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm="1">
                                                                            <MyExportCSV {...toolkitProps.csvProps} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    keyField={keyField}
                                                                                    responsive
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={
                                                                                        "table align-middle table-nowrap"
                                                                                    }
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                                // pagination={paginationFactory(pageOptions)}
                                                                                // ref={node}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <PaginationTotalStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                                <span>Show Rows : </span> &nbsp;&nbsp;
                                                                                <SizePerPageDropdownStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                            <div className="text-md-right ms-auto">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    );
                                                }}
                                            </PaginationProvider>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }

                </Container>
            }

            <Modal size="sm" isOpen={showAlert} toggle={() => { setShowAlert(!showAlert) }} backdrop={'static'} centered={true}
                id="staticBackdrop"
            >
                <div className="modal-content">
                    <ModalBody className="px-2 py-4 text-center">
                        <p className="text-muted font-size-16 mb-4">Do you want to Schedule Report?</p>

                        <div className="hstack gap-2 justify-content-center mb-0">
                            <button type="button" className="btn btn-light" onClick={handleSubmit}>No</button>
                            <button type="button" className="btn btn-primary" onClick={confirmScheduleReport}>Yes</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>


        </div>
    )
}

export default NewPublisherSchedule_Report