import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Col, Container, Row, Alert, Form, Label, Input, FormFeedback } from "reactstrap";

// availity-reactstrap-validation
import { Link,useHistory   } from "react-router-dom";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logodark from "../../assets/images/favicons.png";
import user from "../../assets/images/users/avatar-1.jpg";
import CarouselPage from "pages/Authentication/CarouselPage";

// actions
import { loginUser,loginUsers,apiError, socialLogin } from "../../store/actions";
import ProfileImage from 'pages/Dashboard/ProfileImage';

var userInfo = JSON.parse(localStorage.getItem('authUser'));

const LockScreen = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const userSessionState = localStorage.getItem('userSessionState');
  const [timer, setTimer] = useState({ count: 0 })
	const linkRef = useRef();
	const increment = useRef(null)
  const [passwordShown, setPasswordShown] = useState(false);
  const adminDetails = JSON.parse(localStorage.getItem('AdminDetails'));

  if(adminDetails){
    userInfo = JSON.parse(localStorage.getItem('AdminDetails'));
 }else{
    userInfo = JSON.parse(localStorage.getItem('authUser'));
 }

 if(userInfo){
  var headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token ? userInfo.token : ''}`
  }
}else{
  console.log('clicked')
  localStorage.removeItem('authUser');
  history.push('/login');
}
	
useEffect(() => {
  // console.log(userInfo)
  if (!userInfo) {
    localStorage.removeItem('authUser');
    history.push('/login');
  }

}, [])

  const togglePasswordVisiblity = () => {
   setPasswordShown(passwordShown ? false : true);
 };

    useEffect(async () => {
        handleStart()
        startSession();
        startWindowMonitoring();
        dispatch(apiError(""));
     }, [])
    
          const handleStart = () => {
            increment.current = setInterval(() => {
                setTimer(prevState => ({
                    count: prevState.count + 1
                }))
            }, 1000)
        }
    
      const path = window.location.pathname
    
        const startSession = async () => {
            try {
                setInterval(async () => {
                    axios.get("/v2.0/AP/isExpired",
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": 'application/json',
                                "Authorization": `Bearer ${userInfo.token}`
                            }
                        })
                        .then((res) => {
                            console.log("result", res)
                            if (res.data.isExpired === true) {
                               dispatch(apiError(""));
                                windowStop()
                                linkRef.current.click()
                            }
                        })
                        .catch((err) => {
                            windowStop()
                            console.log("e", err)
                        });
                }, 600000);
            } catch (e) {
                console.log(e);
            }
        };
    
        const windowStatus = () => {
            clearInterval(increment.current)
            setTimer({ count: 0 })
            handleStart()
        }
    
        const windowStop = () => {
            clearInterval(increment.current)
            setTimer({ count: 0 })
        }
    
        const handleWindowClose = () => {
            axios.get("/v2.0/AP/expireToken",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": 'application/json',
                        "Authorization": `Bearer ${userInfo.token}`
                    }
                })
                .then((res) => {
                    console.log("result", res)
                    if (res.data.TokenisExpired === true) {
                      dispatch(apiError(""));
                          stopWindowMonitoring()
                          linkRef.current.click()
                    }
                })
                .catch((err) => {
                    console.log("e", err)
    
                });
        }
    
        const startWindowMonitoring = async () => {
            window.addEventListener("scroll", windowStatus);
            window.addEventListener("keydown", windowStatus);
            window.addEventListener("click", windowStatus);
        }
    
        const stopWindowMonitoring = async () => {
            window.removeEventListener("scroll", windowStop, true);
            window.removeEventListener("keydown", windowStop, true);
            window.removeEventListener("click", windowStop, true);
        }
    
        useEffect(() => {
          if (timer.count >= 20) {
                handleWindowClose()
            }
        }, [timer.count]);



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userInfo ? userInfo.username : '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      if(adminDetails){
        dispatch(loginUsers(values, props.history,'login'));
      }else{
        dispatch(loginUsers(values, props.history,'lockScreen'));
      }
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Lock Screen | Bidsxchange</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={7}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-50" style={{margin:"auto"}}>
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      {/* <Link to="dashboard"
                        className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                      </Link> */}
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary text-center">Locked Screen</h5>
                        <p className="text-muted text-center">
                          Enter your password to unlock the screen!
                        </p>
                        <p className="text-muted text-center">
                            windos will session out in another 5 minutes , now time is : {timer.count} seconds
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                     {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="user-thumb text-center mb-4">
                            {/* <img
                              src={user}
                              className="rounded-circle img-thumbnail avatar-md"
                              alt="thumbnail"
                            /> */}
                            <ProfileImage />
                            <h5 className="font-size-15 mt-3">{userInfo ? userInfo.username : ''}</h5>
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter Password"
                              type={passwordShown ? "text" : "password"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                          <span className="passwordIcon">
											      <   i onClick={togglePasswordVisiblity} id="passwordToggle" className={passwordShown ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
									      </span>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>


                          <div className="text-end">
                            <button className="btn btn-primary w-md" type="submit" > Unlock </button>
                          </div>
                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Not you ? return &nbsp;&nbsp;
                            <Link
                              to="login"
                              className="fw-medium text-primary"
                            >
                              Sign In
                            </Link>
                          </p>
                        </div>
                        <Link ref={linkRef} to="/session-out"></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="footer" style={{left:'0px'}}>
              <Container fluid={true}>
                <Row>
            
                <Col md={6}></Col>
                  <Col md={6}>
                    <div className="text-sm-end d-none d-sm-block">
                    2015 - {new Date().getFullYear()} © Bidsxchange.
                    </div>
                  </Col>
                </Row>
              </Container>
            </footer>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

LockScreen.propTypes = {
  error: PropTypes.any,
  history:PropTypes.object,
};

export default LockScreen;
