/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
import MetaTags from 'react-meta-tags';
import Select from "react-select";
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Spinner,
    Label, Form,FormFeedback
} from "reactstrap"

import jsPDF from "jspdf";
import "jspdf-autotable";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, { PaginationProvider, PaginationTotalStandalone, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import moment from "moment";

import excelFile from 'assets/files/AdsTxtFile.xlsx'
import PDFFile from 'assets/files/AdsTxtEntries.pdf'

// actions
import {
    addNewAgencyScheduleReport as onADDAgencyScheduleReport,
    updateAgencyScheduleReport as onUpdateAgencyScheduleReport,
    readAgencyScheduleReport as onReadAgencyScheduleReport,
    addAgencyScheduleReportSuccess,
    addAgencyScheduleReportFail,
    updateAgencyScheduleReportSuccess,
    updateAgencyScheduleReportFail,
} from "store/AgencyConsole/ScheduleReport/actions";

import Toaster from "pages/Toaster/Toaster";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import SupplyBreadCrumb from "components/Common/SupplyBreadCrumb";


var fromDate = moment().subtract(6, 'days')
var toDate = moment()
var loader = false

const Agency_NewSchedule_Report= (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const dispatch = useDispatch();
    const [reportOpen, setReportOpen] = useState(false);
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [requestTotal, setRequestTotal] = useState(0)
    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectAll, setSelectAll] = useState(false)
    const [selectMetrice, setSelectMetrice] = useState(false)
    const [range, setRange] = useState('Day')
    const [selectAllMTC, setSelectAllMTC] = useState(false)
    const [scheduleReport, setAgencyScheduleReport] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const [agency, setagency] = useState([]);
    const [brand, setbrand] = useState([]);
    const [campaignName, setcampaignName] = useState([]);
    const [placementName, setplacementName] = useState([]);
    const [device, setDomian] = useState([]);
    const [adopsManager, setadopsManager] = useState([]);
    const [adUnitSize, setadUnitSize] = useState([]);
    const [inventoryType, setinventoryType] = useState([]);
    const [publisherManager, setpublisherManager] = useState([]);
    const [Device, setDevice] = useState([]);

    const [selectedagency, setSelectedagency] = useState([]);
    const [selectedbrand, setSelectedbrand] = useState([]);
    const [selectedcampaignName, setSelectedcampaignName] = useState([]);
    const [selectedplacementName, setSelectedplacementName] = useState([]);
    const [selecteddevice, setSelecteddevice] = useState([]);
    const [selectedadopsManager, setSelectedadopsManager] = useState([]);
    const [selectedadUnitSize, setSelectedadUnitSize] = useState([]);
    const [selectedinventoryType, setSelectedinventoryType] = useState([]);
    const [selectedpublisherManager, setSelectedpublisherManager] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState([]);

    const [selectAllDM, setSelectAllDM] = useState({
        agency: false,
        brand: false,
        campaignName: false,
        placementName: false,
        device: false,
        accountManager: false,
        adopsManager: false,
        adUnitSize: false,
        inventoryType: false,
        publisherManager: false,
    });

    const [filters, setFilters] = useState({
        agency: [],
        brand: [],
        campaignName: [],
        placementName: [],
        device: [],
        adopsManager: [],
        accountManager: [],
        adUnitSize: [],
        inventoryType: [],
        publisherManager: [],
    });

    const [filterData, setFilterData] = useState({
        agency: [],
        brand: [],
        campaignName: [],
        placementName: [],
        device: [],
        adopsManager: [],
        accountManager: [],
        adUnitSize: [],
        inventoryType: [],
        publisherManager: [],
    })

    const [selectedMTC, setSelectedMTC] = useState({
        impressions: false,
        clicks: false,
        ctr: false,
        conversions: false,
        conversionRatio: false,
        cost: false,
        cpm: false,
        videoCompletions: false,
        videoCompletionsRate: false,
    });

    const [finalData, setFinalData] = useState({
        dateRange: 'yesterday',
        range: 'Cumulative',
        frequency: 'daily',
        scheduleName: '',
        emails: [],

    });

    const [emailData, setEmailData] = useState('')

    console.log(props)

    useEffect(() => {
        if (props.selectedAgencyScheduleReport !== '') {
            setIsEdit(true)
            setFinalData(props.selectedAgencyScheduleReport)
            setFinalData(finalData => ({ ...finalData, range: props.selectedAgencyScheduleReport.settings.range }));
            setEmailData(props.selectedAgencyScheduleReport.emails)
            setSelectAllDM(props.selectedAgencyScheduleReport.settings.dimensions)
            setSelectedMTC(props.selectedAgencyScheduleReport.settings.metrices)
            setAgencyScheduleReport(props.selectedAgencyScheduleReport)
            setFilters(props.selectedAgencyScheduleReport.settings.filters)
            let DimArr = Object.values(selectAllDM)
            console.log(DimArr)
            if (!DimArr.includes(false)) {
                setSelectAll(true)
            } else {
                setSelectAll(false)
            }
        }
    }, []);

    const { errorMsg, successMsg } = useSelector(state => ({
        errorMsg: state.agencyScheduleReports.errorMsg,
        successMsg: state.agencyScheduleReports.successMsg,
    }));

    const { revenueDisplayReports } = useSelector(state => ({
        revenueDisplayReports: state.agencyScheduleReports.revenueDisplayRpt,
    }));

    useEffect(() => {
        if (successMsg !== null) {
            if (successMsg.success) {
                dispatch(addAgencyScheduleReportSuccess(""));
                dispatch(addAgencyScheduleReportFail(""));
                dispatch(updateAgencyScheduleReportSuccess(""));
                dispatch(updateAgencyScheduleReportFail(""));
                setLoader(false)
                if (props.selectedAgencyScheduleReport !== '') {
                    props.closeCanvas('Update')
                } else {
                    props.closeCanvas('Create')
                }
            }
        }
    }, [successMsg]);

    useEffect(() => {
        console.log(errorMsg)
        if (errorMsg !== null && errorMsg !== '') {
            setLoader(false)
        }
    }, [errorMsg]);

    const changeFilter = (e) => {
        const { name, value } = e.target;
        setFilters(filters => ({ ...filters, [name]: value }));
        changeData(e)
    }

    const handleData = (e) => {
        const { name, value } = e.target;
        setFinalData(finalData => ({ ...finalData, [name]: value }));
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setEmailData(value)
        let urls = value.split(/[\s,]+/);
        setFinalData(finalData => ({ ...finalData, [name]: urls }));
    }

    const handleSelectbrand = publisherManager => {
        setSelectedbrand(publisherManager)
        publisherManager ? setFilters(filters => ({ ...filters, brand: publisherManager.value })) : setFilters(filters => ({ ...filters, brand: '' }))
        // changeData('brand', listData)
    }

    const handleSelectcampaignName = partner => {
        setSelectedcampaignName(partner)
        partner ? setFilters(filters => ({ ...filters, campaignName: partner.value })) : setFilters(filters => ({ ...filters, campaignName: '' }))
        // changeData('campaignName', listData)
    }

    const handleSelectplacementName = publisher => {
        setSelectedplacementName(publisher)
        publisher ? setFilters(filters => ({ ...filters, placementName: publisher.value })) : setFilters(filters => ({ ...filters, placementName: '' }))
        // changeData('placementName', listData)
    }

    const handleSelectDomian = domian => {
        setSelecteddevice(domian)
        domian ? setFilters(filters => ({ ...filters, device: domian.value })) : setFilters(filters => ({ ...filters, device: '' }));
        // changeData('device', listData)
    }

    const handleSelectadopsManager = adUnitName => {
        setSelectedadopsManager(adUnitName)
        adUnitName ? setFilters(filters => ({ ...filters, adopsManager: adUnitName.value })) : setFilters(filters => ({ ...filters, adopsManager: '' }))
        // changeData('adopsManager', listData)
    }


    const handleSelectadUnitSize = adUnitSize => {
        setSelectedadUnitSize(adUnitSize)
        adUnitSize ? setFilters(filters => ({ ...filters, adUnitSize: adUnitSize.value })) : setFilters(filters => ({ ...filters, adUnitSize: '' }));
        // changeData('adUnitSize', listData)
    }

    const handleSelectinventoryType = inventoryType => {
        setSelectedinventoryType(inventoryType)
        inventoryType ? setFilters(filters => ({ ...filters, inventoryType: inventoryType.value })) : setFilters(filters => ({ ...filters, inventoryType: '' }));
        // changeData('inventoryType', listData)
    }

    const handleSelectpublisherManager = dealType => {
        setSelectedpublisherManager(dealType)
        dealType ? setFilters(filters => ({ ...filters, publisherManager: dealType.value })) : setFilters(filters => ({ ...filters, publisherManager: '' }));
        // changeData('publisherManager', listData)
    }

    const changeMTCSelect = (e) => {
        setSelectAllMTC(e.target.checked)
        setSelectedMTC({
            impressions: e.target.checked,
            clicks: e.target.checked,
            ctr: e.target.checked,
            conversions: e.target.checked,
            conversionRatio: e.target.checked,
            cost: e.target.checked,
            cpm: e.target.checked,
            videoCompletions: e.target.checked,
            videoCompletionsRate: e.target.checked,
        })
    }

    const changeSelectionMTC = (e) => {
        const { name, value } = e.target;
        setSelectedMTC(selectedMTC => ({ ...selectedMTC, [name]: e.target.checked }));
        let newMTC = { ...selectedMTC, [name]: e.target.checked }
        let MtcArr = Object.values(newMTC)
        if (!MtcArr.includes(false)) {
            setSelectAllMTC(true)
        } else {
            setSelectAllMTC(false)
        }
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            Partner:userInfo.name,
            name: userInfo.name
    }

    useEffect(() => {
        axios.post("/v2.0/partnerOp/Query/Agency/DisplayGetFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
        fromDate = moment().subtract(6, 'days')
        toDate = moment()
        console.log("start: ", fromDate);
        console.log("end: ", toDate);
    }, []);

    const changeData = (data) => {
        const { name, value } = data.target;
        body = {
            ...body,
            "agency": name === 'agency' ? data : [],
            "brand": name === 'brand' ? data : [],
            "campaignName": name === 'campaignName' ? data : [],
            "placementName": name === 'placementName' ? data : [],
            "device": name === 'device' ? data : [],
            adopsManager: name === 'adopsManager' ? data : [],
            accountManager: name === 'accountManager' ? data : [],
            adUnitSize: name === 'adUnitSize' ? data : [],
            inventoryType: name === 'inventoryType' ? data : [],
            publisherManager: name === 'publisherManager' ? data : [],
        }
        axios.post("/v2.0/partnerOp/Query/Revenue/DisplayGetSpecificFilter", body,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    objectModalData(res.data)
                    console.log(filterData)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    };

    const objectModalData = (response) => {
        if (response.agency) {
            setagency(objectModal(response.agency))
        }
        if (response.brand) {
            setbrand(objectModal(response.brand))
        }
        if (response.campaignName) {
            setcampaignName(objectModal(response.campaignName))
        }
        if (response.placementName) {
            setplacementName(objectModal(response.placementName))
        }
        if (response.device) {
            setDomian(objectModal(response.device))
        }
        if (response.adopsManager) {
            setadopsManager(objectModal(response.adopsManager))
        }
        if (response.accountManager) {
            setaccountManager(objectModal(response.accountManager))
        }
        if (response.adUnitSize) {
            setadUnitSize(objectModal(response.adUnitSize))
        }
        if (response.inventoryType) {
            setinventoryType(objectModal(response.inventoryType))
        }
        if (response.publisherManager) {
            setpublisherManager(objectModal(response.publisherManager))
        }
        if (response.deviceType) {
            setDevice(objectModal(response.deviceType))
        }
    }

    
    const objectModal = (response) => {
        console.log
        var dataList = []
        response.map((item, index) => {
            let obj = {}
            obj.value = item
            obj.label = item
            dataList.push(obj)
        })
        return dataList
    }

    const changeDMSelect = (e) => {
        setSelectAll(e.target.checked)
        setSelectAllDM({
            agency: e.target.checked,
            brand: e.target.checked,
            campaignName: e.target.checked,
            placementName: e.target.checked,
            device: e.target.checked,
            adopsManager: e.target.checked,
            accountManager: e.target.checked,
            adUnitSize: e.target.checked,
            inventoryType: e.target.checked,
            publisherManager: e.target.checked,
        })
    }

    const changeSelection = (e) => {
        const { name, value } = e.target;
        setSelectAllDM(selectAllDM => ({ ...selectAllDM, [name]: e.target.checked }));
        let newDim = { ...selectAllDM, [name]: e.target.checked }
        let DimArr = Object.values(newDim)
        console.log(DimArr)
        if (!DimArr.includes(false)) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    }

    const changeRange = (e) => {
        setRange(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        let DimArr = Object.values(selectAllDM)
        let MtcArr = Object.values(selectedMTC)
        if (!DimArr.includes(true) || !MtcArr.includes(true)) {
            toastr.warning('Please select atleast one Dimensions and Metrices')
        }
        else {
            if(finalData.scheduleName && finalData.emails.length > 0 )
            setLoader(true)
            let query = {
                "settings": {
                    "dimensions": selectAllDM,
                    "filters": filters,
                    "metrices": selectedMTC,
                    "range": range,
                    currencyType:userInfo.partnerCurrencyType
                },
                "scheduleName": finalData.scheduleName,
                "partnerName": userInfo.name,
                "dateRange": finalData.dateRange,
                "emails": finalData.emails,
                "frequency": finalData.frequency,
            }
            if(props.selectedAgencyScheduleReport !== ''){
                let final = {...finalData,...query}
                console.log(final)
                dispatch(onUpdateAgencyScheduleReport({ ...final }));
            }else{
                console.log(query)
                dispatch(onADDAgencyScheduleReport({ ...query }));
            }
   
        }
    }

    return (
        <div>
            {loader &&
                <Spinner className="ms-2 loader" color="primary" />
            }
            <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>
                <Card>
                    <CardBody>
                        <Form onSubmit={handleSubmit}>
                            <Row style={{ padding: '1% 3%', }}>
                                <Col xs={4} >
                                    <div className="mb-3">
                                        <h5>SELECT DATE:</h5> <br />
                                        <Input
                                            name="dateRange"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.dateRange}
                                        >
                                            <option value='' disabled>Select Date Range</option>
                                            <option value='yesterday'>Yesterday</option>
                                            <option value='last7Days'>Last 7 Days</option>
                                            <option value='last30Days'>Last 30 Days</option>
                                            <option value='thisMonth'>Current Month</option>
                                            <option value='lastMonth'>Last Month</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> GROUP BY:</h5><br />
                                        <Input
                                            name="range"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.range}
                                        >
                                            <option value='' disabled>Select</option>
                                            <option value='Month'>Month</option>
                                            <option value='Day'>Day</option>
                                            <option value='Cumulative'>Cumulative</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> FREQUENCY:</h5><br />
                                        <Input
                                            name="frequency"
                                            type="select"
                                            className="form-select"
                                            onChange={handleData}
                                            value={finalData.frequency}
                                        >
                                            <option value='daily' >Daily</option>
                                            <option value='weekly'>Weekly</option>
                                            <option value='monthly'>Monthly</option>
                                        </Input>
                                    </div>
                                </Col>
                                <Col xs={4} >
                                    <div className="mb-3">
                                        <h5> SCHEDULE REPORT NAME:</h5><br />
                                        <Input
                                            name="scheduleName"
                                            type="text"
                                            className={ (submitted && !finalData.scheduleName ? ' is-invalid' : '') }
                                            onChange={handleData}
                                            value={finalData.scheduleName}
                                        >
                                        </Input>
                                    {submitted && !finalData.scheduleName ? (
                                    <FormFeedback type="invalid">{'Schedule Report Name is required'}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                                <Col xs={8} style={{ padding: '0% 3%', }}>
                                    <div className="mb-3">
                                        <h5> EMAIL:</h5><br />
                                        <Input
                                            name="emails"
                                            type="text"
                                            className={ (submitted && finalData.emails.length === 0 ? ' is-invalid' : '') }
                                            value={emailData}
                                            onChange={handleChangeEmail}
                                            placeholder="Enter multiple email addresses seperated by commas"
                                        >
                                        </Input>
                                        {submitted && finalData.emails.length === 0 ? (
                                    <FormFeedback type="invalid">{'Email is required'}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                                <Col xs={4} >

                                    <br />
                                    <h5>FILTERS</h5>
                                    <div className="mb-3">
                                                    <Label className="form-label">Brand</Label>
                                                    <Select
                                                        value={selectedbrand}
                                                        onChange={s => {
                                                            handleSelectbrand(s)
                                                        }}
                                                        options={brand}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label className="form-label">Campaign </Label>
                                                    <Select
                                                        value={selectedcampaignName}
                                                        onChange={s => {
                                                            handleSelectcampaignName(s)
                                                        }}
                                                        options={campaignName}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Placement</Label>
                                                    <Select
                                                        value={selectedplacementName}
                                                        onChange={s => {
                                                            handleSelectplacementName(s)
                                                        }}
                                                        options={placementName}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Ad Size</Label>
                                                    <Select
                                                        value={selectedadUnitSize}
                                                        onChange={s => {
                                                            handleSelectadUnitSize(s)
                                                        }}
                                                        options={adUnitSize}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Device Type</Label>
                                                    <Select
                                                        value={selecteddevice}
                                                        onChange={s => {
                                                            handleSelectDomian(s)
                                                        }}
                                                        options={device}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label className="form-label">Inventory Type </Label>
                                                    <Select
                                                        value={selectedinventoryType}
                                                        onChange={s => {
                                                            handleSelectinventoryType(s)
                                                        }}
                                                        options={inventoryType}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                         
                                                <div className="mb-3">
                                                    <Label>Campaign Manager</Label>
                                                    <Select
                                                        value={selectedadopsManager}
                                                        onChange={s => {
                                                            handleSelectadopsManager(s)
                                                        }}
                                                        options={adopsManager}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                   
                                                <div className="mb-3">
                                                    <Label className="form-label">Sales Manager</Label>
                                                    <Select
                                                        value={selectedpublisherManager}
                                                        onChange={s => {
                                                            handleSelectpublisherManager(s)
                                                        }}
                                                        options={publisherManager}
                                                        isClearable={true}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>


                                </Col>
                                <Col xs={4} style={{ padding: '0% 3%', }}>


                                    <br />
                                    <h5 className="mb-3 ">DIMENSIONS</h5>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={changeDMSelect}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Select All
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.brand}
                                                        name="brand"
                                                        onChange={changeSelection}
                                                        id="brand"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="brand"
                                                    >
                                                        Brand
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.campaignName}
                                                        name="campaignName"
                                                        onChange={changeSelection}
                                                        id="supplycampaignName"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="supplycampaignName"
                                                    >
                                                        Campaign
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.placementName}
                                                        name="placementName"
                                                        onChange={changeSelection}
                                                        id="placementName"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="placementName"
                                                    >
                                                        Placement
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.adUnitSize}
                                                        name="adUnitSize"
                                                        onChange={changeSelection}
                                                        id="adUnitSize"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="adUnitSize"
                                                    >
                                                        Ad Size
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.device}
                                                        name="device"
                                                        onChange={changeSelection}
                                                        id="device"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="device"
                                                    >
                                                        Device Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.inventoryType}
                                                        name="inventoryType"
                                                        onChange={changeSelection}
                                                        id="inventoryType"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="inventoryType"
                                                    >
                                                        Inventory Type
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.adopsManager}
                                                        name="adopsManager"
                                                        onChange={changeSelection}
                                                        id="adopsManager"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="adopsManager"
                                                    >
                                                        Campaign Manager
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.accountManager}
                                                        name="accountManager"
                                                        onChange={changeSelection}
                                                        id="accountManager"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="accountManager"
                                                    >
                                                        Account Manager
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectAllDM.publisherManager}
                                                        name="publisherManager"
                                                        onChange={changeSelection}
                                                        id="publisherManager"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="publisherManager"
                                                    >
                                                        Sales Manager
                                                    </label>
                                                </div>

                                </Col>
                                <Col xs={4} style={{ padding: '0 3%', }}>

                                    <br />
                                    <h5 className="mb-3">METRICES</h5>
                                    <div className="form-check mt-2 mb-4">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectAllMTC}
                                            onChange={changeMTCSelect}
                                        />
                                        <label
                                            className="form-check-label"
                                        >
                                            Select All
                                        </label>
                                    </div>
                                    <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.impressions}
                                                        name="impressions"
                                                        onChange={changeSelectionMTC}
                                                        id="impressions"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="impressions"
                                                    >
                                                        Impressions
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.clicks}
                                                        name="clicks"
                                                        onChange={changeSelectionMTC}
                                                        id="clicks"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="clicks"
                                                    >
                                                        Clicks
                                                    </label>
                                                </div>

                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.ctr}
                                                        name="ctr"
                                                        onChange={changeSelectionMTC}
                                                        id="ctr"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="ctr"
                                                    >
                                                        CTR
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.conversions}
                                                        name="conversions"
                                                        onChange={changeSelectionMTC}
                                                        id="conversions"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="conversions"
                                                    >
                                                        Conversions
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.conversionRatio}
                                                        name="conversionRatio"
                                                        onChange={changeSelectionMTC}
                                                        id="conversionRatio"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="conversionRatio"
                                                    >
                                                        Conversions Ratio
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.cost}
                                                        name="cost"
                                                        onChange={changeSelectionMTC}
                                                        id="cost"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="cost"
                                                    >
                                                        Cost
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.cpm}
                                                        name="cpm"
                                                        onChange={changeSelectionMTC}
                                                        id="cpm"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="cpm"
                                                    >
                                                        CPM
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.videoCompletions}
                                                        name="videoCompletions"
                                                        onChange={changeSelectionMTC}
                                                        id="videoCompletions"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="videoCompletions"
                                                    >
                                                        Video Completion
                                                    </label>
                                                </div>
                                                <div className="form-check mt-2 mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={selectedMTC.videoCompletionsRate}
                                                        name="videoCompletionsRate"
                                                        onChange={changeSelectionMTC}
                                                        id="videoCompletionsRate"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="videoCompletionsRate"
                                                    >
                                                        Video Completion Rate
                                                    </label>
                                                </div>



                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-start">
                                        &nbsp;&nbsp;
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            // eslint-disable-next-line react/prop-types
                                            onClick={props.closeCanvas}
                                        >
                                            Back
                                        </button>
                                        &nbsp;&nbsp;
                                        {!isEdit &&
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                            >
                                                Save
                                            </button>
                                        }
                                        &nbsp;&nbsp;

                                        {isEdit && !props.selectedArchived &&
                                            <button
                                                type="submit"
                                                className="btn btn-primary save-user"
                                            >
                                                Update
                                            </button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default Agency_NewSchedule_Report