/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button, Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
} from "reactstrap"

import classnames from "classnames";
import queryString from 'query-string';

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table-nextgen-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb"
import AssociateBuyers_V3 from "./AssociateBuyers";

var selectedItem = []
var selectedIndex = []

var buyersData = []

const BuyerAdUnits_V3 = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [lineItems, setLineItems] = useState([])
    const [btnprimary1, setBtnprimary1] = useState(false)
    const [uploadModal, setUploadModal] = useState(false);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])
    const [deleteState, setDeleteState] = useState(false)
    const [selectedAdUnit, setSelectedAdUnit] = useState({})
    const [selectedAdUnitState, setSelectedAdUnitState] = useState(false);
    const [isRight, setIsRight] = useState(false);
    // const [buyersData,setLineItemsData] = useState([])
    const parsed = queryString.parse(window.location.search);
    const nextRef = useRef();

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };


    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    useEffect(() => {
        getLineItem()
    }, []);

    const getLineItem = (event) => {
        body.url = (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "/v2.0/partnerOp/Demand/HeaderBidding/HB_Buyers/read/Adunits" :  "/v2.0/OP/test/HB_Buyers/read/Adunits"
        if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
            body.requestBody = { ...body.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
          }
        axios.post(body.url, body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.data) {
                    res.data.data.map((item, index) => {
                        item.id = index + 1
                    })
                    setLineItems([...res.data.data])
                    if (parsed.status === 'EditAssociateAdUnits') {
                        res.data.data.map((item, index) => {
                            if(item.adUnitID === Number(parsed.adUnitID)){
                                setSelectedAdUnit(item)
                                setIsRight(!isRight);
                            }
                        })
                    }
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    const closeLineItem = (event) => {
        console.log(event)
        if (event === 'Update') {
            toastr.success('Line Items are Datched Successfully..')
        }
        setSelectedAdUnitState(false)
        getLineItem()
    }

    const toggleRightCanvas = () => {
        nextRef.current.click()
        setIsRight(!isRight);
    };


    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const adUnitListColoumns = [
        {
            text: "ID",
 headerClasses:"ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "AdUnit ID",
            dataField: "adUnitID",
            sort: true,
        },
        {
            text: "AdUnit",
            dataField: "aUnitName",
            sort: true,
            formatter: (cellContent, buyer) => (
                <>
                    <span className="font-size-12 mb-1">
                        <span className=" mb-1">{buyer.aUnitName}</span><br />
                        {buyer.buyersCount !== 0  &&
                            <Link to={`/AssociateBuyer_test?adUnitID=${buyer.adUnitID}&adUnitName=${buyer.aUnitName}&status=ViewBuyers`}  className="color-dark-blue ">
                                Associate Buyer
                            </Link>
                        }
                        {buyer.buyersCount === 0  &&
                            <Link to={`/AssociateBuyer_test?adUnitID=${buyer.adUnitID}&adUnitName=${buyer.aUnitName}&status=AssociateBuyers`} style={{ color: 'red' }}>
                                * No Buyers Associated | <span style={{ color: 'blue' }}> + Associate Buyers </span>
                            </Link>
                        }
                    </span>
                </>
            ),
        },
        {
            text: "HB Buyers Presents",
            dataField: "buyersCount",
            sort: true,
            align: 'center',
            headerAlign: 'center',
        },

        // {
        //     dataField: "",
        //     isDummyField: true,
        //     editable: false,
        //     text: "Action",
        //     align: 'center',

        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, adUnit) => (
        //         <div className="d-flex gap-3">
        //             <button color="primary"
        //                 className="font-16 btn-block btn btn-primary" onClick={() => { tog_standard(adUnit); }} >
        //                 View Associated Buyers
        //             </button>
        //         </div>
        //     ),
        // },
    ];

    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
            setSelectedAdUnit(adUnit)
            // if (adUnit.buyerData.length > 0) {
            //     adUnit.buyerData.map((item, index) => {
            //         item.id = index + 1
            //     })
            // }
            // console.log(adUnit.buyerData)
            // buyersData = adUnit.buyerData
            // console.log(buyersData)
            setIsRight(!isRight);
        } else {
            setIsRight(!isRight);
        }
    }



    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: lineItems.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    return (
        <React.Fragment >
            <div className="page-content">
                <MetaTags>
                    <title>AdUnits | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    {/* Render Breadcrumbs */}

                    <div className=" d-sm-flex  justify-content-between">
                        <Breadcrumbs title="Demand" breadcrumbItem="Ad Unit" breadList="All Ad Units" />
                    </div>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <PaginationProvider
                                    pagination={paginationFactory(pageOptions)}
                                    keyField={keyField}
                                    columns={adUnitListColoumns}
                                    data={lineItems}
                                >
                                    {({ paginationProps, paginationTableProps }) => {
                                        return (
                                            <ToolkitProvider
                                                keyField={keyField}
                                                data={lineItems}
                                                columns={adUnitListColoumns}
                                                // bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="3">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                    <div className="row m-0 React-Table-Selection">
                                                                        <div style={{ padding: '0.5% 2%' }}>
                                                                            &nbsp;  &nbsp;
                                                                            <button color="primary" className="btn btn-primary " onClick={() => setDeleteState(true)} >
                                                                                Delete Entry
                                                                            </button>
                                                                            &nbsp;  &nbsp;
                                                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                                className="mdi mdi-close-thick font-size-18"
                                                                            ></i></Button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Col>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={keyField}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        // selectRow={selectRow}
                                                                        classes={
                                                                            "table align-middle table-nowrap"
                                                                        }
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                    // ref={node}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <PaginationTotalStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                    <span>Show Rows : </span> &nbsp;&nbsp;
                                                                    <SizePerPageDropdownStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        );
                                    }}
                                </PaginationProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </div>
            <Offcanvas isOpen={isRight} direction='end' >
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    {'View Associated Buyers'}
                </OffcanvasHeader>
                <OffcanvasBody className="p-0">
                    <AssociateBuyers_V3 closeCanvas={toggleRightCanvas} selectedAdUnits={selectedAdUnit} buyerInfo={buyersData}  />
                </OffcanvasBody>
            </Offcanvas>
            <Link ref={nextRef} to={'#'} ></Link>
        </React.Fragment >
    )
}

BuyerAdUnits_V3.propTypes = {}

export default BuyerAdUnits_V3