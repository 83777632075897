/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import Header from "components/VerticalLayout/Header";
import Sidebar from "components/VerticalLayout/Sidebar";
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';


import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Button,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Dropdown,
    Input,
    Modal,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap"

import classnames from "classnames";

import DateRangePickers from 'helpers/DateRangePicker'
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import cellEditFactory , { Type } from 'react-bootstrap-table2-editor';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var selectedItem = []
var selectedIndex = []

var highImpactSettingsData = []

const DetachStbrbyAdUnit = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [selectedAdUnit, setSelectedAdUnit] = useState(props.selectedAdUnit)
    const [selectedStbr,setSelectedHIS] = useState(props.selectedAdUnit.standardBannerData ? props.selectedAdUnit.standardBannerData : [])

    console.log(selectedAdUnit)

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
          if (isSelect) {
            setSelected([...selected, row.id])
            setSelectedRowsStatus(true)
            selectedIndex.push(row.standardBannerSettingID)
            selectedItem.push(row.standardBannerSettingID)
          } else {
            setSelected(selected.filter(x => x !== row.id))
            let index = selectedIndex.indexOf(row.standardBannerSettingID)
            selectedIndex.splice(index, 1)
            let index2 = selectedItem.indexOf(row.standardBannerSettingID)
            selectedItem.splice(index2, 1)
            if (selectedIndex.length === 0) {
              setSelectedRowsStatus(false)
            }
          }
        },
        onSelectAll: (isSelect, rows, e) => {
          const ids = rows.map(r => r.id);
          if (isSelect) {
            setSelected(ids)
            rows.map((item, index) => {
              selectedIndex.push(item)
              selectedItem.push(item)
            })
            setSelectedRowsStatus(true)
          } else {
            setSelected([])
            setSelectedRowsStatus(false)
            selectedIndex = []
            selectedItem = []
          }
        }
      };

      const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
      }

      const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const detachAdUnit = (event) => {
        console.log(selectedItem)
        let standardBannerData = selectedStbr.filter(
            (array22) => selectedItem.some((array11) => array11 === array22.standardBannerSettingID));
            standardBannerData.map((item, index) => {
            delete item.id
        })
        let selectedAdUnitData = selectedAdUnit
        selectedAdUnitData.standardBannerData = standardBannerData;
        let body = []
        body.push(selectedAdUnitData)
        console.log(body)
        let bodyEntries = {
            url :(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? '/v2.0/partnerOp/Supply/StandardBannerSettings/update/forDetachment' : "/v2.0/OP/StandardBannerSettings/update/forDetachment",
            "requestBody": {
                userID: userInfo.userID,
                userRole: userInfo.userRole,
                companyID: userInfo.companyID,
                name:userInfo.name,
                data:body
            }
        }
        if(userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency'){
            bodyEntries.requestBody = {...bodyEntries.requestBody,partnerName:userInfo.name,partnerID:userInfo.userID}
        }
        console.log(bodyEntries)
        axios.post(bodyEntries.url, bodyEntries.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data) {
                    props.closeDetach('Update')
                }else{
                    toastr.error(res.data.message)
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const stbrSettingsListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            dataField: "standardBannerSettingID",
            sort: true,
        },
        {
            text: "Standard Banner Setting Name",
            dataField: "standardBannerSettingName",
            sort: true,
        },
    ];

    function tog_standard(adUnit) {
        console.log(adUnit)
        if (adUnit !== '') {
          setSelectedAdUnit(adUnit)
          if(adUnit.stbrData.length > 0){
            adUnit.stbrData.map((item,index)=>{
                item.id = index + 1
            })
          }
          console.log(adUnit.stbrData)
          highImpactSettingsData = adUnit.stbrData
          console.log(highImpactSettingsData)
          setJsTagModalState(!jsTagModalState);
        } else {
          setJsTagModalState(!jsTagModalState);
        }
      }

      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

      const { SearchBar } = Search;
      const pageOptions = {
          sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
          totalSize: selectedStbr.length,
          custom: true,
          firstPageText: 'First',
          prePageText: 'Back',
          nextPageText: 'Next',
          lastPageText: 'Last',
          nextPageTitle: 'First page',
          prePageTitle: 'Pre page',
          firstPageTitle: 'Next page',
          lastPageTitle: 'Last page',
          showTotal: true,
          paginationTotalRenderer: customTotal,
          disablePageTitle: true,
      };

      const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];


  return (
    <React.Fragment >

        <Row style={{ height: '80vh', overflow: 'auto' }}>
 
            <Col lg="12">
                <Card>
                    <CardBody>
                        <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField={keyField}
                            columns={stbrSettingsListColumns}
                            data={selectedStbr}
                        >
                            {({ paginationProps, paginationTableProps }) => {
                                return (
                                    <ToolkitProvider
                                        keyField={keyField}
                                        data={selectedStbr}
                                        columns={stbrSettingsListColumns}
                                        // bootstrap4
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                 <h4 className="text-center">Ad Unit Name : {selectedAdUnit.aUnitName}</h4>
                                                <Row className="mb-2">
                                                    <Col sm="3">
                                                        <div className="search-box ms-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col xl="12">
                                                    {selectedRowsStatus && selectedIndex.length > 0 &&
                                                        <div className="row m-0 React-Table-Selection">
                                                        {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                        <div style={{ padding: '0.5% 2%' }}>

                                                            {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>Activate</button> */}
                                                            {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>
                                                            Activate
                                                            </button> */}
                                                            &nbsp;  &nbsp;
                                                            <button color="primary" className="btn btn-primary " onClick={()=> detachAdUnit(true)} >
                                                            Detach
                                                            </button>
                                                            &nbsp;  &nbsp;
                                                            {/* <Button color="primary" className="btn btn-primary " onClick={archiveAdvertiser} >
                                                            Archive
                                                            </Button> */}
                                                            <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                            className="mdi mdi-close-thick font-size-18"
                                                            ></i></Button>
                                                        </div>
                                                        </div>
                                                    }
                                                    </Col>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={keyField}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                selectRow={selectRow}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                                noDataIndication={<h5 className="text-center">No Data</h5>}
                                                            // ref={node}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                        <div className="d-inline">
                                                            <PaginationTotalStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                            <span>Show Rows : </span> &nbsp;&nbsp;
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                );
                            }}
                        </PaginationProvider>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeDetach}
                >
                  Back
                </button>
              </div>
            </Col>
          </Row>
    </React.Fragment>
  )
}

DetachStbrbyAdUnit.propTypes = {}

export default DetachStbrbyAdUnit