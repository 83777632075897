/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
    PaginationProvider, PaginationListStandalone,
    SizePerPageDropdownStandalone, PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import { withRouter, Link } from "react-router-dom";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

var selectedItem = []
var selectedIndex = []

var LineItemsData = []

const AssociateBuyers = props => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [selectedAdUnit, setSelectedAdUnit] = useState(props.selectedAdUnits)
    const [selectedLI, setSelectedLI] = useState([])


    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const [selectedRowsStatus, setSelectedRowsStatus] = useState(false)
    const [selected, setSelected] = useState([])

    const selectRow = {
        mode: "checkbox",
        nonSelectable: [],
        selected: selected,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelected([...selected, row.id])
                setSelectedRowsStatus(true)
                selectedIndex.push(row.buyerID)
                selectedItem.push(row.buyerID)
            } else {
                setSelected(selected.filter(x => x !== row.id))
                let index = selectedIndex.indexOf(row.buyerID)
                selectedIndex.splice(index, 1)
                let index2 = selectedItem.indexOf(row.buyerID)
                selectedItem.splice(index2, 1)
                if (selectedIndex.length === 0) {
                    setSelectedRowsStatus(false)
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            const ids = rows.map(r => r.id);
            if (isSelect) {
                setSelected(ids)
                rows.map((item, index) => {
                    selectedIndex.push(item)
                    selectedItem.push(item)
                })
                setSelectedRowsStatus(true)
            } else {
                setSelected([])
                setSelectedRowsStatus(false)
                selectedIndex = []
                selectedItem = []
            }
        }
    };

    const handleClose = () => {
        setSelectedRowsStatus(false)
        selectedItem = []
        setSelected([])
        selectedIndex = []
    }

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    const body = {
        "url": '',
        "requestBody": {
            userID: userInfo.userID,
            userRole: userInfo.userRole,
            companyID: userInfo.companyID,
            name: userInfo.name,
        }
    }

    useEffect(() => {
        getLineItem()
    }, []);

    const getLineItem = (event) => {
        body.url = (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') ? "/v2.0/partnerOp/Demand/HeaderBidding/HB_Buyers/associatedBuyers/"+ selectedAdUnit.adUnitID : "/v2.0/OP/HB_Buyers/associatedBuyers/" + selectedAdUnit.adUnitID
        if (userInfo.userRole === 'Partner' || userInfo.userRole === 'Agency') {
            body.requestBody = { ...body.requestBody, partnerName: userInfo.name, partnerID: userInfo.userID }
          }
        axios.post(body.url, body.requestBody,
            {
                headers: headers
            })
            .then((res) => {
                console.log("result is ==>", res)
                if (res.data.response) {
                    res.data.response.map((item, index) => {
                        item.id = index + 1
                    })
                    setSelectedLI([...res.data.response])
                }
            })
            .catch((err) => {
                console.log("e", err)
            });
    }


    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const keyField = "id";

    const buyersListColumns = [
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            text: "ID",
            headerClasses: "ID",
            dataField: "HB_BuyerID",
            sort: true,
        },
        {
            text: "Buyer Name",
            dataField: "HB_BuyerName",
            sort: true,
        },
        {
            dataField: "",
            isDummyField: true,
            editable: false,
            text: "Action",
            align: 'center',
            headerAlign: 'center',
            // hidden: (UserRolePrivileges.Demand && UserRolePrivileges.Demand.HeaderBidding) && (!UserRolePrivileges.Demand.HeaderBidding.update),
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, buyer) => (
                <>
                    <span className="font-size-12 mb-1">
                        <Link className="text-success " style={{ cursor: 'pointer' }} to={`/AssociateAdUnit?buyerID=${buyer.HB_BuyerID}&buyerName=${buyer.HB_BuyerName}&bidderName=${buyer.HB_Bidder}&adUnitID=${selectedAdUnit.adUnitID}&status=EditAssociateAdUnits`} >
                            <i
                                className="mdi mdi-pencil font-size-18"
                                id="edittooltip"
                            ></i>
                        </Link>
                    </span>
                </>
            ),
        },
    ];



    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} entries
        </span>
    );

    const { SearchBar } = Search;
    const pageOptions = {
        sizePerPageList: [{ text: "10", value: 10 }, { text: "30", value: 30 }, { text: "50", value: 50 }, { text: "100", value: 100 }],
        totalSize: selectedLI.length,
        custom: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
    };

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];


    return (
        <React.Fragment >

            <Row style={{ height: '80vh', overflow: 'auto' }}>

                <Col lg="12">
                    <Card>
                        <CardBody>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField={keyField}
                                columns={buyersListColumns}
                                data={selectedLI}
                            >
                                {({ paginationProps, paginationTableProps }) => {
                                    return (
                                        <ToolkitProvider
                                            keyField={keyField}
                                            data={selectedLI}
                                            columns={buyersListColumns}
                                            // bootstrap4
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <h4 className="text-center">Ad Unit Name : {selectedAdUnit.aUnitName}</h4>
                                                    <Row className="mb-2">
                                                        <Col sm="3">
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar {...toolkitProps.searchProps} />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            {selectedRowsStatus && selectedIndex.length > 0 &&
                                                                <div className="row m-0 React-Table-Selection">
                                                                    {/* <Typography color="inherit" sx={{ padding: '0.4% 1%', borderRight: '1px solid white' }}>{selectedRows.length} Selected </Typography> */}
                                                                    <div style={{ padding: '0.5% 2%' }}>

                                                                        {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>Activate</button> */}
                                                                        {/* <button color="primary" className="btn btn-primary " onClick={statusAdvertiser(true)}>
                                                            Activate
                                                            </button> */}
                                                                        &nbsp;  &nbsp;
                                                                        <button color="primary" className="btn btn-primary " onClick={() => detachAdUnit(true)} >
                                                                            Detach
                                                                        </button>
                                                                        &nbsp;  &nbsp;
                                                                        {/* <Button color="primary" className="btn btn-primary " onClick={archiveAdvertiser} >
                                                            Archive
                                                            </Button> */}
                                                                        <Button color="primary" className="btn btn-primary " style={{ position: "absolute", right: 0 }} onClick={handleClose} >  <i
                                                                            className="mdi mdi-close-thick font-size-18"
                                                                        ></i></Button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={keyField}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    // selectRow={selectRow}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    {...paginationTableProps}
                                                                    noDataIndication={<h5 className="text-center">No Data</h5>}
                                                                // ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="inner-custom-pagination d-flex">
                                                            <div className="d-inline">
                                                                <PaginationTotalStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <div className="text-md-center d-inline " style={{ marginLeft: '5%' }} >
                                                                <span>Show Rows : </span> &nbsp;&nbsp;
                                                                <SizePerPageDropdownStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                            <div className="text-md-right ms-auto">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    );
                                }}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="drawer-footer" style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
                <Col>
                    <div className="text-start">
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            // eslint-disable-next-line react/prop-types
                            onClick={props.closeCanvas}
                        >
                            Back
                        </button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

AssociateBuyers.propTypes = {}

export default AssociateBuyers