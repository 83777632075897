import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
// //Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row, Input,
    Button,
    Spinner,
    Col,

} from "reactstrap"
//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import downloadtemplate from "assets/files/newReporting.xlsx"

import downloadAgencytemplate from "assets/files/Agency_Data_Upload_Template.xlsx"

var fileNameEntry = ''
var agencyFileNameEntry = ''

const ReportUpload = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('authUser'));
    const [loader, setLoader] = useState(false);
    const [publisherReport, setPublisherReport] = useState({
        fileName: '',
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const [agencyReport, setAgencyReport] = useState({
        fileName: '',
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
    });
    const ref = useRef();
    const [inputKey, setInputKey] = useState(Math.random().toString(36))
    const [downloadFileBtn, setDownloadFileBtn] = useState(false)
    const [downloadUrl, setDownloadUrl] = useState('')
    const agencyRef = useRef();
    const [agencyInputKey, setAgencyInputKey] = useState(Math.random().toString(36))
    const [agencyDownloadFileBtn, setAgencyDownloadFileBtn] = useState(false)
    const [agencyDownloadUrl, setAgencyDownloadUrl] = useState('')
    const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));

    const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token}`
    }

    toastr.options = {
        positionClass: "toast-top-center",
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 3000,
        extendedTimeOut: 1000
    };

    const fileChangepublisherReport = (file) => {
        setDownloadFileBtn(false)
        if (file.target.files[0].name) {
            setLoader(true)
        } else {
            setLoader(false)
        }
        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        fileNameEntry = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("data", newFile);

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);

        });

        var newBaseData = toBase64(newFile);
        const newBaseContent = {};
        (async () => {
            newBaseContent.data = await newBaseData;
            newBaseContent.fileName = fileName;
            newBaseContent.type = fileType;
            newBaseContent.location = 'uploadedreport';
            newBaseContent.userID = userInfo.userID;
            newBaseContent.userRole = userInfo.userRole;
            newBaseContent.companyID = userInfo.companyID;
            console.log(newBaseContent)
            const body = {
                "url": "http://127.0.0.1:3022/upload/uploadFile",
                "requestBody": newBaseContent
            }
            axios.post("/upload/uploadFile", newBaseContent, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data) {
                        setPublisherReport(publisherReport => ({ ...publisherReport, fileName: fileName }));
                    }
                    else {
                        ref.current.value = "";
                        toastr.error(res.data.message)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("e", err)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                });
        })();
    };

    const publisherRUpload = (e) => {
        console.log(publisherReport)
        if (publisherReport.fileName) {
            setLoader(true)
            axios.post("/v2.0/RE/PublihserReport/Revenue/uploadDisplay", publisherReport, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data) {
                        if (res.data) {
                            toastr.success('Publisher Report Uploaded Successfully..')
                            setInputKey(Math.random().toString(36))
                            fileNameEntry = ''

                        } else {
                            toastr.error(res.data.message)
                            if (res.data && res.data.DownloadfileName) {
                                setDownloadFileBtn(true)
                                setDownloadUrl(res.data.DownloadfileName)
                            }
                            setInputKey(Math.random().toString(36))
                            fileNameEntry = ''
                        }
                    } else {
                        toastr.error(res.data)
                        setInputKey(Math.random().toString(36))
                        fileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    setInputKey(Math.random().toString(36))
                    fileNameEntry = ''
                    console.log("e", err)
                    toastr.error('File Upload error')
                });
        }
    }

    const agencyFileChangepublisherReport = (file) => {
        setAgencyDownloadFileBtn(false)
        if (file.target.files[0].name) {
            setLoader(true)
        } else {
            setLoader(false)
        }
        console.log(file.target.files[0])
        const fileName = file.target.files[0].name
        agencyFileNameEntry = file.target.files[0].name
        const fileType = file.target.files[0].type
        console.log("fileName=====================", fileName, file)

        const formData = new FormData();
        let newFile = file.target.files[0];
        formData.append("file", newFile);
            axios.post("/uploadXslx", formData, {
                headers: {
                    "Content-Type":"multipart/form-data",
                    'Accept': '*/*',
                    "Authorization": `Bearer ${userInfo.token}`
                }
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data) {
                        setAgencyReport(agencyReport => ({ ...agencyReport, fileName: fileName }));
                    }
                    else {
                        ref.current.value = "";
                        toastr.error(res.data.message)
                        setAgencyInputKey(Math.random().toString(36))
                        agencyFileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("e", err)
                    setAgencyInputKey(Math.random().toString(36))
                    agencyFileNameEntry = ''
                });
    };

    const agencyReportUpload = (e) => {
        console.log(agencyReport)
        if (agencyReport.fileName) {
            setLoader(true)
            const bodyReport = {
                "url": "http://127.0.0.1:3333/v1.0/processData",
                "requestBody": agencyReport
            }
            axios.post("/v2.0/OP", bodyReport, {
                headers: headers
            })
                .then((res) => {
                    console.log("publisherReport Upload ==>", res)
                    setLoader(false)
                    if (res.data) {
                        if (res.data) {
                            toastr.success('Agency Report Uploaded Successfully..')
                            setAgencyInputKey(Math.random().toString(36))
                            agencyFileNameEntry = ''

                        } else {
                            toastr.error(res.data.message)
                            if (res.data && res.data.DownloadfileName) {
                                setAgencyDownloadFileBtn(true)
                                setAgencyDownloadUrl(res.data.DownloadfileName)
                            }
                            setAgencyInputKey(Math.random().toString(36))
                            agencyFileNameEntry = ''
                        }
                    } else {
                        toastr.error(res.data)
                        setAgencyInputKey(Math.random().toString(36))
                        agencyFileNameEntry = ''
                    }
                })
                .catch((err) => {
                    setLoader(false)
                    setAgencyInputKey(Math.random().toString(36))
                    agencyFileNameEntry = ''
                    console.log("e", err)
                    toastr.error('File Upload error')
                });
        }
    }

    var currentTimeStamp = Date.now()

    const downloadCSV = async (url, name) => {
        window.location.assign(url);
    };


    //Agency Report Upload


    // let formData = new FormData();

    const fileChangeAgency = async (e) => {
        // console.log(e.target.files[0])

        // const file = await e.target.files[0];

        // formData.append("file", file);

    }







    return (
        <React.Fragment>
            {/* <Sidebar />
            <Header />
            <div className="main-content"> */}
            <div className="page-content">
                <MetaTags>
                    <title>Report Upload | Bidsxchange</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Report Upload" breadcrumbItem="Report Upload" />
                        <div className="">
                            {loader &&
                                <Spinner className="ms-2 loader" color="primary" />
                            }
                            <div className="row mt-4">
                                 <div className="col-md-6" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges.ReportUploading && UserRolePrivileges.ReportUploading.isPublihserReportAccess) ? 'block' : 'none' }}>
                                    <Card>
                                        <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                            Publisher Data Upload (Display/Video)
                                        </CardTitle>
                                        <CardBody>

                                            <Input className="form-control" type="file" key={inputKey || ''} id="formFile" ref={ref} onChange={fileChangepublisherReport} accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                            <div className="col-12  ">
                                                <div className="row justify-content-center mt-2 ">
                                                    <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={publisherRUpload} disabled={fileNameEntry === ''}>Upload</Button> &nbsp;&nbsp;
                                                    {downloadFileBtn &&
                                                        <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={() => downloadCSV(downloadUrl, 'Error File-' + currentTimeStamp + '.csv')}>Download Error File</Button>
                                                    }
                                                </div>
                                            </div>

                                            <hr className="hr" />
                                            <span className=" mt-3 mb-1 row justify-content-center">
                                                Upload Report in Excel format (.xlsx file)
                                            </span>
                                            <Row className="justify-content-center text-center">
                                                <Link to={downloadtemplate} target="_blank" className=" color-dark-blue" download="Publisher_Data_Upload_Template.xlsx" style={{ width: 'fit-content' }}>
                                                    Download Template for Data Upload
                                                </Link>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div> 

                                <div className="col-md-6" style={{ display: (userInfo.userRole === 'Root' || userInfo.userRole === 'SuperAdmin') ? 'block' : (UserRolePrivileges.ReportUploading && UserRolePrivileges.ReportUploading.isAgencyReportUploadAccess) ? 'block' : 'none' }}>
                                    <Card>
                                        <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                        Agency Data Upload 
                                        </CardTitle>
                                        <CardBody>

                                            <Input className="form-control" type="file" key={agencyInputKey || ''} id="formFile" ref={agencyRef} onChange={agencyFileChangepublisherReport} accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                            <div className="col-12  ">
                                                <div className="row justify-content-center mt-2 ">
                                                    <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={agencyReportUpload} disabled={agencyFileNameEntry === ''}>Upload</Button> &nbsp;&nbsp;
                                                    {agencyDownloadFileBtn &&
                                                        <Button color="primary" className="font-16 btn-block btn btn-primary w-25" onClick={() => downloadCSV(agencyDownloadUrl, 'Error File-' + currentTimeStamp + '.csv')}>Download Error File</Button>
                                                    }
                                                </div>
                                            </div>

                                            <hr className="hr" />
                                            <span className=" mt-3 mb-1 row justify-content-center">
                                                Upload Report in Excel format (.xlsx file)
                                            </span>
                                            <Row className="justify-content-center text-center">
                                                <Link to={downloadAgencytemplate} target="_blank" className=" color-dark-blue" download="Agency_Data_Upload_Template.xlsx" style={{ width: 'fit-content' }}>
                                                    Download Template for Data Upload
                                                </Link>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                                {/* <div className="col-md-6">
                                    <Card>
                                        <CardTitle className="mt-0 card-header bg-transparent border-bottom">
                                            Delivery Status Data Upload
                                        </CardTitle>
                                        <CardBody>
                                            <Input className="form-control" type="file" id="formFile" />
                                            <div className="col-12  ">
                                                <div className="row justify-content-center mt-2 ">
                                                    <Button color="primary" className="font-16 btn-block btn btn-primary w-25">Upload</Button>
                                                </div>
                                            </div>
                                            <hr className="hr" />
                                            <span className=" mt-3 mb-1 row justify-content-center">
                                                Upload Agency Delivery Status in Excel format (.xlsx file)
                                            </span>
                                            <Row className="justify-content-center text-center">
                                                <Link to="#" className=" color-dark-blue" style={{ width: 'fit-content' }}>
                                                    Download Template for Delivery Status
                                                </Link>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div> */}
                            </div>
                        </div>
                </Container>
            </div>
            {/* </div> */}

        </React.Fragment >
    )
}

export default ReportUpload
