import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CREATIVES,ADD_CREATIVE , UPDATE_CREATIVE,READ_CREATIVE,READ_VIDEO_CREATIVE,STATUS_CREATIVE,ARCHIVE_CREATIVE,ADD_VIDEO_CREATIVE,UPDATE_VIDEO_CREATIVE} from "./actionTypes"

import {
  loadCreative,
  noDataCreative,
  getCreativeSuccess,
  getCreativeFail,
  addCreativeSuccess,
  addCreativeFail,
  updateCreativeSuccess,
  updateCreativeFail,
  readCreativeSuccess,
  statusCreativeSuccess,
  archiveCreativeSuccess,
  addVideoCreativeSuccess,
  addVideoCreativeFail,
  updateVideoCreativeSuccess,
  updateVideoCreativeFail,
  readVideoCreativeSuccess

} from "./actions"

//Include Both Helper File with needed methods
import { getCreative,getCreativeByVideoLineItem,createCreative,updateCreative,readCreative,readVideoCreative,statusChangeCreative,statusChangeVideoCreative,videoArchiveCreative,archiveCreative,createVideoCreative,updateVideoCreative } from "../../../helpers/Backend_helper"

function* fetchCreatives({ payload: adv }) {
  try {
    yield put(loadCreative(true))
    var getCreativeList
    if(adv && adv.lineItemID){
      getCreativeList = yield call(getCreativeByVideoLineItem,adv)
      getCreativeList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getCreativeList.response.length === 0){
      yield put(noDataCreative(true))
    }
      yield put(getCreativeSuccess(getCreativeList.response))
    }else if(adv && adv.lineItemID === ''){
      delete adv.lineItemID
      getCreativeList = yield call(getCreative,adv)
      getCreativeList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getCreativeList.response.length === 0){
      yield put(noDataCreative(true))
    }
      yield put(getCreativeSuccess(getCreativeList.response))
    }else {
      getCreativeList = []
      yield put(getCreativeSuccess(getCreativeList))
    }
    console.log(getCreativeList)
  } catch (error) {
    yield put(getCreativeFail(error))
  }
}

function* onReadCreative({ payload: creative }) {
  try {
    if(creative){
      const response = yield call(readCreative, creative)
      if(response.success){
        yield put(readCreativeSuccess(response.response))
      }
      else{
        yield put(readCreativeSuccess(response))
      }
    }
    else{
      yield put(readCreativeSuccess({}))
    }
  } catch (error) {
    yield put(readCreativeSuccess(error))
  }
}

function* onReadVideoCreative({ payload: creative }) {
  try {
    const response = yield call(readVideoCreative, creative)
    if(response.success){
      yield put(readVideoCreativeSuccess(response.response))
    }
    else{
      yield put(readVideoCreativeSuccess(response))
    }
  } catch (error) {
    yield put(readVideoCreativeSuccess(error))
  }
}

function* onAddCreative({ payload: creative }) {
  try {
    const response = yield call(createCreative, creative)
    if(response.success){
      yield put(addCreativeSuccess(response))
    }else{
      yield put(addCreativeFail(response))
    }
  } catch (error) {
    yield put(addCreativeFail(error))
  }
}

function* onUpdateCreative({ payload: creative }) {
  try {
    const response = yield call(updateCreative, creative)
    if(response.success){
      yield put(updateCreativeSuccess(response))
    }else{
      yield put(updateCreativeFail(response))
    }
  } catch (error) {
    yield put(updateCreativeFail(error))
  }
}

function* onAddVideoCreative({ payload: creative }) {
  try {
    const response = yield call(createVideoCreative, creative)
    if(response.success){
      yield put(addVideoCreativeSuccess(response))
    }else{
      yield put(addVideoCreativeFail(response.message))
    }
  } catch (error) {
    yield put(addVideoCreativeFail(error))
  }
}

function* onUpdateVideoCreative({ payload: creative }) {
  try {
    const response = yield call(updateVideoCreative, creative)
    if(response.success){
      yield put(updateVideoCreativeSuccess(response))
    }else{
      yield put(updateVideoCreativeFail(response.message))
    }
  } catch (error) {
    yield put(updateVideoCreativeFail(error))
  }
}


function* onStatusCreative({ payload: creative }) {
  try {
    if(creative.creativeType === 'video'){
      delete creative.creativeType
      const response = yield call(statusChangeVideoCreative, creative)
      yield put(statusCreativeSuccess(response))
    }else{
      delete creative.creativeType
      const response = yield call(statusChangeCreative, creative)
      yield put(statusCreativeSuccess(response))
    }
  } catch (error) {
    yield put(statusCreativeSuccess(error))
  }
}

function* onArchiveCreative({ payload: creative }) {
  try {
    if(creative.creativeType === 'video'){
      delete creative.creativeType
      const response = yield call(videoArchiveCreative, creative)
      yield put(archiveCreativeSuccess(response))
    }else{
      delete creative.creativeType
      const response = yield call(archiveCreative, creative)
      yield put(archiveCreativeSuccess(response))
    }
  } catch (error) {
    yield put(archiveCreativeSuccess(error))
  }
}

function* creativeSaga() {
  yield takeEvery(GET_CREATIVES, fetchCreatives)
  yield takeEvery(ADD_CREATIVE, onAddCreative)
  yield takeEvery(UPDATE_CREATIVE, onUpdateCreative)
  yield takeEvery(ADD_VIDEO_CREATIVE, onAddVideoCreative)
  yield takeEvery(UPDATE_VIDEO_CREATIVE, onUpdateVideoCreative)
  yield takeEvery(READ_CREATIVE, onReadCreative)
  yield takeEvery(READ_VIDEO_CREATIVE, onReadVideoCreative)
  yield takeEvery(STATUS_CREATIVE, onStatusCreative)
  yield takeEvery(ARCHIVE_CREATIVE, onArchiveCreative)
}

export default creativeSaga;
