/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewAdUnit as onADDAdUnit,
  updateAdUnit as onUpdateAdUnit,
  readAdUnit as onReadAdUnit,
  addAdUnitSuccess,
  addAdUnitFail,
  updateAdUnitSuccess,
  updateAdUnitFail,
} from "store/Supply/AdUnit/actions";

import {
  getPartnersList as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import {
  getAdUnitGroup as onGetAdUnitGroups,
} from "store/Supply/AdUnitGroup/actions";

import {
  getCommonSizes as onGetSizes,
} from "store/CommonEvents/actions";
import Toaster from "pages/Toaster/Toaster";


const Create_Publisher_AdUnit = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [adUnit, setAdUnit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [adUnitGroupData, setAdUnitGroupData] = useState([])
  const [sizeData, setSizeData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedAdUnitGroup, setSelectedAdUnitGroup] = useState(null);
  const [selectedDealType, setSelectedDealType] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log(props)
  const [AMPState, setAMPState] = useState(false)

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  var dealTypeOptions = [
    { value: 'RevShare', label: 'RevShare' },
    { value: 'CPM', label: 'CPM' },
    { value: 'Fixed Spot', label: 'Fixed Spot' },
  ]

  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
    dispatch(onGetAdUnitGroups(null));
  }, [])

  useEffect(() => {
    if (props.selectedAdUnit !== '') {
      setIsEdit(true)
      setAdUnit(props.selectedAdUnit)
      setSelectedDealType({ value: props.selectedAdUnit.adUnitDealType, label: props.selectedAdUnit.adUnitDealType });
      setSelectedSize({ value: props.selectedAdUnit.adUnitSize, label: props.selectedAdUnit.adUnitSize });
      getParentAdUnit()
    }
    if (props.selectedAdUnitGroup !== '') {
      getParentAdUnit()
    }
  }, []);

  const getParentAdUnit = (publisherId,adUnitGroupId) => {
    var filterBody = {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID
    }
    if (props.selectedAdUnit !== '') {
      filterBody.adUnitGroupID = props.selectedAdUnit.adUnitGroupID
    }
    if (props.selectedAdUnitGroup !== '') {
      filterBody.adUnitGroupID = props.selectedAdUnitGroup.adUnitGroupId
    }
    if(publisherId){
      filterBody.publisherID = publisherId
    }
    if(adUnitGroupId){
      filterBody.adUnitGroupID = adUnitGroupId
    }
    axios.post("/v2.0/partnerOp/supply/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedPublisher({ value: res.data.publisherID, label: res.data.publisherName });
          setSelectedPartner({ value: res.data.partnerID, label: res.data.username });
          if(!publisherId){
            setSelectedAdUnitGroup({ value: res.data.adUnitGroupID, label: res.data.adUnitGroupName });
          }
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      adUnitName: (adUnit && adUnit.adUnitName) || '',
      partnerID: (selectedPartner ? selectedPartner.value : '') || '',
      publisherID: (selectedPublisher ? selectedPublisher.value : '') || '',
      adUnitGroupID: (selectedAdUnitGroup ? selectedAdUnitGroup.value : '') || '',
      adUnitPricing: (adUnit && adUnit.adUnitPricing) || 0,
      adUnitData: {},
      adUnitDealType: (selectedDealType ? selectedDealType.value : '') || '',
      adUnitType: props.selectedAdUnitType || props.selectedAdUnit.adUnitType,
      adUnitSize: (selectedSize ? selectedSize.value : '') || '',
    },
    validationSchema: Yup.object({
      adUnitName: Yup.string().required("AdUnit Name is required"),
      partnerID: Yup.string().required("Partner is required"),
      publisherID: Yup.string().required("Publisher is required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      console.log(values)
      if (props.selectedAdUnit !== '') {
        dispatch(onUpdateAdUnit({ ...values, adUnitID: props.selectedAdUnit.adUnitID }));
      } else {
        dispatch(onADDAdUnit(values));
      }
    }
  });

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.adUnits.errorMsg,
    successMsg: state.adUnits.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addAdUnitSuccess(""));
        dispatch(addAdUnitFail(""));
        dispatch(updateAdUnitSuccess(""));
        dispatch(updateAdUnitFail(""));
        setLoader(false)
        if (nextTab && validation.values.adUnitType === 'highImpact') {
          props.closeCanvas('')
          nextRef.current.click()
        } else {
          if (props.selectedAdUnit !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    console.log(errorMsg)
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
    }
  }, [errorMsg]);

  const { partners, publishers, adUnitGroups, sizes } = useSelector(state => ({
    partners: state.partners.partnerList,
    publishers: state.publishers.publisherList,
    adUnitGroups: state.adUnitGroups.adUnitGroupsList,
    sizes: state.commonEvents.sizeList
  }));

  console.log(partners, publishers, adUnitGroups, sizes)

  useEffect(() => {
    if (publishers !== null && adUnitGroups !== null && sizes !== null) {
      dispatch(onGetPublishers({ partnerID: '', filter: 'Active',type: props.selectedAdUnitType || props.selectedAdUnit.adUnitType}));
      dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: props.selectedAdUnitType || props.selectedAdUnit.adUnitType }));
      dispatch(onGetSizes());
    }
  }, []);

  useEffect(() => {
    if (partners !== null) {
        dispatch(onGetPartners({type: props.selectedAdUnitType || props.selectedAdUnit.adUnitType}));
    }
}, []);

  useEffect(() => {
    if (partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
  }, [publishers]);

  useEffect(() => {
    if (adUnitGroups.length > 0) {
      setAdUnitGroupData(objectAdUnitGroupModal(adUnitGroups))
      console.log(adUnitGroups)
    }
  }, [adUnitGroups]);

  useEffect(() => {
    if (sizes.length > 0) {
      if (props.selectedAdUnitType === 'play' || props.selectedAdUnitType === 'highImpact') {
        setSizeData([{ value: '1x1', label: '1x1' }])
        setSelectedSize({ value: '1x1', label: '1x1' })
      } else {
        setSizeData(objectSizesModal(sizes))
      }
      console.log(sizes)
    }
  }, [sizes]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.userID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  const objectAdUnitGroupModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.adUnitGroupID
      obj.label = item.adUnitGroupName
      dataList.push(obj)
    })
    return dataList
  }

  const objectSizesModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item
      obj.label = item
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setAdUnit(validation.values)
    setSelectedPartner(selectPartner);
    dispatch(onGetPublishers({ partnerID: selectPartner.value, filter: 'Active' ,type: props.selectedAdUnitType || props.selectedAdUnit.adUnitType}));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    getParentAdUnit(selectPublisher.value,'')
    setAdUnit(validation.values)
    setSelectedPublisher(selectPublisher);
    dispatch(onGetAdUnitGroups({ publisherID: selectPublisher.value, filter: 'Active', adUnitGroupType: props.selectedAdUnitType || props.selectedAdUnit.adUnitType }));
    setSelectedAdUnitGroup(null)
  }

  function handleSelectAdUnitGroup(selectAdUnitGroup) {
    getParentAdUnit('',selectAdUnitGroup.value)
    setAdUnit(validation.values)
    setSelectedAdUnitGroup(selectAdUnitGroup);
  }

  function handleSelectDealType(selectDealType) {
    setAdUnit(validation.values)
    setSelectedDealType(selectDealType);
  }

  function handleSelectSize(selectSize) {
    setAdUnit(validation.values)
    setSelectedSize(selectSize);
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  const AMPStateChange = e => {
    setAMPState(e.target.checked)
  }

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label>Partner</Label>
                <Select
                  value={selectedPartner}
                  onChange={s => {
                    handleSelectPartner(s)
                  }}
                  options={partnerData}
                  invalid={
                    validation.touched.partnerID && validation.errors.partnerID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.partnerID && validation.errors.partnerID ? (
                  <FormFeedback type="invalid">{validation.errors.partnerID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>Publisher</Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  invalid={
                    validation.touched.publisherID && validation.errors.publisherID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.publisherID && validation.errors.publisherID ? (
                  <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label>Ad Unit Group</Label>
                <Select
                  value={selectedAdUnitGroup}
                  onChange={s => {
                    handleSelectAdUnitGroup(s)
                  }}
                  options={adUnitGroupData}
                  invalid={
                    validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.adUnitGroupID && validation.errors.adUnitGroupID ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitGroupID}</FormFeedback>
                ) : null}

              </div>
              <div className="mb-3">
                <Label className="form-label">Ad Unit Name</Label>
                <Input
                  name="adUnitName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  maxLength={60}
                  value={validation.values.adUnitName || ""}
                  invalid={
                    validation.touched.adUnitName && validation.errors.adUnitName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.adUnitName && validation.errors.adUnitName ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitName}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label >AMP : </Label>
                <div className="form-check form-switch form-check-success form-switch-md mb-3 mt-2  col-4" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizemd"
                    checked={AMPState}
                    onChange={AMPStateChange}
                    disabled={props.selectedArchived}
                  />
                  <label className="form-check-label" htmlFor="customSwitchsizemd">
                    {AMPState ? <span color='text-success' style={{ color: '#34c38f' }}>Enabled </span> : <span style={{ color: '#a3a3a3' }}>Disabled</span>}
                  </label>
                </div>
                </div>
              <div className="mb-3">
                <Label className="form-label">Ad Unit Pricing</Label>
                <Input
                  name="adUnitPricing"
                  type="number" min="0" onKeyPress={preventMinus}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.adUnitPricing}
                  invalid={
                    validation.touched.adUnitPricing && validation.errors.adUnitPricing ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.adUnitPricing && validation.errors.adUnitPricing ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitPricing}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label>Ad Unit Deal Type</Label>
                <Select
                  value={selectedDealType}
                  onChange={s => {
                    handleSelectDealType(s)
                  }}
                  options={dealTypeOptions}
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
              </div>
              <div className="mb-3">
                <Label>Ad Unit Size</Label>
                <Select
                  value={selectedSize}
                  onChange={s => {
                    handleSelectSize(s)
                  }}
                  options={sizeData}
                  invalid={
                    validation.touched.adUnitSize && validation.errors.adUnitSize ? true : false
                  }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived}
                />
                {validation.touched.adUnitSize && validation.errors.adUnitSize ? (
                  <FormFeedback type="invalid">{validation.errors.adUnitSize}</FormFeedback>
                ) : null}

              </div>
            </Col>
          </Row>
          <Row style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
                &nbsp;&nbsp;
                {!isEdit && validation.values.adUnitType === 'highImpact' &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                    onClick={() => setNextTab(true)}
                  >
                    Save & Add Child Ad Unit
                  </button>
                }
                {nextTab && successMsg &&
                  <Link ref={nextRef} to={`/ChildAdUnit?adUnitID=${successMsg.data.adUnitID}&adUnitName=${successMsg.data.adUnitName}&status=ChildAdUnitCreateByAdUnit`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' &&
          <Toaster status="error" msg={errorMsg} />
        }
      </div>
    </React.Fragment>
  );
};

export default withRouter(Create_Publisher_AdUnit);

Create_Publisher_AdUnit.propTypes = {
  history: PropTypes.object,
};