{/*import React, { useState, useEffect } from "react";

function DigitalClock() {
    const [time, setTime] = useState(new Date().toLocaleTimeString());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <h6 className="text-primary">{time}</h6>
        </div>
    );
}

export default DigitalClock;*/}

import React, { useState, useEffect } from "react";
import moment from "moment";
// 
import ReactTooltip from "react-tooltip";

function DigitalClock() {
    const [time, setTime] = useState(new Date().toLocaleTimeString('en-US', {
        timeZone: 'America/New_York',
      }));
    const [date, setDate] = useState(
        new Date()
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date().toLocaleTimeString('en-US', {
                timeZone: 'America/New_York',
              }));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <h2 style={{ marginBottom: '3%', color: '#303d4e',textAlign:'center',marginTop:'3%' }}>{moment(date).format('DD:MM:YYYY ')} </h2>
            <ReactTooltip
                anchorSelect="#time-title"
                place="top"
                variant="info"
                content="All Dates and Times are in EST TimeZone"
              />
        </div>

    );
}

export default DigitalClock;

