/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import axios from "axios";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

//Notifications 
import toastr from "toastr";
import "toastr/build/toastr.min.css";

// actions
import {
  addNewPartner as onADDPartner,
  updatePartner as onUpdatePartner,
  addPartnerSuccess,
  addPartnerFail,
  updatePartnerSuccess,
  updatePartnerFail,
} from "store/PartnerManagement/actions";
import PasswordStrengthIndicator from "pages/Authentication/PasswordStrengthIndicator";
import Toaster from "pages/Toaster/Toaster";
import { data } from "jquery";

var countriesList = {}

const Account_Info = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  // const [partner, setPartner] = useState({
  //   password: ''
  // });
  const [isEdit, setIsEdit] = useState(false);
  const [regionList, setRegionList] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [form10F, setForm10F] = useState('')
  const [NPE, setNPE] = useState('')
  const [TRC, setTRC] = useState('')
  const [loader, setLoader] = useState(false);
  const [forcePassword, setForcePassword] = useState(false);

  const [partner, setPartner] = useState({
    userlog: {
      userID: userInfo.userID,
      userRole: userInfo.userRole,
      companyID: userInfo.companyID,
    },
    partnerPaymentMode: '',
    partnerBeneficiaryAccountNo: '',
    partnerBankIFSOrSwiftCode: '',
    partnerBankName: '',
    partnerBeneficiaryName: '',
    partnerBeneficiaryAddress: '',
    partnerBankAddress: '',
    partnerBankBrachName: '',
    partnerOtherInfo: '',
    partnerPANTAXID: '',
    partnerGSTNo: '',
    partnerCIN: '',
    partnerCountry: '',
    partnerFORM10F: { name: '' },
    partnerNoPermanentEstablishment: { name: '' },
    partnerTaxResidencyCertificate: { name: '' },
  });

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }



  toastr.options = {
    positionClass: "toast-top-center",
    closeButton: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 5000,
    extendedTimeOut: 1000
  };

  useEffect(() => {
    let partnerBody = {
      "userlog": {
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
      },
      userID: userInfo.userID,
      partnerName: userInfo.name
    }
    axios.post("/v1.0/read/partnerBank/" + userInfo.userID, partnerBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("result is Partner ==>", res)
        if (res.data.data) {
          setPartner(partner => ({ ...partner, ...res.data.data }));
          setSelectedCountry(res.data.data.partnerCountry)
        }
      })
      .catch((err) => {
        console.log("e", err)
      });

  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newKey = name;
    setPartner(partner => ({ ...partner, [newKey]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(partner)
    if (partner.partnerPaymentMode && partner.partnerBeneficiaryAccountNo && partner.partnerBeneficiaryAddress && partner.partnerBankIFSOrSwiftCode && partner.partnerBankName && partner.partnerBankAddress && partner.partnerPANTAXID && partner.partnerGSTNo && partner.partnerCIN) {
      console.log("this If condition==============");
      setLoader(true)
      axios.post("/v1.0/updatePartner/Bypartner/" + userInfo.userID, partner,
        {
          headers: headers
        })
        .then((res) => {
          console.log("result is Partner ==>", res)
          if (res.data) {
            toastr.success('Profile is Updated Successfully!..')
          }
        })
        .catch((err) => {
          console.log("e", err)
        });
    }
  }

  return (
    <Row >
      <Form onSubmit={handleSubmit}>
        <Row className="mb-1" style={{ padding: '1%', height: '50vh', overflow: 'auto', }}>
          <Col xl={6} >

            <div className="mb-3">
              <Label className="form-label"> Payment Mode <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Input
                name="partnerPaymentMode"
                type="select"
                onChange={handleChange}
                value={partner.partnerPaymentMode}
                className={'form-control form-select' + (submitted && !partner.partnerPaymentMode ? ' is-invalid' : '')}
              >
                <option value=''>Select</option>
                <option value='Wire'>Wire</option>
                <option value='Paypal'>Paypal</option>
              </Input>
              {submitted && !partner.partnerPaymentMode &&
                <div className="invalid-feedback"> Payment Mode is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> Beneficiary Name <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                type="text"
                name="partnerBeneficiaryName"
                onChange={handleChange}
                value={partner.partnerBeneficiaryName}
                className={'form-control ' + (submitted && !partner.partnerBeneficiaryName ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerBeneficiaryName &&
                <div className="invalid-feedback">Beneficiary Name  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> Beneficiary Address <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                type="text"
                name="partnerBeneficiaryAddress"
                onChange={handleChange}
                value={partner.partnerBeneficiaryAddress}
                className={'form-control ' + (submitted && !partner.partnerBeneficiaryAddress ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerBeneficiaryAddress &&
                <div className="invalid-feedback">Beneficiary Address is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> Bank Name <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                type="text"
                name="partnerBankName"
                onChange={handleChange}
                value={partner.partnerBankName}
                className={'form-control ' + (submitted && !partner.partnerBankName ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerBankName &&
                <div className="invalid-feedback">Bank Name  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label">Bank Account No <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                name="partnerBeneficiaryAccountNo"
                type="text"
                onChange={handleChange}
                value={partner.partnerBeneficiaryAccountNo}
                className={'form-control ' + (submitted && !partner.partnerBeneficiaryAccountNo ? ' is-invalid' : '')}
              >
              </Input>
              {submitted && !partner.partnerBeneficiaryAccountNo &&
                <div className="invalid-feedback">Bank Account No  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label">Bank Branch Name  </Label>
              <Input
                name="partnerBankBrachName"
                type="text"
                onChange={handleChange}
                value={partner.partnerBankBrachName}
                className={'form-control '}
              >
              </Input>
            </div>
            <div className="mb-3">
              <Label className="form-label">Bank {selectedCountry === 'India' ? 'IFSC' : 'Swift'} Code <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                name="partnerBankIFSOrSwiftCode"
                type="text"
                onChange={handleChange}
                value={partner.partnerBankIFSOrSwiftCode}
                className={'form-control ' + (submitted && !partner.partnerBankIFSOrSwiftCode ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerBankIFSOrSwiftCode &&
                <div className="invalid-feedback">Bank {selectedCountry === 'India' ? 'IFSC' : 'Swift'} Code is required</div>
              }
            </div>

            <div className="mb-3">
              <Label className="form-label"> Bank Address <span style={{ color: 'red', fontSize: '15px' }}>*</span> </Label>
              <Input
                type="text"
                name="partnerBankAddress"
                onChange={handleChange}
                value={partner.partnerBankAddress}
                className={'form-control ' + (submitted && !partner.partnerBankAddress ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerBankAddress &&
                <div className="invalid-feedback">Bank Address  is required</div>
              }
            </div>

            <div className="mb-3">
              <Label className="form-label"> PAN/TAX ID <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Input
                type="text"
                name="partnerPANTAXID"
                onChange={handleChange}
                value={partner.partnerPANTAXID}
                className={'form-control ' + (submitted && !partner.partnerPANTAXID ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerPANTAXID &&
                <div className="invalid-feedback">PAN/TAX ID  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> GSTNo <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Input
                name="partnerGSTNo"
                type="text"
                onChange={handleChange}
                value={partner.partnerGSTNo}
                className={'form-control ' + (submitted && !partner.partnerGSTNo ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerGSTNo &&
                <div className="invalid-feedback">GSTNo  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> Company Identification Number(CIN) <span style={{ color: 'red', fontSize: '15px' }}>*</span></Label>
              <Input
                name="partnerCIN"
                type="number"
                onChange={handleChange}
                value={partner.partnerCIN}
                className={'form-control ' + (submitted && !partner.partnerCIN ? ' is-invalid' : '')}
              />
              {submitted && !partner.partnerCIN &&
                <div className="invalid-feedback">Company Identification Number  is required</div>
              }
            </div>
            <div className="mb-3">
              <Label className="form-label"> Other Information</Label>
              <Input
                type="text"
                name="partnerOtherInfo"
                onChange={handleChange}
                value={partner.partnerOtherInfo}
              />
            </div>
            {/* <div className="mb-3">
    <Label> Country</Label>
    <Select
      value={selectedCountry}
      onChange={s => {
        handleSelectRegion(s)
      }}
      options={regionList}
      classNamePrefix="select2-selection"
    />
  </div> */}

          </Col>
        </Row>

        <Col sm={12}>
          <div className="text-sm-center mt-3">
            <button
              type="submit"
              className="btn btn-primary save-user"
            >
              Update Profile
            </button>
          </div>
        </Col>
      </Form>
    </Row>
  )

}

Account_Info.propTypes = {}

export default Account_Info