/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"; 
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback,  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown, Spinner} from "reactstrap";
import Select from "react-select";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewIOGroup as onADDIOGroup,
  updateIOGroup as onUpdateIOGroup,
  addIOGroupSuccess,
  addIOGroupFail,
  updateIOGroupSuccess,
  updateIOGroupFail,
} from "store/Demand/IOGroup/actions";

import {
  getAdvertisers as onGetAdvertisers,
} from "store/Demand/Advertiser/actions";
import Toaster from "pages/Toaster/Toaster";
import ToasterWarning from "pages/Toaster/ToasterWarning";


const Create_IO_Group = props => {

  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [iOGroup, setIOGroup] = useState();
  const [advertiserData, setAdvertiserData] = useState([])
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [btnprimary1, setBtnprimary1] = useState(false)
  const [iOSType,setIOSType] = useState('');
  const [loader, setLoader] = useState(false);
  console.log(props)
  var advertiserID = ''

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
}


  useEffect(() => {
    if (props.selectedIOGroup !== '') {
      setIsEdit(true)
      setIOGroup(props.selectedIOGroup)
      getParentIOG()
    }
    if(props.selectedAdvertiser !== '' && props.selectedIOGroup === ''){
      setIOGroup({advertiserID:props.selectedAdvertiser.advertiserId})
      setSelectedAdvertiser({value: props.selectedAdvertiser.advertiserId,label:props.selectedAdvertiser.advertiserName});
    }
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      iOGroupName: (iOGroup && iOGroup.iOGroupName) || '',
      advertiserID: (selectedAdvertiser ? selectedAdvertiser.value : '')  || '',
    },
    validationSchema: Yup.object({
      iOGroupName: Yup.string().required("IOGroup Name is required"),
      advertiserID: Yup.string().required("Advertiser is Required"),
    }),
    onSubmit: (values) => {
      setLoader(true)
      console.log(values)
      if (props.selectedIOGroup !== '') {
        dispatch(onUpdateIOGroup({ ...values, iOGroupID: props.selectedIOGroup.iOGroupID,iOGroupName:values.iOGroupName.trim(), userRole: userInfo.userRole, userID: userInfo.userID}));
      } else {
        console.log(values,advertiserID)
        dispatch(onADDIOGroup({...values,iOGroupName:values.iOGroupName.trim(), userRole: userInfo.userRole, userID: userInfo.userID}));
      }
    }
  });

  const { advertisers } = useSelector(state => ({
    advertisers: state.advertisers.advs,
  }));

  const getParentIOG = () =>{
    var filterBody = {
          "advertiserID": 0,
          "iOGroupID":0,
          "iOID": 0,
          "lineItemID": 0,
          "creativeID": 0,
          "videoCreativeID":0,
          userID: userInfo.userID,
          userRole: userInfo.userRole,
          companyID: userInfo.companyID,
          name:userInfo.name
  }
  if(props.selectedIOGroup !== ''){
      filterBody.iOGroupID = props.selectedIOGroup.iOGroupID
  }
  axios.post("/v2.0/OP/demand/getParent", filterBody,
      {
          headers: headers
      })
      .then((res) => {
          console.log("cdsresult is ==>", res)
          if (res.data) {
            setSelectedAdvertiser({label:res.data.advertiserName,value:res.data.advertiserID});
          }
      })
      .catch((err) => {
          console.log("e", err)
      });
  }

  console.log(advertisers)

  useEffect(() => {
    if (advertisers !== null) {
      dispatch(onGetAdvertisers({filter:'Active', userRole: userInfo.userRole, userID: userInfo.userID}));
    }
  }, []);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.advertiserID
      obj.label = item.advertiserName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (advertisers && advertisers.length > 0) {
      setAdvertiserData(objectModal(advertisers))
      console.log(advertisers)
    }
  }, [advertisers]);

  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.iOGroups.errorMsg,
    successMsg: state.iOGroups.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addIOGroupSuccess(""));
        dispatch(addIOGroupFail(""));
        dispatch(updateIOGroupSuccess(""));
        dispatch(updateIOGroupFail(""));
        setLoader(false)
        if(nextTab){
          props.closeCanvas('')
          nextRef.current.click()
        }else{
          if (props.selectedIOGroup !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addIOGroupFail(""));
      dispatch(updateIOGroupFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addIOGroupFail(""));
    dispatch(updateIOGroupFail(""));
        props.closeCanvas('')
  };


  function handleSelectAdvertiser(selectAdvertiser) {
    console.log(selectAdvertiser)
    setSelectedAdvertiser(selectAdvertiser);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  return (
    <React.Fragment>
     {loader &&
              <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-6" style={{pointerEvents:(loader) ? 'none' : 'unset'}}>
        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
          <Row  style={{ padding: '3%' }}>
            <Col xs={12}>
              <div className="mb-3">
                <Label>Advertiser</Label>
                <Select
                  value={selectedAdvertiser}
                  onChange={s => {
                    handleSelectAdvertiser(s)
                  }}
                  options={advertiserData}
                  invalid={
                    validation.touched.advertiserID && validation.errors.advertiserID ? true : false
                  }
                  className={ (validation.touched.advertiserID && validation.errors.advertiserID ? ' is-invalid' : '') }
                  classNamePrefix="select2-selection"
                  isDisabled={props.selectedArchived || isEdit}
                />
                  {validation.touched.advertiserID && validation.errors.advertiserID ? (
                  <FormFeedback type="invalid">{validation.errors.advertiserID}</FormFeedback>
                ) : null}
              
              </div>
              <div className="mb-3">
                <Label className="form-label">IO Group Name</Label>
                <Input
                  name="iOGroupName"
                  type="text"
                  maxLength={65}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  onKeyPress={allowAlphaNumericSpace}
                  value={validation.values.iOGroupName || ""}
                  invalid={
                    validation.touched.iOGroupName && validation.errors.iOGroupName ? true : false
                  }
                  readOnly={props.selectedArchived}
                />
                {validation.touched.iOGroupName && validation.errors.iOGroupName ? (
                  <FormFeedback type="invalid">{validation.errors.iOGroupName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '20px', margin: 0 }}>
            <Col>
              <div className="text-start">
              &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                  &nbsp;&nbsp;
              {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
              
                &nbsp;&nbsp;
                {!isEdit &&
                   <div className="btn-group">
                   <Dropdown
                     isOpen={btnprimary1}
                     toggle={() => setBtnprimary1(!btnprimary1)}
                   >
                     <DropdownToggle tag="button" type="button" className="btn btn-primary">
                       <i className="mdi mdi-plus-circle-outline me-1" />Save & Add Insertion Order &nbsp; <i className="mdi mdi-chevron-down" />
                     </DropdownToggle>
                     <DropdownMenu>
                       {/* <DropdownItem header>Display</DropdownItem> */}
                       <button type="submit" onClick={() => {setNextTab(true) ; setIOSType('stdb')}} style={{border:'none',background:'none',margin:'0 15% 3%'}}>Standard</button>
                       <br />
                      <button type="submit" onClick={() => {setNextTab(true) ; setIOSType('highImpact')}} style={{border:'none',background:'none',margin:'0 15% 3%'}}>High Impact</button>
                       {/* <DropdownItem divider />
                       <DropdownItem header>Video</DropdownItem> */}
                       <button type="submit" onClick={() => {setNextTab(true) ; setIOSType('video')}} style={{border:'none',background:'none',margin:'0 15% 3%'}}>Video</button>
                       {/* <br />
                       <button type="submit" onClick={() => {setNextTab(true) ; setIOSType('video')}} style={{border:'none',background:'none',margin:'0 15% 3%'}} disabled>Vast</button> */}
                     </DropdownMenu>
                   </Dropdown>
                 </div>
                }
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {nextTab &&  successMsg &&
                 <Link ref={nextRef} to={`/Insertion_Order?iOGroupID=${successMsg.data.iOGroupID}&iOGroupName=${successMsg.data.iOGroupName}&iOType=${iOSType}&status=IOCreateByIOGroup`}  > </Link>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(Create_IO_Group);

Create_IO_Group.propTypes = {
  history: PropTypes.object,
};