/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Label, Form, FormFeedback, Spinner, Collapse, Button } from "reactstrap";
import Select from "react-select";
import axios from "axios";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// actions
import {
  addNewTc as onADDTc,
  updateTc as onUpdateTc,
  readTc as onReadTc,
  addTcSuccess,
  addTcFail,
  updateTcSuccess,
  updateTcFail,
} from "store/Demand/TagController/actions";

import {
  getAdUnitTc as onGetAdUnits,
} from "store/Supply/AdUnit/actions";

import {
  getPartners as onGetPartners,
} from "store/PartnerManagement/actions";

import {
  getPublisher as onGetPublishers,
} from "store/Supply/Publisher/actions";

import Toaster from "pages/Toaster/Toaster";
import classnames from "classnames";
import ToasterWarning from "pages/Toaster/ToasterWarning";

var total = 0
var totalPercentage = 0

const New_TagController = props => {
  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const nextRef = useRef();
  const [nextTab, setNextTab] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tCName, setTCName] = useState('')
  const [adUnitData, setAdUnitData] = useState([])
  const [selectedAdUnit, setSelectedAdUnit] = useState(null);
  const [itemOpen, setItemOpen] = useState('')
  const [controller, setController] = useState({
    Tag_Controller_URLsToBlock: ''
  })
  const [blockUrls, setBlockUrls] = useState([])
  const [tagList, setTagList] = useState([
    {
      weightage: "1",
      tag: '',
      tagLabel: 'Tag1',
      percentage: 100,
      passbackTags: [
        {
          passbackTag: '',
          size: '728x90'
        },
        {
          passbackTag: '',
          size: '320x50'
        },
      ]
    }
  ])
  console.log(props)

  const [tCOptions, setTCOptions] = useState({
    partnerID:'',
    publisherID:'',
    tagControllerName:'',
    tagControllerPlayerID:'',
    tagControllerVideoPlayerTag:'',
    tagControllerBidSrvDisplayTag:'',
    tagControllerPlayerType:'Aniview'
  })

  const headers = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    "Authorization": `Bearer ${userInfo.token}`
  }

  const [partnerData, setPartnerData] = useState([])
  const [publisherData, setPublisherData] = useState([])
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  useEffect(() => {
    dispatch(onGetPartners(null));
    dispatch(onGetPublishers(null));
  }, [])

  const getParentAdUnitGroup = (publisherId) => {
    var filterBody = {
        "partnerID": 0,
        "publisherID": 0,
        "adUnitGroupID": 0,
        "adUnitID": 0,
        "childAdUnitID": 0,
        "playerSettingID": 0,
        userID: userInfo.userID,
        userRole: userInfo.userRole,
        companyID: userInfo.companyID,
        name:userInfo.name
    }
    if (publisherId) {
      filterBody.publisherID = publisherId
    }
    axios.post("/v2.0/OP/supply/getParent", filterBody,
      {
        headers: headers
      })
      .then((res) => {
        console.log("cdsresult is ==>", res)
        if (res.data) {
          setSelectedPublisher({ value: res.data.publisherID, label: res.data.publisherName });
          setSelectedPartner({ value: res.data.partnerID, label: res.data.username });
          setTCOptions(tCOptions => ({ ...tCOptions, publisherID: res.data.publisherID,partnerID: res.data.partnerID }));
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  const { partners } = useSelector(state => ({
    partners: state.partners.partners,
  }));

  const { publishers } = useSelector(state => ({
    publishers: state.publishers.publisherList,
  }));

  useEffect(() => {
    if (partners !== null) {
      // dispatch(onGetPartners({type:props.selectedAdUnitGroupType}));
      dispatch(onGetPartners({filter:'Active'}));
    }
  }, []);

  useEffect(() => {
    if (publishers !== null) {
      console.log(publishers)
      // dispatch(onGetPublishers({partnerID:'',filter:'Active',type:props.selectedAdUnitGroupType}));
      dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '' }));
    }
  }, []);

  useEffect(() => {
    if (partners && partners.length > 0) {
      setPartnerData(objectModal(partners))
      console.log(partners)
    }
  }, [partners]);

  useEffect(() => {
    if (publishers && publishers.length > 0) {
      setPublisherData(objectPublisherModal(publishers))
      console.log(publishers)
    }
    if (publishers && publishers.length === 0) {
      setPublisherData([])
    }
  }, [publishers]);

  const objectModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.partnerID
      obj.label = item.userName
      dataList.push(obj)
    })
    return dataList
  }

  const objectPublisherModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.publisherID
      obj.label = item.publisherName
      dataList.push(obj)
    })
    return dataList
  }

  function handleSelectPartner(selectPartner) {
    setTCOptions(tCOptions => ({ ...tCOptions, partnerID: selectPartner.value }));
    console.log(selectPartner)
    setSelectedPartner(selectPartner);
    dispatch(onGetPublishers({ partnerID: selectPartner.value, filter: 'Active', type: '' }));
    setSelectedPublisher(null)
  }

  function handleSelectPublisher(selectPublisher) {
    setTCOptions(tCOptions => ({ ...tCOptions, publisherID: selectPublisher.value }));
    getParentAdUnitGroup(selectPublisher.value)
    setSelectedPublisher(selectPublisher);
  }

  const handleChangeOptions = (e) => {
    const { name, value } = e.target;
    setTCOptions(tCOptions => ({ ...tCOptions, [name]: value }));
  }

  const { adUnits } = useSelector(state => ({
    adUnits: state.adUnits.adUnitListbyTC,
  }));

  console.log(adUnits)

  const openCollapsed = (panel) => (event, newExpanded) => {
    console.log(panel)
    setItemOpen(panel)
  };

  const objectAdUnitModal = (response) => {
    var dataList = []
    response.map((item, index) => {
      let obj = {}
      obj.value = item.AdUnitID
      obj.label = item.AdUnitName
      dataList.push(obj)
    })
    return dataList
  }

  useEffect(() => {
    if (adUnits !== null) {
      dispatch(onGetAdUnits({ filter: '' }));
    }
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setController(controller => ({ ...controller, [name]: value }));
    let urls = value.split(/[\s,]+/);
    console.log(urls)
    setBlockUrls(urls)
  }

  const handleChangeTag = (index) => (e) => {
    const { name, value } = e.target;
    let newArr = [...tagList]
    newArr[index].tag = value
    setTagList(newArr)
  }

  const passbackTagChange = (CtgIndex, index) => e => {
    const { name, value } = e.target;
    let mainArr = [...tagList]
    let newArr = [...tagList[CtgIndex].passbackTags];
    newArr[index].passbackTag = value
    console.log(mainArr)
    mainArr[CtgIndex].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  useEffect(() => {
    if (props.selectedTc !== '') {
      setIsEdit(true)
      setTCOptions(props.selectedTc)
      getParentAdUnitGroup(props.selectedTc.publisherID)
    }
  }, []);


  const { errorMsg, successMsg } = useSelector(state => ({
    errorMsg: state.tcs.errorMsg,
    successMsg: state.tcs.successMsg,
  }));

  console.log(errorMsg, successMsg)

  useEffect(() => {
    if (successMsg !== null) {
      if (successMsg.success) {
        dispatch(addTcSuccess(""));
        dispatch(addTcFail(""));
        dispatch(updateTcSuccess(""));
        dispatch(updateTcFail(""));
        setLoader(false)
        if (nextTab) {
          props.closeCanvas('')
          nextRef.current.click() 
        } else {
          if (props.selectedTc !== '') {
            props.closeCanvas('Update')
          } else {
            props.closeCanvas('Create')
          }
        }
      }
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg !== null && errorMsg !== '') {
      setLoader(false)
      if(errorMsg.status && errorMsg.status === 500){
      }else{
        dispatch(addTcSuccess(""));
        dispatch(addTcFail(""));
        dispatch(updateTcSuccess(""));
        dispatch(updateTcFail(""));
      }
    }
  }, [errorMsg]);

  const toggleToast = (n) => (e) => {
    dispatch(addTcSuccess(""));
    dispatch(addTcFail(""));
    dispatch(updateTcSuccess(""));
    dispatch(updateTcFail(""));
        props.closeCanvas('')
  };

  const handleSubmit = async (e) => {
    console.log(tCOptions)
    e.preventDefault();
    setSubmitted(true);
    if ( tCOptions.partnerID && tCOptions.publisherID && tCOptions.tagControllerName && tCOptions.tagControllerPlayerID && tCOptions.tagControllerVideoPlayerTag && tCOptions.tagControllerBidSrvDisplayTag && tCOptions.tagControllerPlayerType ) {
      if (props.selectedTc !== '') {
        dispatch(onUpdateTc({ ...tCOptions, tcID: props.selectedTc.tagControllerID }));
      } else {
        dispatch(onADDTc({ ...tCOptions }));
      }

    }
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };


  const contentChangeTag = index => e => {
    let newArr = [...tagList]
    newArr[index].weightage = parseInt(e.target.value);
    setTagList(newArr)
    changePercentage(newArr)
  }

  const blurChangeTag = index => e => {
    console.log("input blurred")
    let newArr = [...tagList]
    if (parseInt(e.target.value) < 1) {
      newArr[index].weightage = 1;
    } else {
      newArr[index].weightage = parseInt(e.target.value);
    }
    setTagList(newArr);
    changePercentage(newArr)
  }

  const changePercentage = (data) => {
    console.log(data)
    total = 0
    data.map((item, index) => {
      if (Number.isNaN(parseInt(item.weightage)) || parseInt(item.weightage) < 1) {
        total = total + 1
      } else {
        total = total + parseInt(item.weightage)
      }
    })
    totalPercentageChange(data)
  }

  const tagMaxChange = (data) => {
    console.log(data)
    var maxId = data.reduce(function (maxId, item) {
      return Math.max(maxId, item.percentage)
    }, Number.MIN_VALUE);
    data.map((item, index) => {
      let newArr = [...data];
      if (totalPercentage >= 100) {
        if (item.percentage === maxId) {
          console.log(item.percentage)
          newArr[index].percentage = maxId - (totalPercentage - 100);
          console.log(newArr)
          setTagList(newArr);
        }
      }
      else if (item.percentage >= 99) {
        newArr[index].percentage = item.percentage - 2;
      }
      else if (item.percentage === 0) {
        newArr[index].percentage = 1;
        totalPercentage = totalPercentage + 1
      }
    })
  }

  const tagMinChange = (data) => {
    let newArr = [...data];
    if (totalPercentage === 99) {
      newArr[0].percentage = newArr[0].percentage + 1
      console.log(newArr)
      setTagList(newArr);
    }
    tagMaxChange(newArr)
  }

  const totalPercentageChange = (data) => {
    totalPercentage = 0
    data.map((item, index) => {
      if (item.weightage < 1) {
        item.percentage = Math.floor(((1 / total) * 100), 6);
      } else {
        item.percentage = Math.floor(((item.weightage / total) * 100), 6);
      }
      totalPercentage = totalPercentage + item.percentage
    })
    console.log(data)
    let newArr = [...data];
    setTagList(newArr)
    tagMinChange(newArr)
  }

  function handleSelectAdUnit(selectAdUnit) {
    setSelectedAdUnit(selectAdUnit);
  }


  const addNewPasssBackData = (index) => e => {
    const newPassBackData = {
      passbackTag: '',
      size: ''
    }
    let mainArr = [...tagList]
    let newArr = [...tagList[index].passbackTags];
    newArr.push(newPassBackData);
    mainArr[index].creativePresent = false
    mainArr[index].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  const addNewTag = () => {

    const newTag = {
      weightage: 1,
      tag: '',
      tagLabel: "Tag" + (tagList.length  + 1),
      percentage: '',
      passbackTags: [
        {
          passbackTag: '',
          size: '728x90'
        },
        {
          passbackTag: '',
          size: '320x50'
        },
      ]
    }
    setTagList([...tagList, newTag])
    changePercentage([...tagList, newTag])
  }

  const removeTags = (index) => (e) => {
    let newArr = [...tagList];
    newArr.splice(index, 1);
    setTagList(newArr);
    changePercentage(newArr)
  }

  const removePassBackTag = (gindex, i) => (e) => {
    let mainArr = [...tagList]
    let newArr = [...tagList[gindex].passbackTags];
    newArr.splice(i, 1);
    mainArr[gindex].passbackTags = [...newArr]
    setTagList(mainArr);
  }

  function allowAlphaNumericSpace(e) {
    var code = ('charCode' in e) ? e.charCode : e.keyCode;
    if ( !(code > 31 && code < 33) && !(code > 39 && code < 42) && !(code > 44 && code < 58) && !(code > 64 && code < 91) && !(code > 94 && code < 123) && !(code > 188 && code < 191)) { e.preventDefault();}
  };

  useEffect(() => {

    const formdata = new FormData();
    // formdata.append("key", "87e595fe8d98e4957a1c4c8a892a4915");
    formdata.append("txt", "Balagokulam is the biggest children’s movement in Kerala today. Kesari, which started a column for children under the caption “Balagokulam” way back in the 1950s,had played an important role in the formation of Balagokulam. Tapasya, an art and literary forum, also owes its birth to Kesari .In 2005, Kesari instituted two awards for journalists: 1. Rashtra Seva Puraskaram- to a senior journalist for lifetime achievement.2. Raghaveeyam Puraskaram- to a novice in the print/visual media. It was in the 1970s, the Swayamsevaks all over Kerala started enlisting subscribers for Kesari in a systematic way. Ever since, in November every year, Swayamsevaks are engaging themselves in the subscription drive, and during the 60th year of the weekly, they could achieve the target of enlisting 60000 subscribers. During the subscription drive in November 2012, the number of subscribers has risen to almost 70000, the maximum in the history of Kesari.Kesari has digitised all its issues, right from the first issue to the last issue of December 2010, and the CDs are available in the market for RS. 1500/-. Kesari’s new office at Chalappuram was inaugurated by Param Poojaneeya Sarsanghachalak Dr. Mohanji Bhagwat on December 2020. The Kesari Bhavan comprises Mahatma Gandhi College of Mass Communication, a center for media research, with a state – of – the – art digital library ,conference hall and many more facilities.");
    formdata.append("sentences", 8);
    
    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
     fetch("https://api.meaningcloud.com/summarization-1.0", requestOptions)
      .then(response => ({
        status: response.status, 
        body: response.json()
      }))
      .then(({ status, body }) => console.log(status, body))
      .catch(error => console.log('error', error));









//   let bodyMonth = {
//       "url": "http://en.wikipedia.org/wiki/Automatic_summarization",
//       "text": "",
//       "sentnum": 8
// }
// axios.get('https://meaningcloud-summarization-v1.p.rapidapi.com/summarization-1.0', 
//     {
//       params: {sentences: '5', url: 'http://en.wikipedia.org/wiki/Star_Trek'},
//       headers: {
//         Accept: 'application/json',
//         'X-RapidAPI-Key': '83bc690497mshb24779dd48acbb6p16a574jsn8f77f7e59d0d',
//         'X-RapidAPI-Host': 'meaningcloud-summarization-v1.p.rapidapi.com'
//       }
//     })
//     .then((res) => {
//         console.log("result is Invoices ==>", res)
//     })
//     .catch((err) => {
//         console.log("e", err)
//     });
  }, []);

  return (
    <React.Fragment>
      {loader &&
        <Spinner className="ms-2 loader" color="primary" />
      }
      <div className="col-12" style={{ pointerEvents: (loader) ? 'none' : 'unset' }}>

        <Form onSubmit={handleSubmit}>
          <Row style={{ padding: '1% 3%', height: '80vh', overflow: 'auto', width: '100%' }}>
            <Col xs={8}>
            <div className="mb-3">
                <Label>Partner</Label>
                <Select
                  value={selectedPartner}
                  onChange={s => {
                    handleSelectPartner(s)
                  }}
                  options={partnerData}
                  classNamePrefix="select2-selection"
                />

              </div>
              <div className="mb-3">
                <Label>Publisher</Label>
                <Select
                  value={selectedPublisher}
                  onChange={s => {
                    handleSelectPublisher(s)
                  }}
                  options={publisherData}
                  classNamePrefix="select2-selection"
                />

              </div>
              <div className="mb-3">
                <Label className="form-label">Tag Controller Name</Label>
                <Input
                  name="tagControllerName"
                  type="text"
                  maxLength={65}
                  value={tCOptions.tagControllerName}
                  onKeyPress={allowAlphaNumericSpace}
                  onChange={handleChangeOptions}
                  className={'form-control' + (submitted && !tCOptions.tagControllerName ? ' is-invalid' : '')}
                />
                {submitted && !tCOptions.tagControllerName ? (
                  <FormFeedback type="invalid">{'Tag Controller Name is required'}</FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Video Player Type</Label>
                <Input type="select" name="tagControllerPlayerType" onChange={handleChangeOptions} value={tCOptions.tagControllerPlayerType}
                  className={'form-control form-select' + (submitted && !tCOptions.tagControllerPlayerType ? ' is-invalid' : '')} readOnly={props.selectedArchived} >
                  {/* <option value=''> Select...</option> */}
                  <option value='Aniview'> Aniview</option>
                </Input>
                {submitted && !tCOptions.tagControllerPlayerType ? (
                  <FormFeedback type="invalid">{'Video Player Type is required'}</FormFeedback>
                ) : null}
              </div>
                <div className="mb-3">
                  <Label className="form-label">Player ID</Label>
                  <Input
                    name="tagControllerPlayerID"
                    type="text"
                    value={tCOptions.tagControllerPlayerID}
                    onChange={handleChangeOptions}
                    className={'form-control' + (submitted && !tCOptions.tagControllerPlayerID ? ' is-invalid' : '')}
                    readOnly={props.selectedArchived}
                  />
                  {submitted &&  !tCOptions.tagControllerPlayerID ? (
                    <FormFeedback type="invalid">{'Player ID is required'}</FormFeedback>
                  ) : null}
                </div>
              <div className="mb-3">
                <Label className="form-label">Video Player Tag</Label>
                <Input
                  name="tagControllerVideoPlayerTag"
                  type="textarea"
                  id="textarea"
                  rows="3"
                  value={tCOptions.tagControllerVideoPlayerTag}
                  onChange={handleChangeOptions}
                  className={'form-control' + (submitted && !tCOptions.tagControllerVideoPlayerTag ? ' is-invalid' : '')}
                />
                  {submitted &&  !tCOptions.tagControllerVideoPlayerTag ? (
                    <FormFeedback type="invalid">{'Video Player Tag is required'}</FormFeedback>
                  ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">BIDSERV Display Tag</Label>
                <Input
                  name="tagControllerBidSrvDisplayTag"
                  type="textarea"
                  id="textarea"
                  rows="3"
                  value={tCOptions.tagControllerBidSrvDisplayTag}
                  className={'form-control' + (submitted && !tCOptions.tagControllerBidSrvDisplayTag ? ' is-invalid' : '')}
                  onChange={handleChangeOptions}
                />
                        {submitted &&  !tCOptions.tagControllerBidSrvDisplayTag ? (
                    <FormFeedback type="invalid">{'BIDSERV Display Tag is required'}</FormFeedback>
                  ) : null}
              </div>

            </Col>
          </Row>
          <Row className="drawer-footer"  style={{ position: 'absolute', bottom: 0, borderTop: '1px solid #9e9e9e', width: '100%', padding: '15px', margin: 0 }}>
            <Col>
              <div className="text-start">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  // eslint-disable-next-line react/prop-types
                  onClick={props.closeCanvas}
                >
                  Back
                </button>
                &nbsp;&nbsp;
                {isEdit && !props.selectedArchived &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Update
                  </button>
                }
                {!isEdit &&
                  <button
                    type="submit"
                    className="btn btn-primary save-user"
                  >
                    Save
                  </button>
                }
              </div>
            </Col>
          </Row>
        </Form>
        {errorMsg !== null && errorMsg !== '' && !errorMsg.status &&
            <Toaster status="error" msg={errorMsg.message}/>
        }
      </div>
        {errorMsg !== null && errorMsg !== '' &&  errorMsg.status && errorMsg.status === 500 && 
            <div className="p-0 justify-content-center d-flex">
            <ToasterWarning msg={errorMsg} closeWarning={toggleToast('')}/>
          </div>
        }
    </React.Fragment>
  );
};

export default withRouter(New_TagController);

New_TagController.propTypes = {
  history: PropTypes.object,
};