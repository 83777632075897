import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CHILDADUNITS,ADD_CHILDADUNIT , UPDATE_CHILDADUNIT,READ_CHILDADUNIT,STATUS_CHILDADUNIT,ARCHIVE_CHILDADUNIT} from "./actionTypes"

import {
  loadActivitiesChildAdUnit,
  noDataChildAdUnit,
  getChildAdUnitsSuccess,
  getChildAdUnitsFail,
  addChildAdUnitSuccess,
  addChildAdUnitFail,
  updateChildAdUnitSuccess,
  updateChildAdUnitFail,
  readChildAdUnitSuccess,
  statusChildAdUnitSuccess,
  archiveChildAdUnitSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getChildAdUnit,getChildAdUnitsByAdUnit,createChildAdUnit,updateChildAdUnit,readChildAdUnit,statusChangeChildAdUnit,archiveChildAdUnit } from "../../../helpers/Backend_helper"

function* fetchChildAdUnits({ payload: childAdUnit }) {
  try {
    yield put(loadActivitiesChildAdUnit(true))
    var getChildAdList
    if(childAdUnit && childAdUnit.adUnitID !== ''){
      getChildAdList = yield call(getChildAdUnitsByAdUnit,childAdUnit)
      getChildAdList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getChildAdList.response.length === 0){
      yield put(noDataChildAdUnit(true))
    }
      yield put(getChildAdUnitsSuccess(getChildAdList.response))
    }else if(childAdUnit && childAdUnit.adUnitID === ''){
      delete childAdUnit.adUnitID
      getChildAdList = yield call(getChildAdUnit,childAdUnit)
      getChildAdList.response.map((item, index) => {
        item.id = index + 1
    })
    if(getChildAdList.response.length === 0){
      yield put(noDataChildAdUnit(true))
    }
      yield put(getChildAdUnitsSuccess(getChildAdList.response))
    }else {
      getChildAdList = []
      yield put(getChildAdUnitsSuccess(getChildAdList))
    }
  } catch (error) {
    yield put(getChildAdUnitsFail(error))
  }
}

function* onReadChildAdUnit({ payload: childAdUnit }) {
  try {
    const response = yield call(readChildAdUnit, childAdUnit)
    yield put(readChildAdUnitSuccess(response))
  } catch (error) {
    yield put(readChildAdUnitSuccess(error))
  }
}

function* onAddChildAdUnit({ payload: childAdUnit }) {
  try {
    const response = yield call(createChildAdUnit, childAdUnit)
    if(response.success){
      yield put(addChildAdUnitSuccess(response))
    }else{
      yield put(addChildAdUnitFail(response))
    }
  } catch (error) {
    yield put(addChildAdUnitFail(error))
  }
}

function* onUpdateChildAdUnit({ payload: childAdUnit }) {
  try {
    const response = yield call(updateChildAdUnit, childAdUnit)
    if(response.success){
      yield put(updateChildAdUnitSuccess(response))
    }else{
      yield put(updateChildAdUnitFail(response))
    }
  } catch (error) {
    yield put(updateChildAdUnitFail(error))
  }
}

function* onStatusChildAdUnit({ payload: childAdUnit }) {
  try {
    const response = yield call(statusChangeChildAdUnit, childAdUnit)
    yield put(statusChildAdUnitSuccess(response))
  } catch (error) {
    yield put(statusChildAdUnitSuccess(error))
  }
}

function* onArchiveChildAdUnit({ payload: childAdUnit }) {
  try {
    const response = yield call(archiveChildAdUnit, childAdUnit)
    yield put(archiveChildAdUnitSuccess(response))
  } catch (error) {
    yield put(archiveChildAdUnitSuccess(error))
  }
}

function* childAdUnitSaga() {
  yield takeEvery(GET_CHILDADUNITS, fetchChildAdUnits)
  yield takeEvery(ADD_CHILDADUNIT, onAddChildAdUnit)
  yield takeEvery(UPDATE_CHILDADUNIT, onUpdateChildAdUnit)
  yield takeEvery(READ_CHILDADUNIT, onReadChildAdUnit)
  yield takeEvery(STATUS_CHILDADUNIT, onStatusChildAdUnit)
  yield takeEvery(ARCHIVE_CHILDADUNIT, onArchiveChildAdUnit)
}

export default childAdUnitSaga;
