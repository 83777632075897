import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { withRouter,useHistory } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";
import axios from "axios";

import logo from "../../assets/images/Bids.svg";
import logosmall from "../../assets/images/Crest.svg";
import moment from "moment";
import { APICallRequest } from "helpers/Demand_API_Helper";
import queryString from 'query-string';


import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  Modal,
  ModalBody,
  Input,
  Spinner,
  UncontrolledPopover,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label
} from "reactstrap"

var warningTimeCounter = false

const timeout = 10000_000
const promptBeforeIdle = 40_000

//redux
import { useSelector, useDispatch } from "react-redux";

// import {
//   callAPIChange as onCallAPIChange,
//   getAdvertisers as onGetAdvertisers,
// } from "store/Demand/Advertiser/actions";

// import {
//   getIOs as onGetIOs,
// } from "store/Demand/IOs/actions";

// import {
//   getIOGroups as onGetIOGroups,
// } from "store/Demand/IOGroup/actions";

// import {
//   getLineItem as onGetLineItem,
// } from "store/Demand/LineItem/actions";

// import {
//   getCreative as onGetCreative,
// } from "store/Demand/Creative/actions";

// import {
//   getAdUnitGroup as onGetAdUnitGroups,
// } from "store/Supply/AdUnitGroup/actions";

// import {
//   getAdUnit as onGetAdUnits,
// } from "store/Supply/AdUnit/actions";

// import {
//   getChildAdUnit as onGetChildAdUnits,
// } from "store/Supply/ChildAdUnit/actions";

// import {
//   getHighImpactSetting as onGetHighImpactSetting,
// } from "store/Supply/HighImpactSettings/actions";

// import {
//   getPlayerSetting as onGetPlayerSettings,
// } from "store/Supply/PlayerSetting/actions";

// import {
//   getPublisher as onGetPublishers,
// } from "store/Supply/Publisher/actions";

// import {
//   getPartners as onGetPartners,
//   getUsers as onGetUsers,
// } from "store/PartnerManagement/actions";

// import {
//   getCategory as onGetCategory,
// } from "store/customTracking/Categories/actions";

// import {
//   getHouseAdCreatives as onGetHouseCreative,
// } from "store/customTracking/Creatives/actions";

// import {
//   getHouseAdUnits as onGetHouseAdUnits,
// } from "store/customTracking/AdUnits/actions";

// import {
//   getAdPublisher as onGetAdPublisher,
// } from "store/customTracking/HouseAdPub/actions";

// import {
//   getProxyAdUnits as onGetProxyAdUnits,
// } from "store/Proxy/ProxyAdUnits/actions";

// import {
//   getBrandAffinitys as onGetBrandAffinitys,
// } from "store/Contextual_Settings/Brand_Affinity/actions";

// import {
//   getInterests as onGetInterests,
// } from "store/Contextual_Settings/Interests/actions";

// import {
//   getKeywords as onGetKeywords,
// } from "store/Contextual_Settings/Keywords/actions";

// import {
//   getPageUrls as onGetPageUrls,
// } from "store/Contextual_Settings/PageUrls/actions";

// import {
//   getWebsiteCategorizations as onGetWebsiteCategorizations,
// } from "store/Contextual_Settings/WebsiteCategorization/actions";

// import {
//   getRoles as onGetRoles,
// } from "store/RoleManagement/actions";

// import {
//   getPub_Notifications as onGetPub_Notifications,
//   getAction_Notifications as onGetAction_Notifications,
// } from "store/Notifications/Publisher_Notification/actions";

// import { getSupportTicketAdmins as onGetSupportTicketsAdmins } from 'store/Support_Ticket/Admin_Ticket/Admin_Support/actions'

// import {
//   getBillHistory as onGetApprovedBillList,
//   getBillHistoryList as onGetBillHistoryList,
// } from "store/Billing/actions";

// import {
//   getLogsMaster as onGetLogsMaster,
//   getLogs as onGetLogs,
// } from "store/Logs/actions";

var estCurrentDate = parseInt(moment().format('HHmm')) < 1030 ? 2 : 1

var fromDate = moment().subtract(estCurrentDate > 1 ? 7 : 6, 'days')
var toDate = moment().subtract(estCurrentDate > 1 ? 1 : 0, 'days')

const Sidebar = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [jsTagModalState, setJsTagModalState] = useState(true);
  const [TCState, setTCState] = useState(false);

  const [timer, setTimer] = useState({ count: 0 })
  const [warningTimer, setWarningTimer] = useState({ count: 1200 })
  const linkRef = useRef();
  const sessionRef = useRef();
  const increment = useRef(null)
  const warningIncrement = useRef(null)
  const [showAlert, setShowAlert] = useState(false);
  const alertStatus = localStorage.getItem('alertStatus');
  const loginTime = JSON.parse(localStorage.getItem('LoginTime'));

  useEffect(async () => {
    // handleStart()
    // handleStartWarning()
    // startSession();
    windowStatus()
    startWindowMonitoring();
  }, [])

  let TimeDifference = Date.now()-loginTime;
  let Timefrom24HoursCheck = (TimeDifference / (1000 * 60 * 60)).toFixed(2);
  // console.log(loginTime,TimeDifference,Timefrom24HoursCheck)
  useEffect(async () => {
    if(Number(Timefrom24HoursCheck) >= 24.02){
        console.log(loginTime,TimeDifference,Timefrom24HoursCheck)
      windowStatus()
    }
  }, [Number(Timefrom24HoursCheck)])

  const handleStart = () => {
    increment.current = setInterval(() => {
      setTimer(prevState => ({
        count: prevState.count + 1
      }))
    }, 1000)
  }

  const handleStartWarning = () => {
    warningIncrement.current = setInterval(() => {
      setWarningTimer(prevState => ({
        count: prevState.count - 1
      }))
    }, 1000)
  }

  const userInfo = JSON.parse(localStorage.getItem('authUser'));
  const UserRolePrivileges = JSON.parse(localStorage.getItem("UserRolePrivileges"));
  const userRole = localStorage.getItem('UserRole');
  const terms = localStorage.getItem('Terms');
  const loginStatus = localStorage.getItem('loginStatus');

  const path = window.location.pathname

  var body = document.body;
 

  if(path === '/login'){
    if(userInfo){
      history.push('/Dashboard');
    }else{
      history.push('/login');
    }
  }

  if(path === '/forgot-password'){
    if(userInfo){
      history.push( '/Dashboard');
    }else{
      history.push('/forgot-password');
    }
  }

  if(userInfo){
    var headers = {
      "Content-Type": "application/json",
      "Accept": 'application/json',
      "Authorization": `Bearer ${userInfo.token ? userInfo.token : ''}`
    }
  }else{
    localStorage.removeItem('authUser');
    history.push('/login');
  }



  useEffect(() => {

    if (!userInfo) {
      localStorage.removeItem('authUser');
      history.push('/login');
    }

  }, [])

  // const startSession = async () => {
  //   try {
  //     setInterval(async () => {
  //       axios.get("/isExpired",
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Accept": 'application/json',
  //             "Authorization": `Bearer ${userInfo.token ? userInfo.token : ''}`
  //           }
  //         })
  //         .then((res) => {
  //           if (res.data.isExpired === true) {
  //             console.log('Token is Dead')
  //             localStorage.setItem("userSessionState", ' Session Timed Out');
  //             localStorage.setItem("previousNavigation", (window.location.pathname + window.location.search));
  //             linkRef.current.click()
  //           }else{
  //             console.log('Token is alive')
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("e", err)
  //         });
  //     }, 120000);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const { apiCallMsg} = useSelector(state => ({
  //   apiCallMsg: state.advertisers.apiCallMsg,
  // }));

  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     if(userInfo){
  //       dispatch(onCallAPIChange());
  //     }
  //   }, 30000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);








  // useEffect(() => {
  //   const parsed = queryString.parse(window.location.search);
  //   console.log(apiCallMsg)



  //   if(path === '/Advertiser' && JSON.stringify(parsed) === '{}'){
  //         dispatch(onGetAdvertisers({ filter: 'Active' }));
  //       }
  //   if(path === '/Insertion_Order_Group' && JSON.stringify(parsed) === '{}'){
  //         dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: '' }));
  //     }
  //   if(path === '/Insertion_Order' && JSON.stringify(parsed) === '{}' ){
  //         dispatch(onGetIOs({ iOGroupID: '', filter: 'Active', iOType: '' }));
  //       }
  //   if(path === '/Line_Items' && JSON.stringify(parsed) === '{}' ){
  //         dispatch(onGetLineItem({ iOID: '', filter: 'Active', lineItemType: '', priority: '',AdUnitID:[] }));
  //       }
  //   if(path === '/Creative' && JSON.stringify(parsed) === '{}' ){
  //         dispatch(onGetCreative({ lineItemID: '', filter: 'Active', creativeType: '', creativeSize: '', creativeTemplateName: '' }));
  //       }
  //   // if((path === '/Partner_Management' || path === '/Supply_Partner')){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetPartners({ filter: 'Active' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetPartners({ filter: 'Active' }));
  //   //     }else{
  //   //       dispatch(onGetPartners({...apiCallMsg[1].data}));
  //   //     }
     
  //   //   }
  //   //   }
  //   // if(path === '/Publisher' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '' }));
  //   //     }else{
  //   //       dispatch(onGetPublishers({...apiCallMsg[1].data}));
  //   //     }
     
  //   //   }
  //   //   }
  //   // if(path === '/AdUnitGroup' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: '' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: '' }));
  //   //     }else{
  //   //       dispatch(onGetAdUnitGroups({...apiCallMsg[1].data}));
  //   //     }

  //   //   }
  //   //   }
  //   // if(path === '/AdUnit' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: '', adUnitSize: '' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: '', adUnitSize: '' }));
  //   //     }else{
  //   //       dispatch(onGetAdUnits({...apiCallMsg[1].data}));
  //   //     }
  
  //   //   }
  //   //   }
  //   // if(path === '/ChildAdUnit' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetChildAdUnits({ adUnitID: '', filter: 'Active', childAdUnitType: '', childAdUnitSize: '' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetChildAdUnits({ adUnitID: '', filter: 'Active', childAdUnitType: '', childAdUnitSize: '' }));
  //   //     }else{
  //   //       dispatch(onGetChildAdUnits({...apiCallMsg[1].data}));
  //   //     }

  //   //   }
  //   //   }
  //   // if(path === '/High_Impact' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetHighImpactSetting({ adUnitID: '', filter: 'Active', }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetHighImpactSetting({ adUnitID: '', filter: 'Active', }));
  //   //     }else{
  //   //       dispatch(onGetHighImpactSetting({...apiCallMsg[1].data}));
  //   //     }
   
  //   //   }
  //   //   }
  //   // if( path === '/Player_Settings' ){
  //   //   if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //   //     dispatch(onGetPlayerSettings({ filter: 'Active' }));
  //   //   }else{
  //   //     if((apiCallMsg && apiCallMsg.length > 0)){
  //   //       dispatch(onGetPlayerSettings({ filter: 'Active' }));
  //   //     }else{
  //   //       dispatch(onGetPlayerSettings({...apiCallMsg[1].data}));
  //   //     }
  
  //   //   }
  //   //   }


  //  }, [ path]);

  // useEffect(() => {
  //   const parsed = queryString.parse(window.location.search);
  //   console.log(apiCallMsg)



  //   if(path === '/Advertiser'){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)  ){
  //       dispatch(onGetAdvertisers({ filter: 'Active' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetAdvertisers({...apiCallMsg[1].data}));
 
  //       }else{
  //         dispatch(onGetAdvertisers({ filter: 'Active' }));
  //       }
    
  //     }
  //     }
  //   if(path === '/Insertion_Order_Group' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0) && apiCallMsg[1].data.advertiserID){
  //         dispatch(onGetIOGroups({...apiCallMsg[1].data}));

  //       }else{
  //         dispatch(onGetIOGroups({ advertiserID: '', filter: 'Active', type: '' }));
  //       }
       
  //     }
  //     }
  //   if(path === '/Insertion_Order' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetIOs({ iOGroupID: '', filter: 'Active', iOType: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetIOs({...apiCallMsg[1].data}));
  //       }else{
  //         dispatch(onGetIOs({ iOGroupID: '', filter: 'Active', iOType: '' }));
  //       }
       
  //     }
  //     }
  //   if(path === '/Line_Items' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetLineItem({ iOID: '', filter: 'Active', lineItemType: '', priority: '',AdUnitID:[] }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetLineItem({...apiCallMsg[1].data}));
  //       }else{
  //         dispatch(onGetLineItem({ iOID: '', filter: 'Active', lineItemType: '', priority: '',AdUnitID:[] }));
  //       }
       
  //     }
  //     }
  //   if(path === '/Creative'){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetCreative({ lineItemID: '', filter: 'Active', creativeType: '', creativeSize: '', creativeTemplateName: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetCreative({...apiCallMsg[1].data}));
  //       }else{
  //         dispatch(onGetCreative({ lineItemID: '', filter: 'Active', creativeType: '', creativeSize: '', creativeTemplateName: '' }));
  //       }
  
  //     }
  //     }
  //   if((path === '/Partner_Management' || path === '/Supply_Partner')){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetPartners({ filter: 'Active' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetPartners({...apiCallMsg[1].data}));

  //       }else{
  //         dispatch(onGetPartners({ filter: 'Active' }));
  //       }
     
  //     }
  //     }
  //   if(path === '/Publisher' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetPublishers({...apiCallMsg[1].data}));
     
  //       }else{
  //         dispatch(onGetPublishers({ partnerID: '', filter: 'Active', type: '' }));
  //       }
     
  //     }
  //     }
  //   if(path === '/AdUnitGroup' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetAdUnitGroups({...apiCallMsg[1].data}));
        
  //       }else{
  //         dispatch(onGetAdUnitGroups({ publisherID: '', filter: 'Active', adUnitGroupType: '' }));
  //       }

  //     }
  //     }
  //   if(path === '/AdUnit' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: '', adUnitSize: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetAdUnits({...apiCallMsg[1].data}));

  //       }else{
  //         dispatch(onGetAdUnits({ adUnitGroupID: '', filter: 'Active', adUnitType: '', adUnitSize: '' }));
  //       }
  
  //     }
  //     }
  //   if(path === '/ChildAdUnit' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetChildAdUnits({ adUnitID: '', filter: 'Active', childAdUnitType: '', childAdUnitSize: '' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetChildAdUnits({...apiCallMsg[1].data}));
      
  //       }else{
  //         dispatch(onGetChildAdUnits({ adUnitID: '', filter: 'Active', childAdUnitType: '', childAdUnitSize: '' }));
  //       }

  //     }
  //     }
  //   if(path === '/High_Impact' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetHighImpactSetting({ adUnitID: '', filter: 'Active', }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetHighImpactSetting({...apiCallMsg[1].data}));

  //       }else{
  //         dispatch(onGetHighImpactSetting({ adUnitID: '', filter: 'Active', }));
  //       }
   
  //     }
  //     }
  //   if( path === '/Player_Settings' ){
  //     if(JSON.stringify(parsed) === '{}' && (apiCallMsg && apiCallMsg.length === 0)){
  //       dispatch(onGetPlayerSettings({ filter: 'Active' }));
  //     }else{
  //       if((apiCallMsg && apiCallMsg.length > 0)){
  //         dispatch(onGetPlayerSettings({...apiCallMsg[1].data}));

  //       }else{
  //         dispatch(onGetPlayerSettings({ filter: 'Active' }));
  //       }
  
  //     }
  //     }





  //   if(apiCallMsg !== null){
  //      if(apiCallMsg.length > 0){
    
  //       console.log(parsed)
  //       console.log(apiCallMsg)
  //       // if(path === '/Advertiser'){
  //       //     dispatch(onGetAdvertisers({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Insertion_Order_Group'){
  //       //   dispatch(onGetIOGroups({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Insertion_Order'){
  //       //   dispatch(onGetIOs({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Line_Items'){
  //       //   dispatch(onGetLineItem({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Creative'){
  //       //   dispatch(onGetCreative({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Partner_Management' || path === '/Supply_Partner'){
  //       //   dispatch(onGetPartners({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/Publisher'){
  //       //   dispatch(onGetPublishers({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/AdUnitGroup'){
  //       //   dispatch(onGetAdUnitGroups({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/AdUnit'){
  //       //   dispatch(onGetAdUnits({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/ChildAdUnit'){
  //       //   dispatch(onGetChildAdUnits({...apiCallMsg[1].data}));
  //       // }
  //       // if(path === '/High_Impact'){
  //       //   dispatch(onGetHighImpactSetting({...apiCallMsg[1].data}));
  //       // }
  //       // if( path === '/Player_Settings'){
  //       //   dispatch(onGetPlayerSettings({...apiCallMsg[1].data}));
  //       // }

  //       if(path === '/Categories'){
  //         dispatch(onGetCategory({...apiCallMsg[1].data}));
  //       }
  //       if(path === '/Creatives'){
  //         dispatch(onGetHouseCreative({...apiCallMsg[1].data}));
  //       }
  //       if(path === '/AdUnits'){
  //         dispatch(onGetHouseAdUnits({...apiCallMsg[1].data}));
  //       }
  //       if(path === '/AdPublishers'){
  //         dispatch(onGetAdPublisher({...apiCallMsg[1].data}));
  //       }
  //       if(path === '/Proxy_AdUnits'){
  //         dispatch(onGetProxyAdUnits({...apiCallMsg[1].data}));
  //       }

  //       if(path === '/Brand_Affinity'){
  //         dispatch(onGetBrandAffinitys());
  //       }
  //       if(path === '/Web_Categorization'){
  //         dispatch(onGetWebsiteCategorizations());
  //       }
  //       if(path === '/Page_URL'){
  //         dispatch(onGetPageUrls());
  //       }
  //       if(path === '/Keywords'){
  //         dispatch(onGetKeywords());
  //       }
  //       if( path === '/Interests'){
  //         dispatch(onGetInterests());
  //       }

  //       if(path === '/Access_Management'){
  //         dispatch(onGetUsers({...apiCallMsg[1].data}));
  //       }
  //       if( path === '/Access_Management'){
  //         dispatch(onGetRoles({...apiCallMsg[1].data}));
  //       }

  //       if(path === '/Notification'){
  //         dispatch(onGetPub_Notifications({...apiCallMsg[1].data}));
  //       }
  //       if( path === '/Publisher_Approval'){
  //         dispatch(onGetAction_Notifications());
  //       }
  //       if( path === '/Support_Tickets'){
  //         let userDetails = {
  //           userID: userInfo.userID
  //         }
  //         dispatch(onGetSupportTicketsAdmins(userDetails))
  //       }
  //       if(path === '/Invoice_History'){
  //         dispatch(onGetApprovedBillList({...apiCallMsg[1].data}));
  //       }
  //       if( path === '/Invoice_Approval' && path === '/Payments'){
  //         dispatch(onGetBillHistoryList({...apiCallMsg[1].data}));
  //       }
  //       if(path === '/Master_Log'){
  //         dispatch(onGetLogsMaster({...apiCallMsg[1].data}));
  //       }
  //       if( path === '/Publisher_Log'){
  //         dispatch(onGetLogs({...apiCallMsg[1].data}));
  //       }
  //      }
  //  }
  //  }, [ apiCallMsg]);



  

  const windowStatus = () => {
    axios.get("/v2.0/AP/isExpired",
    {
      headers: {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        "Authorization": `Bearer ${userInfo.token ? userInfo.token : ''}`
      }
    })
    .then((res) => {
      if (res.data.isExpired === true) {
        console.log('Token is Dead')
        stopWindowMonitoring()
        localStorage.removeItem('authUser');
        history.push('/login');
      }else{
        // console.log('Token is alive')
        // recreateNode(document.getElementById("root"));
        // document.getElementsByTagName("div").addEventListener("click", windowStatus,{once : true});
      }
    })
    .catch((err) => {
      console.log("e", err)
      if(err.response){
        if(err.response.status === 401){
          stopWindowMonitoring()
          localStorage.removeItem('authUser');
          history.push('/login');
        }
      }
      if (err.message === "Request failed with status code 504") {
        history.push("/page-maintenance");
      }
    });
  }



  const windowReStatus = () => {
    localStorage.setItem("loginStatus", 'LoggedInContinue');
    localStorage.setItem("alertStatus", 'closed');
    clearInterval(increment.current)
    setTimer({ count: 0 })
    handleStart()
    setShowAlert(false)
    clearInterval(warningIncrement.current)
    setWarningTimer({ count: 1200 })
    handleStartWarning()
  }

  const windowStop = () => {
    // console.log("event Stopped");
    clearInterval(increment.current)
    setTimer({ count: 0 })
    setWarningTimer({ count: 1200 })
  }

  const handleWindowClose = () => {
    localStorage.setItem("loginStatus", 'LoggedOut');
    localStorage.setItem("alertStatus", 'closed');
    stopWindowMonitoring()
    clearInterval(warningIncrement.current)
    setWarningTimer({ count: 1200 })
    setShowAlert(false)
    localStorage.setItem("userSessionState", ' Session Timed Out');
    localStorage.setItem("previousNavigation", (window.location.pathname + window.location.search));
    linkRef.current.click()
  }

  const startWindowMonitoring = async () => {
      // window.addEventListener("scroll", windowStatus);
      // window.addEventListener("keydown", windowStatus);
      // document.addEventListener("click", windowStatus);
      // // window.addEventListener("mousemove", windowStatus);
      document.getElementById("root").addEventListener("click", windowStatus,{once : true});
  }

  const stopWindowMonitoring = async () => {
    // window.removeEventListener("scroll", windowStop, true);
    // window.removeEventListener("keydown", windowStop, true);
    document.getElementById("root").removeEventListener("click", windowStatus);
    // window.removeEventListener("mousemove", windowStop, true);
  }

  useEffect(() => {
    if(userInfo.userRole === 'Partner'){
      if (userInfo.isAccepted) {
        localStorage.setItem("Terms", 'Accepted');
      } else {
        localStorage.setItem("Terms", 'notAccepted');
      }
    }else{
      localStorage.setItem("Terms", 'Accepted');
    }
  }, []);

  const handleAcceptTC = () => {
    let bodyAccept = {
      "userlog": {
        "userRole": userInfo.userRole,
        "userID": userInfo.userID
      }
    }
    axios.post("/v2.0/AP/updatePartner/terms/Bypartner/" + userInfo.userID, bodyAccept,
      {
        headers: headers
      })
      .then((res) => {
        if (res.data) {
          localStorage.setItem("Terms", 'Accepted');
          setJsTagModalState(false);
          let userDetails = {...userInfo, isAccepted: true }
          localStorage.setItem("authUser", JSON.stringify(userDetails));
        }
      })
      .catch((err) => {
        console.log("e", err)
      });
  }

  // useEffect(() => {
  //   if (timer.count >= 300) {
  //     // handleWindowClose()
  //     localStorage.setItem("loginStatus", 'LoggedIn');
  //     localStorage.setItem("alertStatus", 'opened');
  //     warningTimeCounter = true
  //     stopWindowMonitoring()
  //     setShowAlert(true)
  //   }
  // }, [timer.count]);

  // useEffect(() => {
  //     if(warningTimer.count === 0){
  //       setShowAlert(false)
  //       handleWindowClose()
  //     }

  // }, [warningTimer.count]);

  // if(showAlert){
  //   warningTimeCounter = true
  // }else{
  //   warningTimeCounter = false
  // }

//   useEffect(() => {
//     console.log(loginStatus)
//     if(loginStatus === 'LoggedInContinue'){
//       setShowAlert(false)
//       windowReStatus()
//     }
//     if(loginStatus === 'LoggedOut'){
//       setShowAlert(false)
//       handleWindowClose()
//     }
// }, [loginStatus]);

// useEffect(() => {
//   console.log(alertStatus)
//   if(alertStatus === 'opened'){
//     setWarningTimer({ count: 120 })
//     setShowAlert(true)
//   }
//   if(alertStatus === 'closed'){
//     setShowAlert(false)
//   }
// }, [alertStatus]);





const [state, setState] = useState('Active')
// const [remaining, setRemaining] = useState(timeout)
// const onIdle = () => {
//   setState('Idle')
//   setShowAlert(false)
//   handleWindowClose()
// }

// const onActive = () => {
//   setState('Active')
//   setShowAlert(false)
// }

// const onPrompt = () => {
//   setState('Prompted')
//   setShowAlert(true)
// }

// const { getRemainingTime, activate } = useIdleTimer({
//   onIdle,
//   onActive,
//   onPrompt,
//   timeout,
//   promptBeforeIdle,
//   throttle: 500
// })

// useEffect(() => {
//   const interval = setInterval(() => {
//     setRemaining(Math.ceil(getRemainingTime() / 1000))
//   }, 500)

//   return () => {
//     clearInterval(interval)
//   }
// })

// const handleStillHere = () => {
//   activate()
// }

// const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
// const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  return (
    <React.Fragment>
      <div className="vertical-menu" style={{ background:userRole === 'Agency' ? '#15042d' : userRole === 'Publisher' ? '#34434c' : '#00040e' }}>
        <div className="navbar-brand-box" style={{ background:userRole === 'Agency' ? '#15042d' : userRole === 'Publisher' ? '#34434c' : '#00040e' }}>

          {/* {body.classList.length < 4 && body.classList[1] !== "vertical-collpsed" &&
            <span className="logo logo-lg" style={{cursor: "pointer"}}>
              <img src={logo} alt="" height="28" onClick={()=> history.push("/Dashboard")}/>
            </span>
          }
          {body.classList[1] === "vertical-collpsed" &&
            <span className="logo logo-lg" style={{cursor: "pointer"}}>
              <img src={logosmall} alt="" height="28" onClick={()=> history.push("/Dashboard")}/>
            </span>
          } */}

          <Link to="/Dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logosmall} alt="" height="28" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="28" />
            </span>
          </Link>

          <Link to="/Dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src={logosmall} alt="" height="28" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="28" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
        <Link ref={linkRef} to="/login"></Link>
        <Link ref={sessionRef} to="/locked-screen"></Link>
      </div>
      {/* {(userRole === 'Publisher' || userRole === 'Partner') && terms === 'notAccepted' && userInfo.userRole !== "Root" &&
        <Modal
          size="lg"
          isOpen={jsTagModalState}
        >
          <div className="modal-header">
            <h3 className="modal-title mt-0" id="myModalLabel">
              Terms and Conditions
            </h3>
          </div>
          <div className="modal-body" style={{ overflow: 'auto', height: '70vh' }}>

            <div className="card card-small h-100">
              <div className="jb" id="placeholders" style={{ margin: '5px 50px 10px 50px' }}>
                <h5>Introduction</h5>
                <p>This Publisher Terms and Conditions Agreement (hereinafter the Agreement ) shall govern participation in the Streamlyn online advertising network (the Network). By participating in the Network, you are agreeing to be bound by these Terms and Conditions. The term Publisher shall refer to any individual or entity who accepts the Terms and Conditions of this agreement by signing this insertion order form. Upon approval, Streamlyn will provide to the publisher advertising JS codes (Ad Codes) to allow the Publisher to serve advertisements on its approved websites.</p>

                <h5>Definitions</h5>
                <ul>
                  <li>Ad means any advertisement provided by Media Company on behalf of an Advertiser.</li>
                  <li>Advertiser means the advertiser for which Media Company is the agent under an applicable IO.</li>
                  <li>Advertising Materials means artwork, copy, or active URLs for Ads.</li>
                  <li>Affiliate of any entity means any other entity directly or indirectly controlling, controlled by, or under common control with, such entity.</li>
                  <li>Agency means the advertising agency listed on the applicable IO.</li>
                  <li>Fraud means to directly or indirectly generate queries, or impressions of or clicks on any Ad through any automated, deceptive, fraudulent or other invalid means, including but not limited to through repeated manual clicks, the use of robots or other automated query tools and/or computer generated search requests, and/or the unauthorized use of other search engine optimization services and/or software.</li>
                  <li>Fraudulent means created by Fraud.</li>
                  <li>IO means a mutually agreed insertion order that incorporates these Terms, under which Media Company will deliver Ads on Sites for the benefit of Agency or Advertiser.</li>
                  <li>Media Company means Streamlyn Pte. Ltd.</li>
                  <li>Media Company Properties are websites specified on an IO that are owned, operated, or controlled by Media Company.</li>
                  <li>Network Properties means websites specified on an IO that are not owned, operated, or controlled by Media Company, but on which Media Company has a contractual right to serve Ads.</li>
                  <li>Parties means Streamlyn Pte. Ltd. and the Publisher.</li>
                  <li>Policy means advertising criteria or specifications made conspicuously available, including content limitations, technical specifications, privacy policies, user experience policies, policies regarding consistency with Media Companies public image, community standards regarding obscenity or indecency (taking into consideration the portion(s) of the Site on which the Ads are to appear), other editorial or advertising policies, and Advertising Materials due dates.</li>
                  <li>Publisher means the website from which Media Company is buying traffic from under an applicable IO.</li>
                  <li>Representative of an entity means any director, officer, employee, consultant, contractor, agent, and/or attorney of an entity and/or of its Affiliate(s).
                  </li><li>Site or Sites means Media Company Properties and Network Properties.</li>
                  <li>Terms means these Standard Terms and Conditions for Internet Advertising for Media Buys One Year or Less (Publishers), as they shall be amended from time to time.</li>
                  <li>Third Party means an entity or person that is not a party to an IO; for purposes of clarity, Media Company, Agency, Advertiser, and any Affiliates or Representatives of the foregoing are not Third Parties.</li>
                  <li>Third Party Ad Server means a Third Party that will serve and/or track Ads.</li>
                </ul>

                <h5>Agreement:</h5>
                <p>These Terms together with the IO constitute an agreement between the Parties. It supersedes all prior proposals, agreements, or other communications between the Parties regarding such subject matter. </p>

                <h5>Duration:</h5>
                <p>Publisher will serve the campaign for the length of duration requested, regardless of the date on which the IO is signed or sent. Notwithstanding the aforesaid, either party may stop the campaign by giving 24 hours written notice to the other party.</p>

                <h5>Cancellation and Termination</h5>
                <ul>
                  <li>Without Cause. Unless designated on the IO as non-cancellable, either Publisher or Media Company may cancel the IO, with 24 hour written notice.</li>
                  <li>For Cause. Either Media Company Publisher may terminate an IO at any time if the other party is in material breach of its obligations hereunder, which breach is not cured within 10 days after receipt of written notice thereof from the non-breaching party, except as otherwise stated in these Terms with regards to specific breaches. Additionally, if Agency or Advertiser breaches its obligations by violating the same Policy three times (and such Policy was provided to Agency or Advertiser) and receives timely notice of each such breach, then even if Publisher cures such breaches, then Media Company may terminate the IO or placements associated with such breach immediately upon written notice.</li>
                  <li>In the event that any payment due to the Network from any specific advertiser and/or advertisement agency is delayed for any reason (Delayed Amounts), the Network shall make its best efforts to collect such amounts within 30 days of the date upon which the payment due to the Network from a specific advertiser and/or advertising agency was required to be made (the Collection Period), and shall deliver to Publisher the Monthly Payment within 5 business days as of the date of the collection of such Delayed Amounts.</li>

                  <li>Network may adjust payments to Publisher hereunder for refunds, chargebacks or credits provided to advertisers or ad-exchanges agencies for their advertisements. If the refunds, chargebacks or credits are charged to network after the payments has been made to the publisher for the respective month where there is a chargeback, then the chargeback or refund amount will be adjusted from the publishers revenue for the next consecutive months. Example: If chargeback has appeared in the month of Jan and payments were already made by network to publisher for Jan, then the chargeback amount will be deducted from publishers Feb revenue and will be paid the net off amount.</li>
                </ul>
                <h5> Discrepancies:</h5>
                <p>Unless otherwise agreed on the IO by both Parties, Publisher is responsible for following the campaign numbers and noticing if there are discrepancies. Publisher is fully responsible and agrees to be paid in full per Media Companys numbers.</p>

                <h5> Delivery</h5>
                <ul>
                  <li>Media Company will serve Ads through a Third-Party ad server, and payment shall be based on Media Companies system count. Media Company will provide the Publisher with connection data (login, username and/or password) to their ad server upon request.</li>
                  <li>In case the Media Companys ad server records impressions served by the Publisher as an ad server URL (example – adnxs.com for AppNexus), then such impressions shall not count towards the number of impressions deemed delivered hereunder and Media Company shall not be liable to pay for the same.</li>
                  <li> If Media Company determines that the impressions ordered herein are served incorrectly by the Publisher, in terms of either tag or technical specification, then such impressions shall not count towards the number of impressions deemed delivered hereunder and Media Company shall not be liable to pay for the same.</li>
                  <li>Auto Refresh: If the Publisher uses any type of impression auto-refresh delivery system, the Parties shall discuss whether the use of such system negatively impacts on the commercial benefit for the Advertisers of Media Company and accordingly the Parties shall discuss in good faith and implement changes as necessary to the commercial terms hereof.</li>
                  <li>Shifting: throughout the campaign, Media Company shall have the right to request shifting of ordered impressions not yet received by it to new placements of comparable value, subject to inventory and reasonable discretion.</li>
                  <li>Click Fraud: Media Company shall not be obligated to make any payments arising from any Fraudulent impressions generated by any person, both through automated programs or similar Media Company shall be responsible for determining, in its sole and absolute discretion, what acts and/or omissions violate these Terms, which acts include activity that is deceptive and/or Fraudulent in nature.</li>
                </ul>

                <h5>Reporting</h5>
                <p>Media Company Reporting. Media Company will make reporting available with a log in to the used ad server, either electronically or in writing, unless otherwise specified on the IO. Reports will be broken out by day and summarized by impressions, clicks, conversions, spend/cost, and other variables as may be defined in the IO. Once Media Company has provided the online or electronic report, it agrees that Publisher is entitled to reasonably rely on it, subject to provision of Media Companies invoice for such period.</p>

                <h5>Payments</h5>
                <p>Media Company will pay on Net terms as agreed in the IO. It is the Publishers responsibility to deliver invoices on time. Invoices will be approved only once numbers are validated by Media Company. For this matter, the following shall not be billable:</p>
                <li>Impressions targeting countries not specified in the IO; and</li>
                <li>Impressions or clicks which have been generated by Fraud.</li>
                <br />
                <p>Notwithstanding any other remedy available to Media Company under this these Terms and Conditions, if Publisher fails to provide any of its deliverables under any applicable IO, Media Company shall be entitled to demand the immediate return of any sums prepaid under the budget stipulated in such IO.</p>

                <h5>Display Sites.</h5>
                <p>The Sites on which the Media Company Advertisers creative will be displayed (Display Sites) cannot include any content that Media Company deems is in any way unlawful, harmful, threatening, defamatory, obscene, harassing, or racially, ethnically or otherwise objectionable, which by way of example only, might mean that it contains (i) sexually explicit, pornographic or obscene content (whether in text or graphics), (ii) speech or images that are offensive, profane, hateful, threatening, harmful, defamatory, libellous, harassing or discriminatory (whether based on race, ethnicity, creed, religion, gender, sexual orientation, physical disability or otherwise), (iii) graphic violence, (iv) politically sensitive or controversial issues or (v) any unlawful behaviour or conduct. The Display Sites shall not be designed to appeal to minors. Further, Media Company is strongly committed to the protection of its end-users from all types of malicious, harmful or intrusive software and holds a zero-tolerance policy in such regard. The Display Sites cannot be designed to distribute or promote any spyware, adware, Trojan horses, viruses, worms, spy bots, key loggers or any other form of malware. </p>

                <h5>Proprietary Rights.</h5>
                <p>The full right, title and interest in all Ads and Advertising Materials and all their components, including, without limitation, patents, trademarks, service marks, copyrights, know how, software, text, design, clips, graphics, logos and all intellectual property rights related to these (the Proprietary Rights), is and shall remain with Media Company and/or its Clients. Media Company reserves all rights not expressly granted in these Terms in the Proprietary Rights, and these Terms do not provide Publisher with any rights to use any Proprietary Rights, except as expressly permitted by these Terms.</p>

                <h5>Confidentiality.</h5>
                <p>All business, commercial, proprietary or non-public information disclosed by Media Company under these Terms is confidential, and Publisher will only use and disclose such information for the purposes of effecting this Agreement.</p>

                <h5>Force majeure</h5>
                <ul>
                  <li>Neither Media Company nor Publisher will be liable for any delay or default in the performance of their respective obligations under these Terms if such delay or default is caused by conditions beyond its reasonable control, including, but not limited to, fire, flood, accident, earthquakes, telecommunications line failures, electrical outages, network failures, acts of God, or labour disputes (each a Force Majeure Event). If Media Company suffers such a delay or default, Media Company will make reasonable efforts within five (5) business days to recommend a substitute transmission for the Ad or period for the transmission. If no such substitute time or remedy is reasonably acceptable to Publisher, Media Company will allow Publisher a pro rata reduction in the space, time, and/or program charges hereunder in the amount of money assigned to the space, time, and/or program charges at time of purchase.</li>
                  <li>Cancellation. If a Force Majeure Event continues for five (5) business days or longer, Media Company and/or Publisher shall have the right to cancel the remainder of the IO without penalty.</li>
                </ul>

                <h5>Indemnification:</h5>
                <p>Publisher hereby agrees to indemnify, defend and hold harmless Media Company, its shareholders, officers, directors, employees, agents, affiliates, successors and assigns, from and against any and all claims, losses, liabilities, damages or expenses (including legal fees and costs) of any nature whatsoever incurred or suffered by Advertiser (collectively the Losses), insofar as such Losses (or actions in respect thereof) arise out of or are based on (i) the breach of this Agreement by Publisher or any representation or warranty made by Publisher herein; or (ii) any claim related to the Publishers Sites on which Advertisers creative materials will be displayed. </p>

                <h5>Choice of Law and Jurisdiction:</h5>
                <ul>
                  <li>These terms of use, their subject matter, their formation, claims are governed by Singapore law and courts.</li>
                </ul>

                <h5>Limitation of Liability:</h5>
                <p>To the maximum extent permitted by law, in no event will Media Company or its Affiliates or their Representatives be liable for </p>
                <ul>
                  <li> Any indirect, special, incidental, punitive, exemplary or consequential damages (including loss of data, business, or profits), regardless of legal theory;</li>
                  <li>Any claims, liabilities, damages or losses in an amount exceeding the amounts paid to Media Company by the Publisher over the past three months.</li>
                </ul>

                <h5>Data compliance information:</h5>
                <p>We are registered in Singapore under company number (201530199D) and have our registered office at #15-01, 15th Floor, 491B River Valley Road, Valley Point, Singapore,248373.</p>
                <p>Our main trading address is #15-01, 15th Floor, 491B River Valley Road, Valley Point, Singapore 248373 To contact us, please email compliance@streamlyn.comor telephone our customer service line on (+65-31631161 ext 104). OR if you would like to discuss any regulatory matters with us please contact our Compliance Team (compliance@streamlyn.com) at OR our Data Protection Officer – Compliance Compendium Limited, at hello@compliancecompendium.co.uk OR via post - NatWest Hub, Unit 2 Park Cross Street, Leeds LS1 2QH, United Kingdom.</p>
                <b>For EU inventory (These clauses are only applicable for inventory from EU region):</b>
                <p>1) Streamlyn is an inventory monetization company that uses different platforms for inventory monetization and we do not drop cookies/store or collect any kind of data at our end. However, our partners (GDPR compliant SSP platforms) perform data collection from publishers in order to serve ads. In case of any query please refer to our policies: https://streamlyn.com/privacy-policy, https://streamlyn.com/cookie-policy. We follow IAB guidelines and frameworks to maintain user privacy.</p>
                <p>2) Publisher agrees that it is responsible for ensuring that all data subjects/users are appropriately notified about the data collection practices as per the IAB guidelines. Publisher represents and warrants that it shall, at all times maintain and make operational on its properties a mechanism for obtaining and recording such consent and that enables such consent to be withdrawn, in accordance with applicable Privacy Requirements, prior to and within the Agreement period. The publisher shall have sole responsibility for the accuracy, quality and legality of the subject data and the means by which the publisher acquired the data. </p>
                <p>3) If Publisher is unable to comply with its consent and notice obligations under the Agreement (including in Clause 2) in respect of the Data, Publisher shall promptly notify Streamlyn.</p>
                <p>4) Each party shall promptly inform the other if it receives any correspondence or inquiry directly from a data subject(user) in relation to the data. Where user has a concern that the other party has not complied data considering his choice of data privacy, the parties agree to exchange information to ascertain the cause of such non-compliance and take reasonable steps to remediate.</p>


                <br />
              </div>
              <div className="modal-footer " style={{justifyContent:'flex-start'}}>
                <div className="form-check mt-1 mb-2 ml-4" >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="AdUnitName"
                    checked={TCState}
                    onChange={(e) => setTCState(!TCState)}
                  />
                  <label
                    className="form-check-label"
                  >
                    I have read and agree to the Bidsxchange terms and conditions. 
                  </label>
                </div>

              </div>
            </div>

          </div>
          <div className="modal-footer">
            <Link
              type="button"
              to="/logout"
              onClick={() => {
                setJsTagModalState(false);
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Decline
            </Link> &nbsp;&nbsp;
            <button
              type="button"
              onClick={handleAcceptTC}
              className="btn btn-primary "
              data-dismiss="modal"
              disabled={!TCState}
            >
              Accept
            </button> &nbsp;&nbsp;

          </div>
        </Modal>
      } */}
{/* 
style={{background:' rgba(255, 255, 255, 0.32)',borderRadius: '16px',boxShadow:' 0 4px 30px rgba(0, 0, 0, 0.1)',backdropFilter: 'blur(8.1px)'}} */}
      {/* <div className="alertModal">
      <Modal    isOpen={showAlert}   centered={true} 
            >
                <div className="modal-content">
                    <ModalBody className=" " style={{padding:'5% 10%'}}>

                        <h2 className="mb-4">Still with us?</h2>
                        <p className="mb-2">Privacy is essential,and you&apos;ve been gone a while.
                        We will log you out in </p>
                         <h2 className="mb-2">{moment.unix(remaining).utc().format(' m [min] s [secs]')} </h2> 
                         <p className="mb-4"> unless you confirm you&apos;re still with us. </p>
                        <div className="row mt-4 justify-content-center m-0">
                            <button type="button" className="btn btn-success " onClick={handleStillHere}>Continue Working</button>
                        </div>
                        <div className="text-center mt-4">
                            <Link  to="#" onClick={handleWindowClose}> Log out now </Link>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
      </div> */}
   
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
