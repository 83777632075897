import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_NET_BLOCKINGS,ADD_NET_BLOCKING , UPDATE_NET_BLOCKING,READ_NET_BLOCKING,STATUS_NET_BLOCKING,ARCHIVE_NET_BLOCKING,CALL_API} from "./actionTypes"

import {
  loadNetBlockings,
  noDataNetBlockings,
  getNetBlockingsSuccess,
  getNetBlockingsFail,
  addNetBlockingSuccess,
  addNetBlockingFail,
  updateNetBlockingSuccess,
  updateNetBlockingFail,
  readNetBlockingSuccess,
  statusNetBlockingSuccess,
  archiveNetBlockingSuccess,
  apiCallResponseSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { getNetBlocking,updateNetBlocking,statusChangeNetBlocking,archiveNetBlocking } from "../../../helpers/Backend_helper"

function* fetchNetBlocking({ payload: adv }) {
  try {
    yield put(loadNetBlockings(true))
    const response = yield call(getNetBlocking,adv)
    console.log(response)
    response.response.map((item, index) => {
      item.id = index + 1
  })
  console.log(response)
  if(response.response.length === 0){
    yield put(noDataNetBlockings(true))
  }
    yield put(getNetBlockingsSuccess(response.response))
  } catch (error) {
    yield put(getNetBlockingsFail(error))
  }
}


function* onUpdateNetBlocking({ payload: adv }) {
  try {
    const response = yield call(updateNetBlocking, adv)
    if(response.success){
      yield put(updateNetBlockingSuccess(response))
    }else{
      yield put(updateNetBlockingFail(response))
    }
  } catch (error) {
    console.log(error)
    yield put(updateNetBlockingFail(error))
  }
}

function* onStatusNetBlocking({ payload: adv }) {
  try {
    const response = yield call(statusChangeNetBlocking, adv)
    yield put(statusNetBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(statusNetBlockingSuccess(error))
  }
}

function* onArchiveNetBlocking({ payload: adv }) {
  try {
    const response = yield call(archiveNetBlocking, adv)
    yield put(archiveNetBlockingSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(archiveNetBlockingSuccess(error))
  }
}

function* netBlockingsSaga() {
  yield takeEvery(GET_NET_BLOCKINGS, fetchNetBlocking)
  yield takeEvery(UPDATE_NET_BLOCKING, onUpdateNetBlocking)
  yield takeEvery(STATUS_NET_BLOCKING, onStatusNetBlocking)
  yield takeEvery(ARCHIVE_NET_BLOCKING, onArchiveNetBlocking)
}

export default netBlockingsSaga;
